import { useState, useEffect } from "react";
import MyIcon from "../../components/Icon/pageIcon";
import checkUndefined from "../../utility/checkUndefined";
import SituFlight from "../../components/WocWo/SituFLight";
import Cookies from "universal-cookie";
import { Row, Col, Table, message } from "antd";
import { MyPageName, MyPageTitle } from "../../styled/publicTag";
import styled from "styled-components"
import WsWlSelect from "../../components/WsWlSelect";

import { url } from "../../services/handlerAPI";

import useWebSocket from "../../utility/useWebSocket";

const WosTableKanbanElement = ({className}) => {
  const cookies = new Cookies();

  const [KbSocket, changeKbSocketParams, setKbSocket] = useWebSocket();

  const [light, setLgint] = useState("w");

  const [kbSocketData, setKbSocketData] = useState([]);

  const tableColumns = [
    {
      title: "時段",
      dataIndex: "time",
      key: "time",
      className: "wos-tk-table-col",
    },
    {
      title: "制令單號",
      dataIndex: "woN",
      key: "woN",
      className: "wos-tk-table-col",
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      className: "wos-tk-table-col",
    },
    {
      title: "標準人數",
      dataIndex: "planHead",
      key: "planHead",
      className: "wos-tk-table-col",
      render: (num, recoded) => {
        return !recoded.UUID ? "" : checkUndefined(num);
      },
    },
    {
      title: "在線人數",
      dataIndex: "hdC",
      key: "hdC",
      className: "wos-tk-table-col",
      render: (num, recoded) => {
        return !recoded.UUID ? "" : checkUndefined(num);
      },
    },
    {
      title: "目標產量",
      dataIndex: "planQty",
      key: "planQty",
      className: "wos-tk-table-col",
      render: (num, recoded) => {
        return !recoded.UUID ? "" : checkUndefined(num-recoded.d_planQty-recoded.d_inC);
      },
    },
    {
      title: "產出數量",
      key: "inC",
      dataIndex: "inC",
      className: "wos-tk-table-col",
      render: (num, recoded) => {
        return !recoded.UUID ? "" : checkUndefined(num);
      },
    },
    {
      title: "不良數量",
      key: "ngC",
      dataIndex: "ngC",
      className: "wos-tk-table-col",
      render: (num, recoded) => {
        return !recoded.UUID ? "" : checkUndefined(num);
      },
    },
    {
      title: "達成率(%)",
      dataIndex: "TAR",
      key: "TAR",
      className: "wos-tk-table-col",
      render: (num) => {
        return num === 0 ? 0+"%" : checkUndefined(num) + "%";
      },
    },
    // {
    //   title: "稼動率(%)",
    //   dataIndex: "ACT",
    //   key: "ACT",
    //   className: "wos-tk-table-col",
    //   render: (num) => {
    //     return num === 0 ? "" : checkUndefined(num) + "%";
    //   },
    // },
    {
      title: "生產效率(%)",
      dataIndex: "PPE",
      key: "PPE",
      className: "wos-tk-table-col",
      render: (num) => {
        return num === 0 ? 0+"%" : checkUndefined(num) + "%";
      },
    },
    {
      title: "直通良率(%)",
      dataIndex: "QTY",
      key: "QTY",
      className: "wos-tk-table-col",
      render: (num) => {
        return num === 0 ? 0+"%" : checkUndefined(num) + "%";
      },
    },
  ];

  useEffect(() => {
    changeKbSocketParams({
      url: url,
      apiName: "kb/kbSocket",
      params: "",
    });
    setKbSocket(true);
  }, []);

  useEffect(() => {
    console.log("KbSocket life", KbSocket);

    if (KbSocket.status) {
      const getThisWon = [];
      for (const i of KbSocket.data.sections) {
        if (i.UUID !== "") {
          getThisWon.push(i);
        }
      }
      const getThisWonIndex = getThisWon.length - 1;
      if (Array.isArray(getThisWon) && getThisWon.length > 0) {
        setLgint(getThisWon[getThisWonIndex].situF);
      }
      //抓取現在時間, 當前時間row 需亮色
      const today = new Date();
      const hour = today.getHours();
      const hourString = hour < 10 ? "0" + hour : "" + hour;

      let data = KbSocket.data.sections.map((x, i) => {
        let light = false;

        //加上時間區間 從8:00 開始, 預設從api 接到的已經按照時間格式排好
        let time = "";
        if (i + 8 < 24) {
          time = `${i + 8 < 10 ? "0" + (i + 8) : i + 8}:00-${
            i + 9 < 10 ? "0" + (i + 9) : i + 9
          }:00`;
        } else {
          time = `${i - 16 < 10 ? "0" + (i - 16) : i - 16}:00-${
            i - 15 < 10 ? "0" + (i - 15) : i - 15
          }:00`;
        }

        if (time.substring(0, 2) === hourString) {
          light = true;
        }
        return { ...x, time: time, key: time, light: light };
      });
      setKbSocketData(data);
    }
  }, [KbSocket]);

  return (
    <div className={className}>
      <MyPageName>
        <Row justify="space-between" align="top">
          <Col span={10}>
            <MyPageTitle style={{ width: "350px" }}>
              設備看板_{`${cookies.get("wsID")}-${cookies.get("wlID")}`}
            </MyPageTitle>
          </Col>
          <Col span={14}>
            <div style={{display:"flex"}}>
              <WsWlSelect/>
              <SituFlight light={light} />
            </div>
          </Col>
        </Row>
      </MyPageName>
      <Row>
        <Col span={24}></Col>
        <Col span={24}>
          <Table
            className="WosTableKanban"
            bordered={true}
            pagination={false}
            columns={tableColumns}
            dataSource={kbSocketData}
            rowClassName={(record) => {
              return record.light ? "clickRowStyl" : "";
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const WosTableKanban = styled(WosTableKanbanElement)`
  .wos-tk-table-col{
    padding: 4px;
  }
`

export default WosTableKanban;
