export const antdLight = {
  "@primary-color": "#71AFF8",
  "@text-color": "fade(@black, 85%)",
  "@component-background": "#fff",
  "@background-color-light": "#F9F9F2",
  "@select-item-selected-bg": "@primary-1",
  "@table-header-sort-bg": "#d9e7e4",
  "@table-row-hover-bg": "#EFF8F7",
  "@table-header-color": "@heading-color",
  "@table-header-bg": "@primary-1",
  "@table-body-sort-bg": "#fafafa",
  "@layout-body-background": "#ffffff",
  "@layout-header-background": "#fff",
  "@layout-sider-background": "@layout-header-background",
  "@mentions-dropdown-menu-item-hover-bg": "fff",
  "@message-notice-content-bg": "#FFF2CC",
  "@descriptions-bg": "#ffffff",
};
export const antdDark = {
  "@primary-color": "#5995BC",
  "@text-color": "#ffffff",
  "@component-background": "#4C4C4C",
  "@table-row-hover-bg": "rgba(165, 188, 209, 0.6)",
  "@background-color-light": "#2b587b",
  "@background-color-base": "rgba(20, 77, 107 , 0.5)",
  "@table-header-sort-bg": "@background-color-base",
  "@select-item-selected-bg": "rgba(0, 150, 136, 0.4)",
  "@item-hover-bg": "rgba(0, 150, 136, 0.1)",
  "@table-body-sort-bg": "#526772",
  "@table-header-color": "#ffffff",
  "@table-header-bg": "@background-color-light",
  "@layout-body-background": "rgba(0, 0, 0, 0.9)",
  "@layout-header-background": "rgba(22, 22, 22, 1)",
  "@layout-sider-background": "#141414 ",
  "@menu-dark-bg": "#141414 ",
  "@menu-dark-submenu-bg": "#141414 ",
  "@message-notice-content-bg": "#FFF2CC",
  "@descriptions-bg": "#ffffff",
};
export const change_antd_theme = (color) => {
  window.less
    .modifyVars(color)
    .then(() => {})
    .catch((error) => {
      console.log("更換主題失敗", error);
    });
};
