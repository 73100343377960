import React, { useState } from 'react'
import { Row, Col,Select ,Button,Space  } from 'antd'
import test01 from '../../assets/test01.pdf'

import { Document, Page,pdfjs } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'
// console.log(pdfjs.version)

function ShowPdf() {

  const { Option } = Select

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function handleChange(value) {
    console.log(`selected ${value}`)
  }

  function nextPage() {
    setPageNumber(pageNumber+1)
  }

  function previousPage() {
    setPageNumber(pageNumber-1)
  }

  return (
    <React.Fragment>
      <Row gutter={[0, 24]}>
        <Col span={4}>
          <div className="page-title">電子標準作業流程</div>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col offset={3} span={4} >
          <Space>
            <Button onClick={previousPage}>-</Button>
            <span stlye={{padding:20}}>{ pageNumber}</span>
            <Button onClick={nextPage}>+</Button>
          </Space>
        </Col>
      </Row>
      <Row gutter={[0, 24]} justify="center">
        <Col span={24} style={{height:'800'}}>
          <Document
            file={"http://pds.hjj.syncff-sat.com/pdf/aaaaa_SIP.pdf"}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default ShowPdf 