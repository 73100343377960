import { useState, useEffect } from "react";
import useStateRef from "../../utility/useStateRef";
import checkUndefined from "../../utility/checkUndefined";
import MyCard from "../../styled/card";
import SituFlight from "../../components/WocWo/SituFLight";
import ProductionLineChart from "../../components/WocChartKanban/ProductionLineChart";
import timeGap from "../../utility/timeGap";
import Cookies from "universal-cookie";
import moment from "moment";
import LineChart from "../../components/WocChartKanban/LineChart";
import LabellineChart from "../../components/WocChartKanban/LabelLineChart";
import { Row, Col } from "antd";
import {
  MyPageName,
  MyComponent,
  MyPageTitle,
  MySubtitleH3,
  MyComponentThree,
} from "../../styled/publicTag";
import myIcon from "../../components/Icon/pageIcon";
import { url } from "../../services/handlerAPI";
import useWebSocket from "../../utility/useWebSocket";
import { formatFloat } from "../../utility/formatFloat";
import WsWlSelect from "../../components/WsWlSelect";

const timeModal = {
  UUID: "",
  wsID: "",
  wlID: "",
  woN: "",
  wotype: 0,
  wpcset: null,
  wpcSN: "",
  addSN: 0,
  prefix: 0,
  suffix: 0,
  offfix: 0,
  woMix: null,
  lineMix: 0,
  PN: "",
  pdtNM: null,
  planHead: 0,
  openT: null,
  updT: "0001-01-01T00:00:00Z",
  situF: null,
  situFT: null,
  lifeF: null,
  lifeFT: "0001-01-01T00:00:00Z",
  planQty: 0,
  d_planQty: 0,
  inC: 0,
  d_inC: 0,
  ngC: 0,
  okC: 0,
  moldCavity: 0,
  hdC: 0,
  whC: 0,
  mhC: 0,
  hourC: 0,
  ACT: 0,
  PPE: 0,
  TAR: 0,
  QTY: 0,
};

const WosChartKanban = () => {
  const cookies = new Cookies();

  const [light, setLgint] = useState("w");

  const [singleWpc, setSingleWpc, singleWpcRef] = useStateRef({
    isOpen: false,
    thisWpc: {},
    wpcChartInfo: timeModal,
    timeData: [],
    ng: [],
  });

  const [chartSocket, changeChartSocketParams, setChartSocket] = useWebSocket();

  useEffect(() => {
    changeChartSocketParams({
      url: url,
      apiName: "kb/kbSocket",
      params: ``,
    });

    setChartSocket(true);
  }, []);

  useEffect(() => {
    if (chartSocket.status) {
      //篩選出最新那筆資料
      let newData = [];
      //加上時間
      let timeData = [];
      for (const [i, x] of chartSocket.data.sections.entries()) {
        // 算qty
        const qty = x.planQty - x.d_planQty - x.d_inC;
        if (x.UUID !== "") {
          newData.push({ ...x, qty: qty });
        }
        let time = "";
        if (i + 8 < 24) {
          time = `${i + 8 < 10 ? "0" + (i + 8) : i + 8}:00-${
            i + 9 < 10 ? "0" + (i + 9) : i + 9
          }:00`;
        } else {
          time = `${i - 16 < 10 ? "0" + (i - 16) : i - 16}:00-${
            i - 15 < 10 ? "0" + (i - 15) : i - 15
          }:00`;
        }
        timeData.push({ ...x, time: time, qty: qty + 0 });
      }

      let ngData = [];
      if (chartSocket.data.ng.length > 0) {
        ngData = chartSocket.data.ng.map((x) => {
          return { ...x, percent: formatFloat(x.percent, 2) };
        });
      }
      setSingleWpc({
        ...singleWpc,
        wpcChartInfo: newData[newData.length - 1]
          ? newData[newData.length - 1]
          : timeModal,
        timeData: timeData,
        ngData: ngData,
      });
    } else {
      setSingleWpc({ ...singleWpc, wpcChartInfo: timeModal, timeData: {} });
    }
  }, [chartSocket]);

  return (
    <>
      <MyPageName>
        <Row justify="space-between" align="top">
          <Col span={6}>
            <MyPageTitle style={{ width: "350px" }}>
              設備看板_{`${cookies.get("wsID")}-${cookies.get("wlID")}`}
            </MyPageTitle>
          </Col>
          <Col span={14}>
            <div style={{ display: "flex"}}>
              <WsWlSelect />
              <SituFlight light={singleWpc.wpcChartInfo.situF} />
            </div>
          </Col>
        </Row>
      </MyPageName>
      <Row justify="space-between" align="middle" gutter={[24, 12]}>
        <Col span={24}>
          <MyComponent>
            <Row justify="space-around" align="middle" gutter={[24, 12]}>
              <Col span={6}>
                <MyCard
                  title="工單號"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.woN)}
                  icon={<myIcon.IconWon />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="料號"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.PN)}
                  icon={<myIcon.IconPn />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="品名"
                  align="left"
                  content={checkUndefined(singleWpc.wpcChartInfo.pdtNM)}
                  icon={<myIcon.IconPdtNM />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="目標產量"
                  align="left"
                  content={singleWpc.wpcChartInfo.qty}
                  icon={<myIcon.IconPlanQty />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="開工時間"
                  align="left"
                  content={
                    singleWpc.wpcChartInfo.openT
                      ? moment(singleWpc.wpcChartInfo.openT).format(
                          "yyyy-MM-DD HH:mm"
                        )
                      : checkUndefined(singleWpc.wpcChartInfo.openT)
                  }
                  icon={<myIcon.IconOpenT />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="生產時間"
                  align="left"
                  content={
                    singleWpc.wpcChartInfo.openT
                      ? timeGap(
                          new Date(moment()),
                          new Date(singleWpc.wpcChartInfo.openT)
                        )
                      : "暫無數據"
                  }
                  icon={<myIcon.IconMhc />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="不良數量"
                  align="left"
                  content={singleWpc.wpcChartInfo.ngC}
                  icon={<myIcon.IconNgC />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="生產數量"
                  align="left"
                  content={singleWpc.wpcChartInfo.okC}
                  icon={<myIcon.IconOkc />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="稼動率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.ACT} />}
                  icon={<myIcon.IconAct />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="生產效率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.PPE} />}
                  icon={<myIcon.IconPpe />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="直通良率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.QTY} />}
                  icon={<myIcon.IconQty />}
                />
              </Col>
              <Col span={6}>
                <MyCard
                  title="目標達成率"
                  content={<LineChart percent={singleWpc.wpcChartInfo.TAR} />}
                  icon={<myIcon.IconTar />}
                />
              </Col>
            </Row>
          </MyComponent>
        </Col>
        <Col span={12}>
          <MyComponent>
            <MySubtitleH3>生產趨勢</MySubtitleH3>
            <MyComponentThree>
              <ProductionLineChart
                chartData={singleWpc.timeData}
                infoData={singleWpc.wpcChartInfo}
              />
            </MyComponentThree>
          </MyComponent>
        </Col>
        <Col span={12}>
          <MyComponent>
            <MySubtitleH3>不良分佈</MySubtitleH3>
            <MyComponentThree>
              <LabellineChart data={singleWpc.ngData} />
            </MyComponentThree>
          </MyComponent>
        </Col>
      </Row>
    </>
  );
};
export default WosChartKanban;
