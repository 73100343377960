import { instance, conn, url } from "../services/handlerAPI";

export const getAllWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/qc/getAllWpc`, { params: _params });
};
export const pass = (data) => {
  return instance.post(`${conn}://${url}/api/qc/pass`, data);
};
export const waive = (data) => {
  return instance.post(`${conn}://${url}/api/qc/waive`, data);
};
export const quarantine = (data) => {
  return instance.post(`${conn}://${url}/api/qc/quarantine`, data);
};
export const confirm = (data) => {
  return instance.post(`${conn}://${url}/api/qc/confirm`, data);
};
export const cancel = (data) => {
  return instance.post(`${conn}://${url}/api/qc/cancel`, data);
};
