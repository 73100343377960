import { useState, useEffect } from "react";
import "./productivitySVGcss.css";
import { timeline } from "../../services/api";

const ProductivityPopoverSVG = ({ data }) => {
  const [s_time, setTime] = useState();

  const [timelineData, setTimelineData] = useState({
    UUID: "",
    openT: "0001-01-01T00:00:00Z",
    prepareT: "0001-01-01T00:00:00Z",
    closeT: "0001-01-01T00:00:00Z",
    mcT: "0001-01-01T00:00:00Z",
    qcT: "0001-01-01T00:00:00Z",
    goT: "0001-01-01T00:00:00Z",
    open: "暫無資料",
    prepare: "暫無資料",
    close: "暫無資料",
    mc: "暫無資料",
    qc: "暫無資料",
    go: "暫無資料",
    diffOpenPrepare: [0, 0, 0, 4],
    diffCloseOpen: [0, 0, 0, 8],
    diffMcClose: [0, 0, 0, 0],
    diffQcMc: [0, 0, 0, 0],
    diffGoQc: [0, 0, 0, 0],
    diffGoPrepare: [0, 0, 0, 0],
  });

  function time() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDay();
    const hour = today.getHours();
    const min = today.getMinutes();
    const sec = today.getSeconds();
    // 轉成字串，如果低於10，前面加上'0'
    const monthString = month < 10 ? "0" + month : "" + month;
    const dayString = day < 10 ? "0" + day : "" + day;
    const hourString = hour < 10 ? "0" + hour : "" + hour;
    const minString = min < 10 ? "0" + min : "" + min;
    const secString = sec < 10 ? "0" + sec : "" + sec;
    return `${year}-${monthString}-${dayString} ${hourString}:${minString}:${secString}`;
  }

  const totalFormat = (array) => {
    if (Array.isArray(array)) {
      return `${array[0]}天${array[1]}時${array[2]}分${array[3]}秒`;
    } else {
      return "暫無資料";
    }
  };

  const timeStringJudge = (str) => {
    if (typeof str === "string") {
      if (str === "暫無資料") {
        return "暫無資料";
      } else {
        return str.substr(5);
      }
    } else {
      return "暫無資料";
    }
  };

  const call_timeline = async () => {
    try {
      const res = await timeline(data);
      if (res.status) {
        let newData = res.data;
        newData.login = timeStringJudge(newData.login);
        newData.mc = timeStringJudge(newData.mc);
        newData.prepare = timeStringJudge(newData.prepare);
        newData.qc = timeStringJudge(newData.qc);
        newData.close = timeStringJudge(newData.close);
        newData.go = timeStringJudge(newData.go);

        newData.login !== '暫無資料'? newData.loginLine = "prefix__b": newData.loginLine = "prefix__dark"
        newData.mc !== '暫無資料'? newData.mcLine = "prefix__b": newData.mcLine = "prefix__dark"
        newData.prepare !== '暫無資料'? newData.prepareLine = "prefix__b": newData.prepareLine = "prefix__dark"
        newData.qc !== '暫無資料'? newData.qcLine = "prefix__b": newData.qcLine = "prefix__dark"
        newData.close !== '暫無資料'? newData.closeLine = "prefix__b": newData.closeLine = "prefix__dark"
        newData.go !== '暫無資料'? newData.goLine = "prefix__b": newData.goLine = "prefix__dark"
        
        if (data.lifeF === "P") {
          newData.pass = timeStringJudge(newData.go);
          newData.waiver = "暫無資料";
          newData.quarantine = "暫無資料";
          newData.diffPass = newData.diffGoQc;
          newData.diffWaiver = [0, 0, 0, 0];
          newData.diffQuarantine = [0, 0, 0, 0];
        } else if (data.lifeF === "W") {
          newData.pass = "暫無資料";
          newData.waiver = timeStringJudge(newData.go);
          newData.quarantine = "暫無資料";
          newData.diffPass = [0, 0, 0, 0];
          newData.diffWaiver = newData.diffGoQc;
          newData.diffQuarantine = [0, 0, 0, 0];
        } else if (data.lifeF === "L") {
          newData.pass = "暫無資料";
          newData.waiver = "暫無資料";
          newData.quarantine = timeStringJudge(newData.go);
          newData.diffPass = [0, 0, 0, 0];
          newData.diffWaiver = [0, 0, 0, 0];
          newData.diffQuarantine = newData.diffGoQc;
        } else {
          newData.pass = "暫無資料";
          newData.waiver = "暫無資料";
          newData.quarantine = "暫無資料";
          newData.diffPass = [0, 0, 0, 0];
          newData.diffWaiver = [0, 0, 0, 0];
          newData.diffQuarantine = [0, 0, 0, 0];
        }
        setTimelineData(newData);
      } else {
        // call_timeline()
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    call_timeline();
  }, [data]);

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="545">
        <defs>
          <clipPath id="prefix__b">
            <path d="M0 0h1200v545H0z" />
          </clipPath>
        </defs>
        <g id="prefix__a" clipPath="url(#prefix__b)">
          <path className="prefix__g" d="M0 0h1200v545H0z" />
          <path
            className={timelineData.mcLine}
            d="M159 1H0v-2h159z"
            transform="translate(607.285 336.43)"
          />
          <path
            className={timelineData.qcLine}
            d="M159 1H0v-2h159z"
            transform="translate(783.285 336.43)"
          />
          <path
            className={timelineData.loginLine}
            d="M160 1H0v-2h160z"
            transform="translate(78.285 336.893)"
          />
          <path
            className={timelineData.prepareLine}
            d="M160 1H0v-2h160z"
            transform="translate(254.285 336.893)"
          />
          <path
            className={timelineData.closeLine}
            d="M160 1H0v-2h160z"
            transform="translate(431.285 336.893)"
          />
          <text className="prefix__c" transform="translate(229.488 199.66)">
            <tspan x="0" y="0">
              開工
            </tspan>
          </text>
          <text className="prefix__c" transform="translate(581.661 201.957)">
            <tspan x="0" y="0">
              物控
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(103.009 321.488)">
            <tspan x="0" y="0">
              {timelineData.login}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(278.785 321.488)">
            <tspan x="0" y="0">
              {timelineData.prepare}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(455.785 321.488)">
            <tspan x="0" y="0">
              {timelineData.close}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(631.785 321.488)">
            <tspan x="0" y="0">
              {timelineData.mc}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(806.785 321.488)">
            <tspan x="0" y="0">
              {timelineData.qc}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(89.285 364.728)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffPrepareLogin)}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(265.785 364.728)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffClosePrepare)}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(442.785 364.725)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffMcClose)}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(617.785 364.728)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffQcMc)}
            </tspan>
          </text>
          <text className="prefix__d" transform="translate(793.785 364.728)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffGoQc)}
            </tspan>
          </text>
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(69.635 344.596)"
          />
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(422.997 344.596)"
          />
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(774.877 344.596)"
          />
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(246.488 213.372)"
          />
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(599.067 215.669)"
          />
          <path
            className="prefix__e"
            d="M335.842 103.1V10.7H19.075l-5.259-8.756-5.258 8.764H1v92.4h334.842m1 1H0v-94.4h7.992L13.815 0l5.826 9.708h317.2z"
            transform="translate(810.929 380.025)"
          />
          <rect
            width="415.07"
            height="81.006"
            rx="3"
            transform="translate(742.751 27.629)"
            fill="#f3f3f3"
          />
          <path
            className="prefix__e"
            d="M3 .5A2.5 2.5 0 00.5 3v75.005a2.5 2.5 0 002.5 2.5h409.07a2.5 2.5 0 002.5-2.5V3a2.5 2.5 0 00-2.5-2.5H3M3 0h409.07a3 3 0 013 3v75.005a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3z"
            transform="translate(742.751 27.629)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(766.028 328.189)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(766.028 328.189)"
          />
          <circle
            className={timelineData.qcLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(769.375 331.402)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(589.958 328.189)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(589.958 328.189)"
          />
          <circle
            className={timelineData.mcLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(593.305 331.402)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(413.888 328.189)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(413.888 328.189)"
          />
          <circle
            className={timelineData.closeLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(417.235 331.402)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(237.785 328.189)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(237.785 328.189)"
          />
          <circle
            className={timelineData.prepareLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(241.132 331.402)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(61.079 328.189)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(61.079 328.189)"
          />
          <circle
            className={timelineData.loginLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(64.426 331.402)"
          />
          <text
            transform="translate(31.267 41.629)"
            fontSize="13"
            fill="#707070"
            fontFamily="MicrosoftJhengHeiRegular,Microsoft JhengHei"
          >
            <tspan x="0" y="0">
              工單號
            </tspan>
          </text>
          <g
            stroke="#707070"
            strokeWidth=".5"
            fill="#f3f3f3"
            transform="translate(31.267 57.085)"
          >
            <rect width="204.187" height="26.109" rx="5" stroke="none" />
            <rect
              x=".25"
              y=".25"
              width="203.687"
              height="25.609"
              rx="4.75"
              fill="none"
            />
          </g>
          <text
            transform="translate(39.3 75.106)"
            fontSize="12"
            fill="#bebebe"
            fontFamily="MicrosoftJhengHeiRegular,Microsoft JhengHei"
          >
            <tspan x="0" y="0">
              {data.woN}
            </tspan>
          </text>
          <path
            className={timelineData.goLine}
            d="M180.756 1H0v-2h180.756z"
            transform="translate(955.633 336.43)"
          />
          <text className="prefix__c" transform="translate(932.88 201.957)">
            <tspan x="0" y="0">
              過站
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(899.861 415.033)">
            <tspan x="0" y="0">
              {timelineData.pass}
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(899.861 441.559)">
            <tspan x="0" y="0">
              {timelineData.waiver}
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(899.861 468.085)">
            <tspan x="0" y="0">
              {timelineData.quarantine}
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(1011.25 415.033)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffPass)}
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(1011.25 441.559)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffWaiver)}
            </tspan>
          </text>
          <text className="prefix__l" transform="translate(1011.25 468.085)">
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffQuarantine)}
            </tspan>
          </text>
          <text className={data.lifeF === "P" ? "prefix__pass":"prefix__o"} transform="translate(858.098 415.033)">
            <tspan x="0" y="0">
              合格
            </tspan>
          </text>
          <text className={data.lifeF === "W" ? "prefix__pass":"prefix__o"} transform="translate(858.098 441.559)">
            <tspan x="0" y="0">
              特採
            </tspan>
          </text>
          <text className={data.lifeF === "Q" ? "prefix__pass":"prefix__o"} transform="translate(858.098 468.085)">
            <tspan x="0" y="0">
              隔離
            </tspan>
          </text>
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(825.707 401.702)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(825.707 401.702)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(825.707 428.372)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(825.707 428.372)"
          />
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(825.707 455.043)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(825.707 455.043)"
          />
          <circle
            className={data.lifeF === "P" ? "prefix__pass" : "prefix__o"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(829.054 405.05)"
          />
          <circle
            className={data.lifeF === "W" ? "prefix__pass" : "prefix__o"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(829.054 431.72)"
          />
          <circle
            className={data.lifeF === "Q" ? "prefix__pass" : "prefix__o"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(829.054 458.39)"
          />
          <path
            className="prefix__e"
            d="M.5 115.818h-1V0h1z"
            transform="translate(950.286 215.669)"
          />
          <text
            transform="translate(920.646 59.018)"
            fill="#707070"
            fontFamily="MicrosoftJhengHeiRegular,Microsoft JhengHei"
            fontSize="17"
          >
            <tspan x="0" y="0">
              工單總時長
            </tspan>
          </text>
          <path
            d="M2358.581 50.185h-2.716c-.621 0-.825-.206-.79-.819a5.548 5.548 0 011.287-3.314c.544-.654 1.1-1.3 1.647-1.947.41-.482.734-.495 1.145-.008.7.827 1.41 1.646 2.055 2.514a4.914 4.914 0 01.9 2.778c.026.605-.168.795-.766.8l-2.762-.004z"
            transform="translate(-1454.601 9.655)"
            fillRule="evenodd"
            fill="#58beb3"
          />
          <path
            className="prefix__r"
            d="M2326.134-36.546a6.137 6.137 0 011.207-3.354c.663-.89 1.321-1.784 2-2.663a.4.4 0 000-.572c-.643-.837-1.267-1.689-1.908-2.527a6.365 6.365 0 01-1.294-3.326c-.061-.642.127-.93.576-.922a.489.489 0 01.53.546 5.053 5.053 0 001.2 3.161c.733.922 1.423 1.878 2.13 2.821a.755.755 0 01-.014 1.079q-1.169 1.565-2.346 3.123a4.727 4.727 0 00-.965 2.662c-.028.511-.2.761-.549.762-.382.002-.573-.265-.567-.79z"
            transform="translate(-1428.04 95.591)"
          />
          <path
            className="prefix__r"
            d="M2417.316-48.833a6.019 6.019 0 01-1.186 3.328c-.659.893-1.316 1.788-2 2.662a.411.411 0 000 .6c.643.836 1.273 1.683 1.9 2.528a5.846 5.846 0 011.266 3.7.507.507 0 01-.531.553.478.478 0 01-.55-.489c.051-1.675-.9-2.882-1.854-4.1-.5-.636-.978-1.288-1.462-1.936a.744.744 0 01-.007-1.109c.7-.934 1.384-1.875 2.108-2.787a5 5 0 001.2-3.12c0-.276.084-.559.4-.577a.824.824 0 01.6.21c.132.141.1.412.116.537z"
            transform="translate(-1507.443 95.288)"
          />
          <path
            className="prefix__r"
            d="M2318.959-77.891h-6.379c-.484 0-.7-.172-.692-.548.006-.358.221-.544.674-.544h12.8c.5 0 .729.253.654.671-.061.338-.279.425-.6.424-1.975-.007-3.951 0-5.926 0z"
            transform="translate(-1414.974 122.273)"
          />
          <path
            className="prefix__r"
            d="M2318.969 138.258h-6.214a3.582 3.582 0 01-.37-.008.47.47 0 01-.45-.5.5.5 0 01.416-.572 1.422 1.422 0 01.287-.016h12.716a1.534 1.534 0 01.287.015.493.493 0 01.427.562c-.011.3-.151.5-.473.509-.521.01-1.043.007-1.564.007z"
            transform="translate(-1415.017 -76.078)"
          />
          <text
            transform="translate(875.976 96.788)"
            fontFamily="MicrosoftJhengHeiBold,Microsoft JhengHei"
            fill="#58beb3"
            fontSize="17"
          >
            <tspan x="0" y="0">
              {totalFormat(timelineData.diffGoPrepare)}
            </tspan>
          </text>
          <path
            className={timelineData.goLine}
            d="M7.364 0l7.364 11.381H0z"
            transform="rotate(90 409.21 738.56)"
          />
          <path
            className="prefix__g"
            transform="translate(983.082 321.453)"
            d="M0 0h125.86v31.465H0z"
          />
          <path
            className="prefix__e"
            d="M1 1v29.465h123.86V1H1M0 0h125.86v31.465H0z"
            transform="translate(983.082 321.453)"
          />
          <text className="prefix__d" transform="translate(990.445 343.601)">
            <tspan x="0" y="0">
              {timelineData.qc}
            </tspan>
          </text>
          <circle
            className="prefix__g"
            cx="8.703"
            cy="8.703"
            r="8.703"
            transform="translate(941.177 327.448)"
          />
          <path
            className="prefix__e"
            d="M8.7 1a7.7 7.7 0 107.7 7.7A7.712 7.712 0 008.7 1m0-1A8.7 8.7 0 110 8.7 8.7 8.7 0 018.7 0z"
            transform="translate(941.177 327.448)"
          />
          <circle
            className={timelineData.goLine !== "prefix__dark" ? "prefix__h" : "prefix__dark"}
            cx="5.356"
            cy="5.356"
            r="5.356"
            transform="translate(944.524 330.663)"
          />
          <text className="prefix__c" transform="translate(52.229 480.42)">
            <tspan x="0" y="0">
              登錄
            </tspan>
          </text>
          <text className="prefix__c" transform="translate(405.591 480.533)">
            <tspan x="0" y="0">
              結單
            </tspan>
          </text>
          <text className="prefix__c" transform="translate(757.325 480.531)">
            <tspan x="0" y="0">
              質控
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default ProductivityPopoverSVG;
