export const themeLight = {
  mainColor: "#71AFF8", //主色
  Color1: "#71AFF8", //副色1
  Color2: "#70CEF6", //副色2
  Color4: "#c4dcec",
  error: "#d86363", //錯誤
  warning: "#EBB549", //警告
  success: "#699f5b", //成功
  pageBg: "#ffffff", //整體背景
  componentsBg: "#ffffff", // 區塊背景
  retext: "rgba(255,255,255,0.8)", //有背景時文字顏色
  text: "rgba(0,0,0,0.85)", //文字顏色
  borderColor: "#f0f0f0",
  shadowColor: "#8D8D8D",
  disabledColor: "#F8F8F8",
  disabledTextColor: "#c5c5c5",
};

export const themeDark = {
  mainColor: "#71AFF8", //主色
  Color1: "#71AFF8", //副色1
  Color2: "#70CEF6", //副色2
  Color4: "#2b587b",
  error: "#d86363", //錯誤
  warning: "#EBB549", //警告
  success: "#699f5b", //成功
  pageBg: "#1F1F1F", //整體背景
  componentsBg: "#363636", // 區塊背景
  retext: "rgba(0,0,0,0.85)", //有背景時文字顏色
  text: "rgba(255,255,255,0.8)", //文字顏色
  borderColor: "#4C4C4C",
  shadowColor: "#f0f0f0",
  disabledColor: "#F8F8F8",
  disabledTextColor: "#c5c5c5",
};
