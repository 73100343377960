import React, { useState, useEffect } from "react";
import { MySituFLightBox, MyH4, MySubtitleH4 } from "../../styled/publicTag";

const SituFLight = (data) => {
  const [color, setColor] = useState([]);

  const [num, setNum] = useState(0);

  const name = ["上模準備", "開始生產", "異常警報", "異常停工"];

  useEffect(() => {
    switch (data.light) {
      case "B":
        setNum(0);
        setColor([
          "rgba(14, 124, 246, 1)",
          "rgba(224, 235, 229, 1)",
          "rgba(248, 241, 222, 1)",
          "rgba(251, 235, 235, 1)",
        ]);
        break;
      case "G":
        setNum(1);
        setColor([
          "rgba(230, 236, 243, 1)",
          "rgba(54, 230, 28, 1)",
          "rgba(248, 241, 222, 1)",
          "rgba(251, 235, 235, 1)",
        ]);
        break;
      case "Y":
        setNum(2);
        setColor([
          "rgba(230, 236, 243, 1)",
          "rgba(224, 235, 229, 1)",
          "rgba(255, 196, 0, 1)",
          "rgba(251, 235, 235, 1)",
        ]);
        break;
      case "R":
        setNum(3);
        setColor([
          "rgba(230, 236, 243, 1)",
          "rgba(224, 235, 229, 1)",
          "rgba(248, 241, 222, 1)",
          "rgba(255, 13, 13, 1)",
        ]);
        break;
      default:
        setNum(4);
        setColor([
          "rgba(230, 236, 243, 1)",
          "rgba(224, 235, 229, 1)",
          "rgba(248, 241, 222, 1)",
          "rgba(251, 235, 235, 1)",
        ]);
    }
  }, [data]);

  //排序為R,Y,G,B

  const lightBox = {
    width: "130px",
    height: "33px",
    lineHeight: "30px",
    borderRadius: "20px",
    margin: "10px",
    textAlign: "center",
    border: ".5px solid rgba(0, 0, 0, 0.1)",
    boxShadow:
      "inset 0px 10px 10px -15px #000, inset -10px 0px 10px -15px #000",
  };

  return (
    <MySituFLightBox>
      {color.map((x, i) => {
        if (i === num) {
          return (
            <div style={{ ...lightBox, backgroundColor: x }} key={i + x}>
              <MyH4 style={{ color: "#fff" }}>{name[i]}</MyH4>
            </div>
          );
        } else {
          return (
            <div style={{ ...lightBox, backgroundColor: x }} key={i + x}>
              <MyH4 style={{ opacity: "0.6",color: "#000" }}>{name[i]}</MyH4>
            </div>
          );
        }
      })}
    </MySituFLightBox>
  );
};

export default SituFLight;
