import { instance, conn, url } from "../services/handlerAPI";

export const getAllWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/mc/getAllWpc`, { params: _params });
};

export const getSingleWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/mc/getSingleWpc`, {
    params: _params,
  });
};

export const ok = (data) => {
  return instance.post(`${conn}://${url}/api/mc/ok`, data);
};

export const ng = (data) => {
  return instance.post(`${conn}://${url}/api/mc/ng`, data);
};

export const confirm = (data) => {
  return instance.post(`${conn}://${url}/api/mc/confirm`, data);
};

export const cancel = (data) => {
  return instance.post(`${conn}://${url}/api/mc/cancel`, data);
};

export const prefixWorkAPI = (data) => {
  return instance.post(`${conn}://${url}/api/mc/prefixWork`, data);
};

export const suffixWorkAPI = (data) => {
  return instance.post(`${conn}://${url}/api/mc/suffixWork`, data);
};

export const getNgitem = () => {
  return instance.get(`${conn}://${url}/api/mc/getNgitem`);
};

export const addNg = (data) => {
  return instance.post(`${conn}://${url}/api/mc/addNg`, data);
};

export const getAllNg = (_params) => {
  return instance.get(`${conn}://${url}/api/mc/getAllNg`, { params: _params });
};

export const getOkitem = () => {
  return instance.get(`${conn}://${url}/api/mc/getOkitem`);
};

export const addOk = (data) => {
  return instance.post(`${conn}://${url}/api/mc/addOk`, data);
};

export const getAllOk = (_params) => {
  return instance.get(`${conn}://${url}/api/mc/getAllOk`, { params: _params });
};
