const deepCopy = (object) => {
  if (!object || typeof object !== 'object') return

  let newObject = Array.isArray(object) ? [] : {}

  for (let key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      newObject[key] =
        typeof object[key] === 'object' ? deepCopy(object[key]) : object[key]
    }
  }
  return newObject
}

export default deepCopy