import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Select, InputNumber } from "antd";
import { EditOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { getSingleWpcSN } from "../../../services/printer";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import Cookies from "universal-cookie";
import moment from "moment";
import "./PrintForm.css";
import MyIcon from "../../Icon/pageIcon";

const FormTemplate = ({ thisData, className }) => {
  const cookies = new Cookies();
  return (
    <div className="pointer-box">
      <div className={`${className} Wraper`} key={Math.random() * 1000}>
        <div className="Header">
          <div className="Header-Info pl-5">
            委&nbsp;外&nbsp;加&nbsp;工&nbsp;單
          </div>
          <div className="woN pr-5">
            <div className="h-30 font-17 pr-10">工單號 : </div>
            <div className="h-30 font-17">{thisData.woN}</div>
          </div>
        </div>

        <div className="flex-c">
          <div className="flex-r">
            <div className="title w-15 h-50 flex-center border-t border-l border-r border-b">
              料號
            </div>
            <div className="value w-35 h-50 pl-15 flex-left border-t border-r border-b font-17">
              {thisData.PN}
            </div>
            <div className="title w-15 h-50 flex-center border-t border-b border-r">
              加工項目
            </div>
            <div className="value w-35 h-50 flex-center border-t border-r border-b font-17">
              {thisData.oemNM}
            </div>
          </div>

          <div className="flex-r">
            <div className="title w-15 h-50 flex-center border-l border-r border-b">
              品名
            </div>
            <div className="value w-35 h-50 pl-15 flex-left border-r border-b font-14">
              {thisData.pdtNM}
            </div>
            <div className="title w-15 h-50 flex-center border-r border-b flex-center">
              加工備註
              <EditOutlined className="pl-5" />
            </div>
            <div className="value w-35 h-50 flex-center border-r border-b font-17">
              {cookies.get("wlNM")}
            </div>
          </div>
        </div>

        <div className="HeaderInfo">
          <div className="flex-c w-15">
            <div className="title w-100 h-50 flex-center border-l border-r border-b">
              報開工時間
            </div>
            <div className="title w-100 h-50 flex-center border-l border-r border-b">
              供應商編號
            </div>
            <div className="title w-100 h-50 flex-center border-l border-r border-b">
              供應商名稱
            </div>
          </div>

          <div className="flex-c w-35">
            <div className="h-50 border-b border-r pl-15 value font-17 flex-left">
              {moment(thisData.prepareT).format('yyyy-MM-DD HH:mm:ss')}
            </div>
            <div className="h-50 border-b border-r pl-15 value font-17 flex-left">
            {thisData.suppID}
            </div>
            <div className="h-50 border-b border-r pl-15 value font-17 flex-left">
            {thisData.suppNM}
            </div>
          </div>

          <div className="w-50 QRCode border-b border-r ">
            <div className="title pl-40 flex-col-c">
              途程碼
              <div className="value">
                {" "}
                {thisData.woType}-{thisData.wpcSet}-{thisData.wpcSN}-
                {thisData.addSN}&emsp;
                {thisData.prefix}-{thisData.suffix}-{thisData.offfix}-
                {thisData.sqc}
              </div>
            </div>

            <div>
              <QRCode
                id={thisData.UUID}
                value={`{"UUID":"${thisData.UUID}","woN":"${thisData.woN}"}`} //value引數為生成二維碼的連結 我這裡是由後端返回
                size={80} //二維碼的寬高尺寸
                fgColor="#000000" //二維碼的顏色
              />
            </div>
          </div>
        </div>

        <div className="pl-5 title1">物控盤料</div>
        <div className="flex-r w-100">
          <div className="flex-c w-15">
            <div className="title h-75 flex-center border-r border-b border-l border-t">
              委外數量
            </div>
            <div className="title h-75 flex-center border-r border-b border-l">
              {thisData.qty}
            </div>
          </div>

          <div className="flex-c w-17">
            <div className="title h-75 flex-center border-r border-b border-t">
              物控出廠數量
              <EditOutlined className="pl-5" />
            </div>
            <div className="title h-75 flex-center border-r border-b"></div>
          </div>

          <div className="flex-c w-18">
            <div className="title h-75 flex-center border-r border-b border-t">
            物控回廠數量
              <EditOutlined className="pl-5" />
            </div>
            <div className="title h-75 flex-center border-r border-b"></div>
          </div>

          <div className="flex-c w-50">
            {/* <div className='title flex-center border-b border-t border-r h-50 pl-15'>物控盤料</div> */}
            <div className="flex-center w-100">
              <div className="MaterialText font-17 border-r border-b border-t h-120 w-100">
                <span className="pl-40">
                  滿箱數：__________箱__________件/箱
                </span>
                <span className="pl-40 pt-10">
                  零箱數：__________箱____________ 件
                </span>
                <span className="pl-40 pt-10">
                  備&emsp;註：__________________________
                </span>
                {/* <span className="pl-40 pt-10">
                __________________________________
              </span> */}
              </div>
            </div>
          </div>
        </div>

        <div className="title1 pl-5 Log border-b">簽收紀錄</div>
        <div className="flex-r border-r border-b border-l">
          <div className="flex-c  w-20">
            <div className="title flex-center border-r border-b h-50 ">
              出廠簽出
              <EditOutlined className="pl-5" />
            </div>
            <div className="flex-col-c h-80 border-r  font-17"></div>
          </div>
          <div className="flex-c  w-20">
            <div className="title flex-center border-r border-b h-50">
              廠商簽收
              <EditOutlined className="pl-5" />
            </div>
            <div className="LogData h-80 flex-left border-r pl-15 font-17">
              <span className="pl-70p"></span>
            </div>
          </div>
          <div className="flex-c  w-20">
            <div className="title flex-center border-r border-b h-50 border-2-t border-2-l">
              回廠［物控］
              <span
                role="img"
                aria-label="edit"
                className="anticon anticon-edit pl-5"
              >
                <MyIcon.IconPress />
              </span>
            </div>
            <div className="flex-col-c h-80 border-r border-2-b font-17 border-2-l"></div>
          </div>
          <div className="flex-c w-20">
            <div className="title flex-center border-r border-b h-50 border-2-t ">
              回廠［質控］
              <span
                role="img"
                aria-label="edit"
                className="anticon anticon-edit pl-5"
              >
                <MyIcon.IconPress />
              </span>
            </div>
            <div className="flex-col-c h-80 border-r border-2-b font-17"></div>
          </div>
          <div className="flex-c w-20">
            <div className="title flex-center border-b h-50 border-2-t border-2-r">
              回廠［過站］
              <span
                role="img"
                aria-label="edit"
                className="anticon anticon-edit pl-5"
              >
                <MyIcon.IconPress />
              </span>
            </div>
            <div className="flex-col-c h-80 font-17 border-2-b border-2-r"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintFormEle = ({ className, setIsModal, thisData }) => {
  const printRef = useRef("");
  const [isPrint, setIsPrint] = useState(true);
  const [printNum, setPrintNum] = useState([1, 2]);
  const [printNumObj, setPrintNumObj] = useState([]);

  const cookies = new Cookies();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => CreatePrintNumObj(printNum),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      size: A5 landscape;
      margin-bottom:0px;
    }
    .pointer-box {
      page-break-inside: avoid;
      position: relative;
      min-height: 90vh;
    }
    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper:after {
      content: "標示單 － "counter(page-number)"/${printNum.length}"; /* 取用該名字的計數器 */
      counter-increment: page-number 1; /* 增加計數器數值 */
      position: absolute;
      right: 5;
      bottom: 10;
      font-size: 20px;
      text-align: center;
    }
    .Wraper:before {
      content: "列印時間：${moment(new Date()).format("yyyy-MM-DD HH:mm:ss")} ${cookies.get("userName")}"; /* 取用該名字的計數器 */
      position: absolute;
      left: 5;
      bottom: 15;
      font-size: 13px;
      text-align: center;
    }
    .template{
      page-break-inside: avoid;
      position: relative;
    }
    .template:after{
      content: "作業單"; 
      position: absolute;
      right: 0;
      bottom: 10;
      font-size: 20px;
      text-align:right;
      width:120px;
      height:30px;
      background: white;
      z-index:99;
    }
  `,
  });

  useEffect(() => {
    if (!isPrint) {
      setTimeout(() => {
        setIsPrint(true);
      }, [500]);
    }
  }, [isPrint]);

  useEffect(() => {
    const obj = printNum.map((x) => {
      return <FormTemplate thisData={thisData} key={Math.random() * 1000} />;
    });
    setPrintNumObj(obj);
  }, [printNum,thisData]);

  return (
    <div className={className}>
      {/*列印張數
       <InputNumber
        min={1}
        max={20}
        value={printNum.length}
        onChange={(e) => {
          let obj = [];
          for (let i = 0; i < e; i++) {
            obj.push(i);
          }
          setPrintNum(obj);
        }}
      /> */}
      <div className="pointer-box">
        <FormTemplate thisData={thisData} />
      </div>

      <div className="pointer-box hidden" ref={printRef}>
        {printNumObj}
        <FormTemplate thisData={thisData} className="template" />
      </div>
      <div className="w-100 align-r">
        <Button type="primary" size="large" onClick={handlePrint}>
          列印
        </Button>
      </div>
    </div>
  );
};

const PrintForm = styled(PrintFormEle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .hidden {
    display: none;
  }
  .pointer-box {
    width: 794px;
    /* background-color: #f5e0e0; */
    /* border: 1px solid #000; */
  }
  .ant-table-cell {
    font-size: 12px;
    padding: 4px;
  }
  .ant-table {
    border: none;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #dc10dc00;
  }
  .ant-table-cell {
    background-color: #fafafa;
  }
  .align-r {
    text-align: right;
    margin-top: 15px;
    margin-right: 15px;
  }
  .w-100 {
    width: 100%;
  }
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default PrintForm;
