import {MyFooter, MyH4, MyH3}  from '../../styled/publicTag';
import Logo from '../../assets/images/logo.png'
import Lamp from '../../assets/images/lamp.png'

import {Space} from 'antd'

const Footer = () =>{

  const imgStyle = {
    height: '90%',
  }
  const lampStyle = {
    height: '25px',
  }

	return (
		<MyFooter>
      <img src={Logo} alt='精工中心' style={imgStyle}/>
      <Space size={[16, 0]} style={{marginLeft:'10px'}}>
        <MyH3>V2.3.50</MyH3>
        {/* <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>IOT</MyH3>
        <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>ES</MyH3>
        <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>CS</MyH3> */}
      </Space>
      {/* <MyH3 style={{
        flex: '1',
        textAlign: 'right'
      }}>LOGO</MyH3> */}
		</MyFooter>
	)
}
export default Footer