import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import WosWoc from "../pages/WosWoc";
import WSwoc from "../pages/WSwoc";
import WosMaterialsControl from "../pages/WosMaterialsControl";
import WosQualityControl from "../pages/WosQualityControl";
import WosChartKanban from "../pages/WosChartKanban";
import WosTableKanban from "../pages/WosTableKanban";
import WosSIP from "../pages/WosSIP";
import WosSOP from "../pages/WosSOP";
import StatusSite from "../pages/StatusSite";
import Outsourcing from "../pages/Outsourcing";

const Routes = () => {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    console.log(auth, auth.WOC === "Y");
  }, [auth]);

  const cookies = new Cookies();

  useEffect(() => {
    const cookieAuth = JSON.parse(localStorage.getItem("auth"));
    const thisWl = cookies.get("wlID");

    if (!cookieAuth) {
      window.location.assign(`/login`);
    }

    for (const x of cookieAuth) {
      if (x.wlID === thisWl) {
        setAuth(x);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <Switch>
        {/* jg 沒權限 */}
        {/* <Route path="/woswoc" component={WosWoc} />
        <Route path="/wosmc" component={WosMaterialsControl} />
        <Route path="/wosqc" component={WosQualityControl} /> */}
        {/* {auth.PDT === "Y" && (
          <Route path="/StatusSite" component={StatusSite} />
        )} */}

        {/* 工站報工 */}
        {/* {auth.SFC === "Y" && (
          <Route path="/wswoc" component={WSwoc} />
        )} */}
        {/* 委外 */}
        {/* {auth.OUT === "Y" && (
          <Route path="/out" component={Outsourcing} />
        )} */}

        {auth.WOC === "Y" && <Route path="/woswoc" component={WosWoc} />}
        {auth.MC === "Y" && (
          <Route path="/wosmc" component={WosMaterialsControl} />
        )}
        {auth.QC === "Y" && (
          <Route path="/wosqc" component={WosQualityControl} />
        )}

        <Route path="/wosck" component={WosChartKanban} />
        <Route path="/wostk" component={WosTableKanban} />

        <Route path="/wossip" component={WosSIP} />
        <Route path="/wossop" component={WosSOP} />

        {auth.WOC === "Y" && <Redirect exact from="*" to="/woswoc" />}
      </Switch>
    </React.Fragment>
  );
};
export default Routes;
