import React, { useState, useEffect } from "react";
import { MySubtitleH3 } from "../../styled/publicTag";
import Cookies from "universal-cookie";
import { Select } from "antd";

const WsWlSelect = () => {
  const { Option } = Select;

  const cookies = new Cookies();

  const [auth, setAuth] = useState({});

  const [wlOptions, setWlOptions] = useState([]);

  useEffect(() => {
    const cookieAuth = JSON.parse(localStorage.getItem("auth"));
    const thisWl = cookies.get("wlID");
    for (const x of cookieAuth) {
      if (x.wlID === thisWl) {
        setAuth(x);
      }
    }

    setWlOptions(
      cookieAuth.map((x) => (
        <Option
          key={x.wlID}
          value={x.wlID}
          name={x.wlNM}
        >{`${x.wlID}-${x.wlNM}`}</Option>
      ))
    );
  }, []);

  return (
    <div style={{display:"flex", alignItems:"center"}}>
      <MySubtitleH3>工線：</MySubtitleH3>
      <Select
        value={auth.wlID}
        size="large"
        onChange={(_, e) => {
          cookies.set("wlID", e.key);
          cookies.set("wlNM", e.value);
          window.history.go(0)
        }}
      >
        {wlOptions}
      </Select>
      <MySubtitleH3>
        工站：{cookies.get("wsID") + "-" + cookies.get("wsNM")}
      </MySubtitleH3>
    </div>
  );
};

export default WsWlSelect;
