import { useState, useEffect } from "react";
import { Table, Row, Col, Typography, message, Input, Modal } from "antd";
import { getAllWpc } from "../../../services/printer";
import checkUndefined from "../../../utility/checkUndefined";
import { MySubtitleH4, MyThemeH1 } from "../../../styled/publicTag";
import PrintForm from "./PrintForm";
import Cookies from "universal-cookie";

const PreviewPrint = ({ setIsShowModal }) => {
  const { Link } = Typography;

  const { Search } = Input;

  const cookies = new Cookies();

  const wpcTableCol = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      width: "90px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      width: "200px",
      className: "row-min-width",
      align: "left",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "歷程時間",
      dataIndex: "lifeFTime",
      key: "lifeFTime",
      width: "150px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(
          str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料"
        );
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      width: "230px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      width: "230px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "動作",
      dataIndex: "action",
      key: "action",
      className: "row-last-col-width",
      align: "center",
      render: (obj, recoded) => {
        return (
          <Link onClick={() => setIsPrintFormModal(true)}>預覽列印</Link>
        );
      },
    },
  ];

  const [printData, setPrintData] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    totalItems: 1,
    currentPage: 1,
    pageItemNumber: 1,
    startItemNumber: 1,
    endItemNumber: 1,
    queryString: "",
    wpcType:"close"
  });

  const [isPrintFormModal, setIsPrintFormModal] = useState(false);

  const [thisData, setThisData] = useState();

  const call_getAllWpc = async (pageData) => {
    const res = await getAllWpc(pageData?pageData:pageInfo);
    if (res.status) {
      console.log(res)
      const addKey = res.data.woNs.map((x) => {
        return { ...x, key: x.UUID, wlID:cookies.get("wlID") };
      });

      setPageInfo({ ...pageInfo, ...res.data.pageInfo });

      setPrintData(addKey);
    } else {
      setPageInfo({
        totalItems: 1,
        currentPage: 1,
        pageItemNumber: 1,
        startItemNumber: 1,
        endItemNumber: 1,
        queryString: pageInfo.queryString,
      });
      setPrintData([]);
      message.error(res.msg);
    }
  };

  const searchWpc = () => {
    call_getAllWpc();
  };

  const changeQueryString = (e) => {
    setPageInfo({ ...pageInfo, queryString: e.target.value });
  };

  // useEffect(()=>{
  //   call_getAllWpc()
  // },[pageInfo])

  useEffect(() => {
    call_getAllWpc();
  }, []);

  return (
    <Row>
      {/* <Col span={24}>
				<Search
					placeholder="請輸入完整的工單或料號"
					onSearch={searchWpc}
					onChange={changeQueryString}
					enterButton
					size="large"
					style={{ width: "380px" }}
				/>
			</Col> */}
      <Col span={24}>
        <Table
          dataSource={printData}
          columns={wpcTableCol}
          onRow={(record) => {
            return {
              onClick: () => {
                setThisData(record)
              },
            };
          }}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            onChange: (current) => {
              console.log(current)
              let pageData = pageInfo;
              pageData.page = current;
              call_getAllWpc(pageData)
              setPageInfo(pageData);
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      </Col>
      {/* 預覽列印 */}
      <Modal
        title={<MyThemeH1>工單歷程</MyThemeH1>}
        visible={isPrintFormModal}
        width="45%"
        onCancel={() => setIsPrintFormModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={null}
        centered
        forceRender
        getContainer={false}
        wrapClassName="print-modal"
      >
        {isPrintFormModal && (
          <PrintForm setIsShowModal={setIsPrintFormModal} thisData={thisData}/>
        )}
      </Modal>
    </Row>
  );
};

export default PreviewPrint;
