/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space, Avatar, Popover } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { changeMenuStatus, changeTheme } from "../../redux/action";
import {
  SysteamName,
  HeaderTime,
  MyH4,
} from "../../styled/publicTag";
import ThemeButton from "../ThemeButton/ThemeButton";
import {
  antdDark,
  antdLight,
  change_antd_theme,
} from "../../styles/antdStyles";
import moment from "moment";

const MyHeader = () => {
  const { Header } = Layout;

  const dispatch = useDispatch();

  const r_isNavBar = useSelector((allReducers) => allReducers.navBar);

  const [s_time, setTime] = useState();

  const [s_popoverOpenNumber, setPopoverOpenNumber] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment().format("yyyy-MM-DD\u00A0HH:mm"));
    }, 1000);
    //畫面Rendering 完之後塞到dom
    return () => {
      clearInterval(timer);
    };
    //只執行一次
  }, []);
  //冒泡
  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const getTheme = localStorage.getItem("theme");
    if (getTheme === "true") {
      localStorage.setItem("theme", true);
      change_antd_theme(antdLight);
      dispatch(changeTheme(true));
    } else {
      localStorage.setItem("theme", false);
      change_antd_theme(antdDark);
      dispatch(changeTheme(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const is_change_theme = () => {
    setPopoverOpenNumber(s_popoverOpenNumber + 1);
    if (s_popoverOpenNumber < 1) {
      return;
    }
    //antd Popover組件有問題 初次點擊時會執行裡面的function 故用useState判斷是否為初次進入頁面
    const getTheme = localStorage.getItem("theme");
    if (getTheme === "true") {
      localStorage.setItem("theme", false);
      change_antd_theme(antdDark);
      dispatch(changeTheme(false));
    } else {
      localStorage.setItem("theme", true);
      change_antd_theme(antdLight);
      dispatch(changeTheme(true));
    }
  };

  const is_navbar = (e) => {
    // 使用 react 的 e.stopPropagation 不能阻止冒泡，需要使用 e.nativeEvent.stopImmediatePropagation，这里我们对其进行封装，方便多次调用
    stop_propagation(e);
    dispatch(changeMenuStatus(r_isNavBar));
  };

  const headerStyle = {
    zIndex: "2",
    boxShadow: "0px 5px 3px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    padding: "0 24px",
    minWidth: "100%",
    transition: ".3s",
    top: 0,
  };

  const navBarButton = {
    fontSize: "26px",
  };

  //當navbar展開往右擠navbar的寬度
  const templateElementStyle = {
    marginLeft: r_isNavBar ? null : "190px",
    transition: ".3s",
  };

  const flexStyle = {
    padding: "0 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const lineStyle = {
    height: "40px",
    width: "2px",
    borderRight: "1px solid #E9E9E9",
  };

  return (
    <Header style={headerStyle}>
      <Row wrap={false}>
        <Col span={12}>
          <Space align="baseline" style={templateElementStyle}>
            <MenuFoldOutlined onClick={is_navbar} style={navBarButton} />
            {window.innerWidth > 820 ? (
              <SysteamName>生產執行系統MES</SysteamName>
            ) : null}
          </Space>
        </Col>
        <Col span={12}>
          <div style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}>
            <div style={flexStyle}>
              {window.innerWidth > 820 ? (
                <HeaderTime>{s_time}</HeaderTime>
              ) : null}
            </div>
            <div style={lineStyle} />
            <div style={flexStyle}>
              <MyH4>新莊廠</MyH4>
            </div>
            <div style={lineStyle} />
            <div style={flexStyle}>
              <ThemeButton onClick={is_change_theme} />
            </div>
          </div>
        </Col>
      </Row>
    </Header>
  );
};
export default MyHeader;
