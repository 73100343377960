import { instance, conn, url } from "../services/handlerAPI";

export const wsSearch = (_params) => {
  return instance.get(`${conn}://${url}/api/sfc/wsSearch`, {
    params: _params,
  });
};

export const getAllProduction = (_params) => {
  return instance.get(`${conn}://${url}/api/sfc/getAllProduction`, {
    params: _params,
  });
};

export const getAllWsID = (_params) => {
  return instance.get(`${conn}://${url}/api/sfc/getAllWsID`, {
    params: _params,
  });
};

export const open = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/open`, data);
};

export const prepare = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/prepare`, data);
};

export const resume = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/resume`, data);
};

export const close = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/close`, data);
};

export const downLine = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/downLine`, data);
};

export const pause = (data) => {
  return instance.post(`${conn}://${url}/api/sfc/pause`, data);
};
