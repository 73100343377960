import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { MyMainColorH1 } from "../../styled/publicTag";
import { Row, Col, Space, Input, Table, message, Typography } from "antd";

import { getAllWsID, getAllProduction } from "../../services/sfcAPI";

import checkUndefined from "../../utility/checkUndefined";

const WorkInProductionElement = ({ className }) => {
  const { Link } = Typography;

  const [tableData, setTableData] = useState({
    data: [],
    col: [],
    basicData: [],
  });

  useEffect(() => {
    const call_getAllWsID = async () => {
      try {
        const res = await getAllWsID();
        if (res.status) {
          let result = [
            {
              title: "工單號",
              key: "woN",
              dataIndex: "woN",
              width: "180px",
            },
            {
              title: "料號",
              key: "PN",
              dataIndex: "PN",
              width: "190px",
            },
          ];
          for (const x of res.data) {
            result = [
              ...result,
              {
                title: x.wsID + x.wsNM,
                key: x.wsID,
                dataIndex: x.wsID,
                children: [
                  {
                    title: (
                      <span>
                        投產
                        
                      </span>
                    ),
                    key: `${x.wsID}inC`,
                    dataIndex: `${x.wsID}inC`,
                    width: "70px",
                  },
                  {
                    title: (
                      <span>
                        不良
                        
                      </span>
                    ),
                    key: `${x.wsID}ngC`,
                    dataIndex: `${x.wsID}ngC`,
                    width: "70px",
                  },
                  {
                    title: (
                      <span>
                        產出
                        
                      </span>
                    ),
                    key: `${x.wsID}okC`,
                    dataIndex: `${x.wsID}okC`,
                    width: "70px",
                  },
                  {
                    title: (
                      <span>
                        未產
                      </span>
                    ),
                    key: `${x.wsID}noProduce`,
                    dataIndex: `${x.wsID}noProduce`,
                    width: "70px",
                  },
                ],
              },
            ];
          }
          const action = {
            title: <span>詳情</span>,
            key: `action`,
            dataIndex: `action`,
            render: (text, rowData, index) => {
              const key = rowData.sfcMrb;
              return <Link>成品查看</Link>;
            },
          };
          const allData = await call_getAllProduction();

          setTableData({
            ...tableData,
            // col: [...result, action],
            col: [...result],
            data: allData,
            basicData: allData,
          });
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const call_getAllProduction = async () => {
      try {
        let result = [];
        const res = await getAllProduction();
        if (res.status) {
          const data = res.data.map((x) => {
            x[`${x.wsID}inC`] = checkUndefined(x.inC);
            x[`${x.wsID}ngC`] = checkUndefined(x.ngC);
            x[`${x.wsID}okC`] = checkUndefined(x.okC);
            x[`${x.wsID}noProduce`] =
              x.inC - x.ngC - x.okC < 0
                ? "超生產"
                : checkUndefined(x.inC - x.ngC - x.okC);
            x["key"] = x.UUID;
            return x;
          });
          result = data;
        } else {
          message.error(res.msg);
        }
        return result;
      } catch (e) {
        console.error(e);
      }
    };
    call_getAllWsID();
    // call_getAllProduction()
  }, []);

  const wonSearch = (e) => {
    const keyWord = e.target.value;
    if (keyWord.length === 0) {
      setTableData({ ...tableData, data: tableData.basicData });
      return;
    }
    //模糊搜索
    const reg = new RegExp(keyWord);
    let arr = [];
    try {
      for (const x of tableData.data) {
        if (reg.test(x.woN)) {
          arr.push(x);
        }
      }
      console.log(arr);
      setTableData({ ...tableData, data: arr });
    } catch (e) {
      setTableData({ ...tableData, data: tableData.basicData });
    }
  };

  const pnSearch = (e) => {
    const keyWord = e.target.value;
    if (keyWord.length === 0) {
      setTableData({ ...tableData, data: tableData.basicData });
      return;
    }
    //模糊搜索
    const reg = new RegExp(keyWord);
    let arr = [];
    try {
      for (const x of tableData.data) {
        if (reg.test(x.PN)) {
          arr.push(x);
        }
      }
      setTableData({ ...tableData, data: arr });
    } catch (e) {
      setTableData({ ...tableData, data: tableData.basicData });
    }
  };

  return (
    <Row gutter={[12, 12]} className={className}>
      <Col>
        <Space size={12}>
          <Input
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            placeholder="請輸入工單號"
            size="large"
            onChange={wonSearch}
          />
          <Input
            stlye={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            placeholder="請輸入料號"
            size="large"
            onChange={pnSearch}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Table
          dataSource={tableData.data}
          columns={tableData.col}
          bordered={true}
          pagination={false}
          scroll={{ x: "max-content", y: window.innerHeight - 60 - 45 - 220 }}
        />
      </Col>
    </Row>
  );
};

const WorkInProduction = styled(WorkInProductionElement)`
  .ant-table-thead > tr > th {
    text-align: center;
    /* padding: 16px; 更改antd原本設定間距 */
  }
  /* 在表格組件內固定antd寬度 設scroll={{ x: true }} 以固定寬度強制斷行 */
  /* 更改antd原本設定間距 */
  .ant-table-tbody > tr > td {
    padding: 4px; 
  }

  /* .ant-table-thead > tr:nth-child(1) > th:nth-child(1) {
    text-align: center;
    background-color: #add8e6;
  }
  .ant-table-thead > tr:nth-child(1) > th:nth-child(2) {
    text-align: center;
    background-color: #add8e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(3),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(1),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(2),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(3),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(4) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(4),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(6),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(7),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(8) {
    text-align: center !;
    background-color: #add8e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(5),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(9),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(10),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(11),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(12) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(6),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(13),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(14),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(15),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(16) {
    text-align: center;
    background-color: #add8e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(7),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(17),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(18),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(19),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(20) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(8),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(21),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(22),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(23),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(24) {
    text-align: center;
    background-color: #add8e6;
  }
 */

  /* --------------------------------H原本說的----------------------------------------------- */
  /* 中文靠右 */
  /* .ant-table-tbody > tr > td {
    text-align: right;
  } */

  /* 中文靠左 */
  /* .ant-table-tbody > tr > td:nth-child(1) {
    text-align: left;
  } */

  /* ------------------------------------------------------------------------------- */

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  .ant-table-tbody > tr > td:last-child {
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(1),
  .ant-table-tbody > tr > td:nth-child(2) {
    /* background-color: #ffffff; */
    text-align: center;
  }

  /* .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-tbody > tr > td:nth-child(5),
  .ant-table-tbody > tr > td:nth-child(6) {
    background-color: #d3f3f7;
  }
  .ant-table-tbody > tr > td:nth-child(10),
  .ant-table-tbody > tr > td:nth-child(7),
  .ant-table-tbody > tr > td:nth-child(8),
  .ant-table-tbody > tr > td:nth-child(9) {
    background-color: #e8fcfc;
  }
  .ant-table-tbody > tr > td:nth-child(14),
  .ant-table-tbody > tr > td:nth-child(11),
  .ant-table-tbody > tr > td:nth-child(12),
  .ant-table-tbody > tr > td:nth-child(13) {
    background-color: #d3f3f7;
  }
  .ant-table-tbody > tr > td:nth-child(18),
  .ant-table-tbody > tr > td:nth-child(15),
  .ant-table-tbody > tr > td:nth-child(16),
  .ant-table-tbody > tr > td:nth-child(17) {
    background-color: #e8fcfc;
  }
  .ant-table-tbody > tr > td:nth-child(22),
  .ant-table-tbody > tr > td:nth-child(19),
  .ant-table-tbody > tr > td:nth-child(20),
  .ant-table-tbody > tr > td:nth-child(21) {
    background-color: #d3f3f7;
  }
  .ant-table-tbody > tr > td:nth-child(26),
  .ant-table-tbody > tr > td:nth-child(23),
  .ant-table-tbody > tr > td:nth-child(24),
  .ant-table-tbody > tr > td:nth-child(25) {
    background-color: #e8fcfc;
  } */
`;

export default WorkInProduction;
