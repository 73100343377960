import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  message,
  Button,
  Space,
  Select,
  Modal,
  Input,
  InputNumber,
  Divider,
} from "antd";
import { formatFloat } from "../../../utility/formatFloat";

import { getOkitem, addOk, getAllOk } from "../../../services/mcAPI";
import {
  MySubtitleH4,
  MyH1,
  MySubtitleH3,
  MyMainColorH3,
} from "../../../styled/publicTag";
import moment from "moment";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const AddOk = ({ singleWpc, call_getAllWpc }) => {
  const { Option } = Select;

  const okProductCol = [
    {
      title: "良品項",
      dataIndex: "itemNM",
      key: "itemNM",
    },
    {
      title: "數量",
      dataIndex: "okC",
      key: "okC",
      render: (num) =>
        num > 0 ? (
          <span>{num}</span>
        ) : (
          <span style={{ color: "#d86363" }}>{num}</span>
        ),
    },
    {
      title: "登錄時間",
      dataIndex: "lifeFT",
      key: "lifeFT",
      render: (item) => moment(item).format("yyyy-MM-DD HH:mm"),
    },
  ];

  const [okItemOptions, setOkItemOptions] = useState([]);

  const [checkProducts, setCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });

  // 已登陸之良品品
  const [recordList, setRecordList] = useState([]);

  useEffect(() => {
    call_getOkitem();
    call_getAllOk();
  }, []);

  const productsConfirm = (e) => {
    if (checkProducts.count <= 0) {
      message.error("數量不得小於0");
      return;
    } else if (checkProducts.product === "") {
      message.error("品項不得為空");
      return;
    } else if (checkProducts.shift === "") {
      message.error("班別不得為空");
      return;
    } else {
      Modal.confirm({
        title: <MyH1>確定進行良品登錄？</MyH1>,
        content: (
          <MySubtitleH3>請先確認輸入的數值無誤，再進行登錄</MySubtitleH3>
        ),
        okText: "確認",
        okType: "primary",
        cancelText: "取消",
        centered: true,
        width: 600,
        onOk() {
          call_addOk(e);
        },
      });
    }
  };

  const call_getOkitem = async () => {
    try {
      const res = await getOkitem();
      if (res.status) {
        const Options = res.data.map((x) => {
          return (
            <Option key={x.item} value={x.item}>
              {x.itemNM}
            </Option>
          );
        });
        setOkItemOptions(Options);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_getOkitem", e);
    }
  };

  const call_getAllOk = async () => {
    try {
      const res = await getAllOk({ UUID: singleWpc.UUID });
      if (res.status) {
        let tdata = [];
        if (res.data !== null || res.data !== undefined) {
          tdata = res.data.map((x) => {
            return { ...x, key: x.cumT };
          });
        }
        setRecordList(tdata);
      } else {
        setRecordList([]);
      }
    } catch (e) {
      console.log("call_getAllOk", e);
    }
  };

  const call_addOk = async (e) => {
    try {
      const data = {
        UUID: singleWpc.UUID,
        okItem: checkProducts.product,
        okC: e === "add" ? checkProducts.count : checkProducts.count * -1,
        shift: checkProducts.shift,
      };
      const res = await addOk(data);
      if (res.status) {
        message.success(res.msg);
        call_getAllOk();
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_addOk", e);
    }
  };

  return (
    <Row justify="space-between" align="bottom" gutter={[24, 24]}>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>工單號</MySubtitleH4>
        <Input value={singleWpc.woN} disabled />
      </Col>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>ERP料號</MySubtitleH4>
        <Input value={singleWpc.PN} disabled />
      </Col>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>成品品名</MySubtitleH4>
        <Input value={singleWpc.pdtNM} disabled />
      </Col>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>良品數量</MySubtitleH4>
        <Input value={singleWpc.okC} disabled />
      </Col>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>良品數量</MySubtitleH4>
        <Input value={singleWpc.ngC} disabled />
      </Col>
      <Col span={8}>
        <MySubtitleH4 style={{ display: "block" }}>總良品率</MySubtitleH4>
        <Input
          value={
            formatFloat(
              (singleWpc.ngC / (singleWpc.okC + singleWpc.ngC)) * 100,
              1
            ) + "%"
          }
          disabled
        />
      </Col>
      <Divider />

      <Col span={7}>
        <MySubtitleH4 style={{ display: "block" }}>良品品項</MySubtitleH4>
        <Select
          style={{ width: "100%" }}
          value={checkProducts.product}
          onChange={(e) => {
            setCheckProducts({
              ...checkProducts,
              product: e,
            });
          }}
        >
          {okItemOptions}
        </Select>
      </Col>
      <Col span={6}>
        <MySubtitleH4 style={{ display: "block" }}>班別</MySubtitleH4>
        <Select
          style={{ width: "100%" }}
          value={checkProducts.shift}
          onChange={(e) => {
            setCheckProducts({
              ...checkProducts,
              shift: e,
            });
          }}
        >
          <Option value="D">日班</Option>
          <Option value="N">夜班</Option>
        </Select>
      </Col>

      <Col span={6}>
        <MySubtitleH4 style={{ display: "block" }}>良品數量</MySubtitleH4>
        <InputNumber
          value={checkProducts.count}
          min={1}
          onChange={(e) => {
            setCheckProducts({ ...checkProducts, count: e });
          }}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={5}>
        <Space>
          <Button
            style={{ marginRight: "25px" }}
            type="primary"
            onClick={() => {
              productsConfirm("add");
            }}
            size="large"
          >
            加
          </Button>
          <Button
            type="danger"
            onClick={() => {
              productsConfirm("reduce");
            }}
            size="large"
          >
            減
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <MyMainColorH3>已登錄之良品品項</MyMainColorH3>
        <Table
          columns={okProductCol}
          dataSource={recordList}
          scroll={{ x: "max-content", y: 150 }}
        />
      </Col>
    </Row>
  );
};

export default AddOk;
