import { instance, conn, url } from "../services/handlerAPI";

//wocwo,login,header,navbar api

//-----------API---------------------------------------------------------------
//call api 時都加上 try catch && async await
//以call_為開頭

export const login = (data) => {
  return instance.post(`${conn}://${url}/api/login`, data);
};

export const getWsWl = () => {
  return instance.get(`${conn}://${url}/api/getWsWl`);
};

export const getAps = (_params) => {
  return instance.get(`${conn}://${url}/api/getAps`, { params: _params });
};

export const apsWoN = (data) => {
  return instance.post(`${conn}://${url}/api/apsWoN`, data);
};

export const getPNs = () => {
  return instance.get(`${conn}://${url}/api/getPNs`);
};

export const manualWoN = (data) => {
  return instance.post(`${conn}://${url}/api/manualWoN`, data);
};

export const getAllWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllWpc`, { params: _params });
};

export const chooseWpcMold = (_params) => {
  return instance.get(`${conn}://${url}/api/chooseWpcMold`, {
    params: _params,
  });
};

export const chooseWpcStaff = (_params) => {
  return instance.get(`${conn}://${url}/api/chooseWpcStaff`, {
    params: _params,
  });
};

export const upStaff = (data) => {
  return instance.post(`${conn}://${url}/api/upStaff`, data);
};

export const getAllStaffs = () => {
  return instance.get(`${conn}://${url}/api/getAllStaffs`);
};

export const prefix = (data) => {
  return instance.post(`${conn}://${url}/api/prefix`, data);
};

export const getWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/getWpc`, { params: _params });
};

export const getMoldInfo = (_params) => {
  return instance.get(`${conn}://${url}/api/getMoldInfo`, { params: _params });
};

export const chooseWpcMoldInfo = (_params) => {
  return instance.get(`${conn}://${url}/api/chooseWpcMoldInfo`, {
    params: _params,
  });
};

export const wpc = (data) => {
  return instance.post(`${conn}://${url}/api/wpc`, data);
};

export const prepare = (data) => {
  return instance.post(`${conn}://${url}/api/prepare`, data);
};

export const open = (data) => {
  return instance.post(`${conn}://${url}/api/open`, data);
};

export const pause = (data) => {
  return instance.post(`${conn}://${url}/api/pause`, data);
};

export const resume = (data) => {
  return instance.post(`${conn}://${url}/api/resume`, data);
};

export const close = (data) => {
  return instance.post(`${conn}://${url}/api/close`, data);
};

export const downStaff = (data) => {
  return instance.post(`${conn}://${url}/api/downStaff`, data);
};

export const suffix = (data) => {
  return instance.post(`${conn}://${url}/api/suffix`, data);
};

export const downLine = (data) => {
  return instance.post(`${conn}://${url}/api/downLine`, data);
};

export const initPage = () => {
  return instance.get(`${conn}://${url}/api/initPage`);
};

export const getAllRtnWpc = () => {
  return instance.get(`${conn}://${url}/api/getAllRtnWpc`);
};

export const returnWpcApi = (data) => {
  return instance.post(`${conn}://${url}/api/returnWpc`, data);
};

export const timeline = (_params) => {
  return instance.get(`${conn}://${url}/api/timeline`, { params: _params });
};

export const getPauseList = () => {
  return instance.get(`${conn}://${url}/api/getPauseList`);
};

export const getDirList = () => {
  return instance.get(`${conn}://${url}/api/getDirList`);
};

export const getAbnList = () => {
  return instance.get(`${conn}://${url}/api/getAbnList`);
};

export const report = (data) => {
  return instance.post(`${conn}://${url}/api/report`, data);
};

export const getAllAbn = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllAbn`, { params: _params });
};

export const sip = () => {
  return instance.get(`${conn}://${url}/api/sip`);
};

export const sop = (_params) => {
  return instance.get(`${conn}://${url}/api/sop`, { params: _params });
};

export const getAllMultiWpc = () => {
  return instance.get(`${conn}://${url}/api/getAllmultiWpc`);
};

export const resolve = (data) => {
  return instance.post(`${conn}://${url}/api/resolve`, data);
};

export const uploadMultiWpc = (data) => {
  return instance.post(`${conn}://${url}/api/uploadMultiWpc`, data);
};

export const getMulti = (_params) => {
  return instance.get(`${conn}://${url}/api/getMulti`, { params: _params });
};

export const logout = () => {
  return instance.post(`${conn}://${url}/api/logout`);
};

// 當站生產狀況
export const wsSearch = () => {
  return instance.get(`${conn}://${url}/api/sfc/wsSearch`);
};
