import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeMenuStatus, changeNavBarInlineStatus } from "../../redux/action";

import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Switch } from "antd";
import {
  CoffeeOutlined,
  BarsOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import myIcon from "../Icon/pageIcon";

import Logo from "../../assets/images/logo.png";

import { logout } from "../../services/api";

import Cookies from "universal-cookie";

function NavBar() {
  const cookies = new Cookies();

  const {
    Woswo,
    Wostk,
    Woskb,
    Wosmc,
    Wosqc,
    Wosrepowork,
    Wossip,
    Wosck,
    IconOverView,
  } = myIcon;
  const { Sider } = Layout;

  const { SubMenu } = Menu;

  const [auth, setAuth] = useState({});

  useEffect(() => {
    const cookieAuth = JSON.parse(localStorage.getItem("auth"));
    const thisWl = cookies.get("wlID");

    for (const x of cookieAuth) {
      if (x.wlID === thisWl) {
        setAuth(x);
      }
    }
  }, []);


  const Dispatch = useDispatch();

  //get path name
  let location = useLocation();

  const r_isNavbar = useSelector((allReducers) => allReducers.navBar);
  const r_isNavbarInline = useSelector(
    (allReducers) => allReducers.navBarInline
  );

  const handleMenuToggle = useCallback(() => {
    if (r_isNavbar === false) {
      Dispatch(changeMenuStatus(r_isNavbar));
    }
  });

  const call_logout = async () => {
    await logout();
  };

  useEffect(() => {
    // 在 document 上绑定click事件，隐藏弹出層
    // console.log('menu打開')
    window.addEventListener("click", handleMenuToggle);
    return () => {
      window.removeEventListener("click", handleMenuToggle);
    };
  }, [handleMenuToggle, r_isNavbar]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  const is_sider_state = (e) => {
    stop_propagation(e);
  };

  const is_change_navbar_inline = () => {
    Dispatch(changeNavBarInlineStatus(r_isNavbarInline));
  };

  const logoBox = {
    width: "100%",
    // height: "65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  };
  const logoStyle = {
    height: "100%",
    width: "100%",
    padding: "15px 30px",
    objectFit: "contain",
  };

  const siderStyle = {
    height: "100%",
    minHeight: "100%",
    //當navbar展開時覆蓋在header上面
    zIndex: r_isNavbar ? 1 : 3,
    boxShadow: "5px 0px 3px 2px rgba(0,0,0, 0.1)",
    overflow: "auto",
    position: "fixed",
    marginTop: function () {
      if (r_isNavbar) {
        return r_isNavbarInline ? null : "64px";
      }
    },
    // eslint-disable-next-line no-dupe-keys
    height: "100vh",
    left: 0,
  };

  const siderMainStyle = {
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    // justifyContent: "center",
  };

  const menuStyle = {
    flexGrow: "Y",
    borderRight: "1px solid rgba(0,0,0,0)",
    fontSize: "18px",
  };

  const menuBottomStyle = {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyContent: "flex-end",
    borderRight: "1px solid rgba(0,0,0,0)",
  };

  const iconStyle = {
    width: "30px",
    height: "30px",
  };
  return (
    <Sider
      trigger={null}
      collapsed={r_isNavbar}
      collapsedWidth={r_isNavbarInline ? 0 : 80}
      onClick={is_sider_state}
      style={siderStyle}
    >
      <div style={siderMainStyle}>
        <div style={logoBox}>
          <img src={Logo} alt="精工中心" style={logoStyle} />
        </div>
        <Menu
          mode="inline"
          // defaultOpenKeys={["sub1", "sub2"]}
          defaultSelectedKeys={[location.pathname]}
          style={menuStyle}
        >
          {auth.WOC === "Y" && (
            <Menu.Item
              key="/woswoc"
              icon={
                <span className="my-icon-style">
                  <Woswo style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/woswoc">設備報工</NavLink>
            </Menu.Item>
          )}

          {/* {auth.SFC === "Y" && (
            <Menu.Item
              key="/wswoc"
              icon={
                <span className="my-icon-style">
                  <Woswo style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/wswoc">工站報工</NavLink>
            </Menu.Item>
          )} */}

          {/* {auth.OUT === "Y" && (
            <Menu.Item
              key="/out"
              icon={
                <span className="my-icon-style">
                  <Woswo style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/out">委外報工</NavLink>
            </Menu.Item>
          )} */}


          {auth.MC === "Y" && (
            <Menu.Item
              key="/wosmc"
              icon={
                <span className="my-icon-style">
                  <Wosmc style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/wosmc">物控作業</NavLink>
            </Menu.Item>
          )}
          {auth.QC === "Y" && (
            <Menu.Item
              key="/wosqc"
              icon={
                <span className="my-icon-style">
                  <Wosqc style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/wosqc">質控過站</NavLink>
            </Menu.Item>
          )}

          {/* {auth.PDT === "Y" && (
            <Menu.Item
              key="/StatusSite"
              icon={
                <span className="my-icon-style">
                  <IconOverView style={iconStyle} />
                </span>
              }
            >
              <NavLink to="/StatusSite">當站生產狀況</NavLink>
            </Menu.Item>
          )} */}

          <Menu.Item
            key="/wossip"
            icon={
              <span className="my-icon-style">
                <Wossip style={iconStyle} />
              </span>
            }
          >
            <NavLink to="/wossip">SIP瀏覽</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/wossop"
            icon={
              <span className="my-icon-style">
                <Wossip style={iconStyle} />
              </span>
            }
          >
            <NavLink to="/wossop">SOP瀏覽</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/wostk"
            icon={
              <span className="my-icon-style">
                <Wostk style={iconStyle} />
              </span>
            }
          >
            <NavLink to="/wostk">列表看板</NavLink>
          </Menu.Item>
          <Menu.Item
            key="/wosck"
            icon={
              <span className="my-icon-style">
                <Wosck style={iconStyle} />
              </span>
            }
          >
            <NavLink to="/wosck">圖表看板</NavLink>
          </Menu.Item>
        </Menu>
        <Menu mode="inline" style={menuBottomStyle}>
          <Menu.Item
            icon={<BarsOutlined />}
            key="changeNavbar"
            title=""
            // style={menuItemNavbarInlineStyle}
          >
            <Switch
              defaultChecked={r_isNavbarInline ? false : true}
              onChange={is_change_navbar_inline}
            />
          </Menu.Item>
          <Menu.Item
            icon={
              <span className="my-icon-style">
                <ExportOutlined />
              </span>
            }
            key="logout"
            onClick={call_logout}
            // style={menuItemNavbarInlineStyle}
          >
            <span style={iconStyle}>登出</span>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
}
export default NavBar;
