import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Table,
  message,
  Button,
  Space,
  Tabs,
  Select,
  Modal,
  Input,
  Form,
  DatePicker,
  InputNumber,
  Typography,
  Tooltip,
  Divider,
  Alert,
} from "antd";
import SituFlight from "../../components/WocWo/SituFLight";
import WOI from "../../styled/workOrderInformation";
import SocialMedia from "../../components/Icon/pageIcon";
import WsWlSelect from "../../components/WsWlSelect";
import WorkOrderInformation from "../../components/WocWo/WorkOrderInformation";
import Productivity from "../../components/Outsourcing/Productivity";
import PreviewPrint from "../../components/Outsourcing/ModalContainer/PrintForm";

import { numberWithCommas } from "../../utility/numberWithCommas";
import useStateRef from "../../utility/useStateRef";
import {
  MyPageName,
  MyComponent,
  MyComponentTwo,
  MyPageTitle,
  MyThemeH1,
  MySubtitleH3,
  MyH1,
  MyMainColorH3,
  MySubtitleH4,
  MyDisabled,
} from "../../styled/publicTag";
import {
  getAps,
  apsWoN,
  getPNs,
  manualWoN,
  getAllWpc,
  chooseWpcMold,
  upStaff,
  chooseWpcStaff,
  getAllStaffs,
  prefix,
  getWpc,
  wpc,
  downStaff,
  suffix,
  chooseWpcMoldInfo,
  getAllRtnWpc,
  returnWpcApi,
  getPauseList,
  getDirList,
  getAbnList,
  report,
  getAllAbn,
  sop,
  resolve,
  getMulti,
  sip,
} from "../../services/api";

import {
  open,
  prepare,
  pause,
  resume,
  close,
  downLine,
} from "../../services/sfcAPI";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { url } from "../../services/handlerAPI";

import MyButton from "../../styled/button";
import moment from "moment";
import useWebSocket from "../../utility/useWebSocket";
import checkUndefined from "../../utility/checkUndefined";
import Cookies from "universal-cookie";
import { Document, Page, pdfjs } from "react-pdf";

import Light from "../../components/Light";
import OutTab from "../../components/Outsourcing/OutTab";

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 8px;
    overflow-wrap: break-word;
  }
`;

const Outsourcing = () => {
  const apsTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      width: "80px",
      align: "center",

      render: (str) => {
        return "發佈";
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      width: "120px",
      align: "center",
      render: (str) => {
        return checkUndefined(
          str === 0 ? (
            <span>
              正常
              <br />
              工單
            </span>
          ) : str === "0" ? (
            <span>
              重工
              <br />
              工單
            </span>
          ) : (
            <span>
              暫無
              <br />
              資料
            </span>
          )
        );
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "料號",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      width: "120px",
      align: "center",

      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "發佈人員",
      dataIndex: "issueID",
      key: "issueID",
      width: "120px",
      align: "center",

      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "發佈日期",
      dataIndex: "issueDayStr",
      key: "issueDayStr",
      width: "120px",
      align: "center",

      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: <span>使用數量</span>,
      dataIndex: "usedQty",
      key: "usedQty",
      width: "80px",
      align: "center",
      render: (num) => {
        return checkUndefined(num);
      },
    },
    // {
    //   title: "APS拆碼",
    //   dataIndex: "pcFix",
    //   key: "pcFix",
    //   render: (str) => {
    //     return checkUndefined(str);
    //   },
    // },
  ];

  const wpcReturnTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      render: (str) => {
        return checkUndefined(
          str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料"
        );
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "發佈人員",
      dataIndex: "loginID",
      key: "loginID",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "發佈日期",
      dataIndex: "loginTime",
      key: "loginTime",
      render: (str) => {
        return checkUndefined(str);
      },
    },
  ];

  const wpcLoginTableCol = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      render: (str) => {
        return str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料";
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      render: (num) => {
        return num ? numberWithCommas(num) : "暫無數據";
      },
    },
    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      render: (num) => {
        return checkUndefined(num);
      },
    },
    {
      title: "生產數量",
      dataIndex: "okC",
      key: "okC",
      render: (num) => {
        return checkUndefined(num);
      },
    },
    {
      title: "動作",
      dataIndex: "action",
      key: "action",
      render: (obj, recoded) => {
        return (
          <Tooltip title={`途程碼：${recoded.wpcSN + ""}`}>
            <Link>詳情</Link>
          </Tooltip>
        );
      },
    },
  ];

  const wpcTableCol = [
    {
      title: "燈號",
      dataIndex: "situF",
      key: "situF",
      width: "90px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        switch (str) {
          case "B":
            return <Light size="20px" type="main" />;
          case "G":
            return <Light size="20px" type="success" />;
          case "Y":
            return <Light size="20px" type="warning" />;
          case "R":
            return <Light size="20px" type="error" />;
          default:
            return <Light size="20px" disabled />;
        }
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      width: "220px",
      className: "row-min-width",
      align: "left",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "歷程時間",
      dataIndex: "lifeFTime",
      key: "lifeFTime",
      width: "150px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(
          str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料"
        );
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      width: "260px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      width: "300px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      width: "120px",
      className: "row-min-width",
      align: "right",

      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      width: "120px",
      align: "right",

      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      width: "120px",
      align: "right",
      render: (num) => {
        return numberWithCommas(num);
      },
    },

    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      width: "120px",
      className: "row-min-width",
      align: "right",

      render: (str) => {
        return checkUndefined(str);
      },
    },
    // {
    //   title: "生產數量",
    //   dataIndex: "okC",
    //   key: "okC",
    //   width: "130px",
    //   className: "row-min-width",
    //   align: "center",
    //   render: (str) => {
    //     return checkUndefined(str);
    //   },
    // },
    {
      title: "動作",
      dataIndex: "action",
      key: "action",
      className: "row-last-col-width",
      align: "center",
      render: (obj, recoded) => {
        return (
          <Tooltip title={`途程碼：${recoded.wpcSN + ""}`}>
            <Link
              onClick={() => {
                setSingleWpc({ ...singleWpc, isOpen: true });
                setAllWpc({ ...allWpc, thisWpc: obj });
              }}
            >
              詳情
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  const wpcDetailsTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "下線碼",
      dataIndex: "offfix",
      key: "offfix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "多單碼",
      dataIndex: "woMix",
      key: "woMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "混線碼",
      dataIndex: "lineMix",
      key: "lineMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
  ];

  const prefixTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
    },
  ];

  const { TabPane } = Tabs;

  const { Option } = Select;
  //人工工單登入
  const [form] = Form.useForm();
  //異常提報
  const [abnForm] = Form.useForm();
  //異常解除
  const [reAbnForm] = Form.useForm();

  const [multipleWonForm] = Form.useForm();

  const pdfRef = useRef(null);

  const { Search, TextArea } = Input;

  const { Link } = Typography;

  const cookies = new Cookies();

  const [s_time, setTime] = useState();

  // pdf
  const [sopPdfInfo, setSopPdfInfo, sopPdfInfoRef] = useStateRef({
    isOpen: false,
    pdfUrl: "",
    width: 800,
    scale: 1,
    pdfData: {},
    msg: "",
  });

  const [sipPdfInfo, setSipPdfInfo, sipPdfInfoRef] = useStateRef({
    isOpen: false,
    pdfUrl: "",
    width: 800,
    scale: 1,
    pdfData: {},
    msg: "",
  });

  //流程管理 控制每個主頁上面按鈕的disable
  const [processManagementButton, setProcessManagementButton] = useState({
    state: 0,
    button: {
      wpcTable: false,
      productionManagement: false, //生管派工
      wpcLogin: false,
      readyStart: false, //事前準備
      prefixWork: false,
      openWork: false,
      moldManagement: false,
      timeManagement: false,
      closeWorkOrder: false, //報完工
      suffixWork: false, //後拆單
      offLine: false, //報下線
      workStop: false, //報停工
      resumeWork: false, //報復工
      abcReport: false, //異常提報
      abcDismiss: false, //異常解除
    },
  });

  //開始生產
  const [openWork, setOpenWork, openWorkRef] = useStateRef({
    moldCavity: 0,
    moldID: "",
    moldType: "1",
    shift: "D",
  });

  //生產派工Modal
  const [isProductionManagementModal, setIsProductionManagementModal] =
    useState(false);

  //途單登錄 Modal對話框
  const [isApsOperateModal, setIsApsOperateModal] = useState(false);

  //APS工單Modal與information
  const [isAPSWorkOrderModal, setIsAPSWorkOrderModal, isAPSWorkOrderModalRef] =
    useStateRef({
      isOpen: false,
      data: [],
      thisData: {},
      pageInfo: {},
    });
  const [apsWhichQuery, setApsWhichQuery] = useState("all");
  const [apsQueryString, setApsQueryString] = useState("");

  //人工工單
  const [isArtificialWorkOrderModal, setIsArtificialWorkOrderModal] = useState({
    isLoading: false,
    isOpen: false,
    getPNs: [],
  });

  //前拆跳顯
  const [prefixModal, setPrefixModal] = useState({
    isOpen: false,
    d_planQty: 0,
    UUID: "",
    woN: "",
  });

  const [suffixModal, setSuffixModal, suffixModalRef] = useStateRef({
    isOpen: false,
    inputC: 0,
  });

  //途單登陸
  const [wpcLogin, setWpcLogin, wpcLoginRef] = useStateRef({
    isOpen: false,
    queryString: {
      queryString: "",
      wlID: "",
    },
    pageInfo: {
      page: 1,
      search: "",
      wlID: "",
      totalItems: 0,
      totalItems: 0,
      currentPage: 1,
      pageItemNumber: 10,
      startItemNumber: 0,
      endItemNumber: 0,
    },
    data: [],
    thisData: {},
  });

  //途單撤登
  const [returnWpc, setReturnWpc, returnWpcRef] = useStateRef({
    isOpen: false,
    queryString: {
      queryString: "",
      wlID: "",
    },
    pageInfo: {
      page: 1,
      search: "",
      wlID: "",
      totalItems: 0,
      totalItems: 0,
      currentPage: 1,
      pageItemNumber: 10,
      startItemNumber: 1,
      endItemNumber: 10,
    },
    data: [],
    thisData: {},
  });

  //途單索引
  const [allWpc, setAllWpc, allWpcRef] = useStateRef({
    //料表詳情
    pageInfo: {
      page: 1,
      queryString: "", //打錯字 不敢改
      totalItems: 0,
      totalItems: 0,
      currentPage: 1,
      pageItemNumber: 10,
      startItemNumber: 1,
      endItemNumber: 10,
    },
    thisWpc: {},
    loading: false,
    data: [],
  });
  //當前那張途單
  const [singleWpc, setSingleWpc, singleWpcRef] = useStateRef({
    isOpen: false,
    thisWpc: {},
  });

  //異常提報
  const [abnormalRepo, setAbnormalRepo, abnormalRepoRef] = useStateRef({
    isOpen: false,
    unitOptions: [], //單位options
    repoOptions: [], //項目options
    rptUnit: "", //提報部門
    rptItem: "", //提報項目
    AbnDri: "", //責任單位
    rptMsg: "",
    unitData: [],
    repoData: [],
  });

  //異常解除
  const [abnormalRemove, setAbnormalRemove, abnormalRemoveRef] = useStateRef({
    isOpen: false,
    data: [],
    abnOptions: [],
    repoItem: "", //提報項目
    abnDri: "", //責任單位
  });

  //模具管理
  const [moldManagement, setMoldManagement, moldManagementRef] = useStateRef({
    options: [],
    isStaffModal: false,
    thisMold: {},
  });
  //工時管理
  const [timeManagement, setTimeManagement, timeManagementRef] = useStateRef({
    staff: "",
    staffData: [], //全部人員
    chooseStaff: [], //上綱人員
    onWorkPeopleOptions: [], //上綱人員
    isStaffModal: false,
    isOffWork: false,
  });
  //停工modal
  const [stopWorkData, setStopWorkData] = useState({
    isOpen: false,
    cause: "",
    repoOptions: [],
  });
  //報多單
  const [multipleWon, setMultipleWon, multipleWonRef] = useStateRef({
    isOpen: false,
    options: [],
    data: [],
    multiInfo: [],
  });

  //委外選擇
  const [vendorFormData, setVendorFormData] = useState();

  //人員登錄跳顯 連線預設開啟
  const [staffSocket, changeStaffSocketSocketParams, setStaffSocket] =
    useWebSocket();

  const [openWorkSocket, changeOpenWorkSocketParams, setOpenWorkSocket] =
    useWebSocket();

  //table queryString 跟掃碼器做連接
  const [scanSocket, changeScanSocketParams, setIsScanSocket] = useWebSocket();

  const [light, setLgint] = useState("w");

  // 預覽列印 modal
  const [isPrinterModal, setIsPrinterModal] = useState(false);

  const apsButton = () => {
    setIsAPSWorkOrderModal({ ...isAPSWorkOrderModalRef.current, isOpen: true });
    call_getAps();
  };

  const awoButton = () => {
    setIsArtificialWorkOrderModal({
      ...isArtificialWorkOrderModal,
      isOpen: true,
    });
    form.setFieldsValue({ dueDay: moment().format("yyyy-MM-DD") });
    call_getPNs();
  };

  const upSize = () => {
    setSopPdfInfo({ ...sopPdfInfo, scale: sopPdfInfo.scale + 0.1 });
  };

  const downSize = () => {
    setSopPdfInfo({ ...sopPdfInfo, scale: sopPdfInfo.scale - 0.1 });
  };

  const onDocumentLoadSuccess = (e) => {
    console.log(e._pdfInfo);
    setSopPdfInfo({ ...sopPdfInfoRef.current, pdfData: e._pdfInfo });
  };

  //途單索引點1下，獲取模具 GET
  const call_chooseWpcMold = async () => {
    try {
      const res = await chooseWpcMold(singleWpcRef.current.thisWpc);
      if (res.status) {
        const options = moldManagementOptions(res.data);
        setMoldManagement({
          ...moldManagementRef.current,
          data: res.data,
          options: options,
        });
        message.success(res.msg);
      } else {
        setMoldManagement({
          ...moldManagementRef.current,
          data: {},
          options: [],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_chooseWpcMoldInfo = async () => {
    try {
      const res = await chooseWpcMoldInfo({
        UUID: singleWpcRef.current.thisWpc.UUID,
      });
      if (res.status) {
        setOpenWork({
          ...openWorkRef.current,
          moldID: res.data.moldID,
          moldCavity: res.data.moldcavity,
          pickType: res.data.pickType,
        });
        setMoldManagement({ ...moldManagementRef.current, thisMold: res.data });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const moldManagementOptions = (molds) => {
    if (
      molds !== undefined &&
      molds !== null &&
      Object.getOwnPropertyNames(molds).length !== 0
    ) {
      const options = molds.map((x) => {
        return (
          <Option key={x.moldID} value={x.moldID} moldcavity={x.moldCavity}>
            {x.moldID}
          </Option>
        );
      });
      return options;
    } else {
      return [];
    }
  };

  const call_chooseWpcStaff = async () => {
    try {
      const res = await chooseWpcStaff(singleWpcRef.current.thisWpc);
      if (res.status) {
        if (res.data === null) {
          const staffOptions = [];
          const onWorkPeopleOptions = [];
          setTimeManagement({
            ...timeManagementRef.current,
            chooseStaff: staffOptions,
            onWorkPeopleOptions: onWorkPeopleOptions,
          });
        } else {
          const staffOptions = res.data.map((x) => {
            return <MyMainColorH3 key={x.cardID}>{x.name}</MyMainColorH3>;
          });
          const onWorkPeopleOptions = res.data.map((x) => {
            return <Option key={x.cardID}>{x.name}</Option>;
          });
          setTimeManagement({
            ...timeManagementRef.current,
            chooseStaff: staffOptions,
            onWorkPeopleOptions: onWorkPeopleOptions,
          });
        }
        call_getAllStaff();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //人工工單登錄 POST
  const call_manualWoN = async (data) => {
    try {
      setIsArtificialWorkOrderModal({
        ...isAPSWorkOrderModal,
        isLoading: true,
      });
      const res = await manualWoN(data);
      setIsArtificialWorkOrderModal({
        ...isAPSWorkOrderModal,
        isLoading: false,
      });
      if (res.status) {
        message.success(res.msg);
        setIsArtificialWorkOrderModal({
          isOpen: false,
          getPNs: [],
        });
        setIsProductionManagementModal(false);
        call_getAllWpc();
        form.resetFields(); //重製表單
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //獲取途單料表
  const call_getAllWpc = async () => {
    try {
      const res = await getAllWpc(allWpcRef.current.pageInfo);
      if (res.status) {
        const newAllWpc = res.data.woNs.map((x) => {
          const wlID = cookies.get("wlID");
          return {
            ...x,
            key: x.UUID,
            wlID: wlID,
            whcDay: `
            ${x.whcDay[0]}天 
            ${x.whcDay[1]}時 
            ${x.whcDay[2]}分 
            `,
            mhcDay: `
            ${x.mhcDay[0]}天 
            ${x.mhcDay[1]}時 
            ${x.mhcDay[2]}分 
            `,
          };
        });
        const thisWpcIndex = newAllWpc
          .map((x) => {
            return x.UUID;
          })
          .indexOf(allWpc.thisWpc.UUID);
        //代表剛被載入 現在判斷有無工單需要被鎖定
        let thisWpc = newAllWpc[thisWpcIndex] ? newAllWpc[thisWpcIndex] : {};
        if (thisWpcIndex === -1) {
          if (
            newAllWpc[0].lifeF === "2" ||
            newAllWpc[0].lifeF === "B" ||
            newAllWpc[0].lifeF === "3" ||
            newAllWpc[0].lifeF === "4"
          ) {
            thisWpc = newAllWpc[0];
          }
        }
        let newPageInfo = {
          ...allWpcRef.current.pageInfo,
          ...res.data.pageInfo,
        };
        setAllWpc({
          ...allWpcRef.current,
          data: newAllWpc,
          thisWpc: thisWpc,
          pageInfo: newPageInfo,
        });
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getWpc = async () => {
    try {
      const params = {
        page: wpcLoginRef.current.pageInfo.page,
        queryString: wpcLoginRef.current.queryString.queryString,
      };

      const res = await getWpc(params);
      if (res.status) {
        const wlID = cookies.get("wlID");
        const woN = res.data.woN.map((x) => {
          return {
            ...x,
            key: x.UUID,
            qty: x.planQty - x.d_planQty - x.d_inC,
            wlID: wlID,
          };
        });
        setWpcLogin({
          ...wpcLoginRef.current,
          data: woN,
          pageInfo: res.data.pageInfo,
          isOpen: true,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //途單撤登資料
  const call_getAllRtnWpc = async () => {
    try {
      const params = {
        page: returnWpcRef.current.pageInfo.page,
        queryString: returnWpcRef.current.queryString.queryString,
      };
      const res = await getAllRtnWpc(params);
      if (res.status) {
        const woN = res.data.woN.map((x) => {
          return { ...x, key: x.UUID };
        });
        setReturnWpc({
          ...wpcLogin,
          data: woN,
          pageInfo: res.data.pageInfo,
          isOpen: true,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };
  //途單撤登按鈕
  const call_returnWpcApi = async () => {
    try {
      const res = await returnWpcApi(returnWpc.thisData);
      if (res.status) {
        message.success(res.msg);
        setReturnWpc({ ...returnWpc, isOpen: false });
        setIsApsOperateModal(false);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const returnWpcButton = () => {
    Modal.confirm({
      title: <MyH1>確定開始撤銷該筆工單？</MyH1>,
      content: <MySubtitleH3>一旦撤登將無法復原</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "800px",
      onOk() {
        call_returnWpcApi();
      },
    });
  };

  const call_apsWoN = async () => {
    try {
      const res = await apsWoN(isAPSWorkOrderModal.thisData);
      if (res.status) {
        message.success(res.msg);
        setIsProductionManagementModal(false);
        setIsAPSWorkOrderModal({
          ...isAPSWorkOrderModalRef.current,
          isOpen: false,
          data: [],
          thisData: {},
        });
        setIsApsOperateModal(false);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getPNs = async () => {
    try {
      const res = await getPNs();
      if (res.status) {
        setIsArtificialWorkOrderModal({
          ...isArtificialWorkOrderModal,
          getPNs: res.data.PNs,
          isOpen: true,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getAps = async (e) => {
    try {
      const res = await getAps({
        queryString: apsQueryString,
        whichWlID: apsWhichQuery,
        ...isAPSWorkOrderModalRef.current.pageInfo,
      });
      if (res.status) {
        let apsData = [];
        if (res.data.aps.length > 0) {
          apsData = res.data.aps;
        } else {
          apsData = [];
        }
        setIsAPSWorkOrderModal({
          ...isAPSWorkOrderModalRef.current,
          pageInfo: res.data.pageInfo,
          data: apsData,
          isOpen: true,
        });
      } else {
        setIsAPSWorkOrderModal({
          ...isAPSWorkOrderModalRef.current,
          pageInfo: {},
          data: [],
          isOpen: true,
        });

        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_upStaff = async () => {
    try {
      if (singleWpc.thisWpc.UUID === undefined) {
        message.error("請先選擇途單");
        return;
      }
      const res = await upStaff({
        UUID: singleWpc.thisWpc.UUID,
        cardID: timeManagementRef.current.staff,
      });
      if (res.status) {
        //上綱成功刷新staff列表
        call_chooseWpcStaff();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_downStaff = async () => {
    try {
      const res = await downStaff({
        cardID: timeManagementRef.current.staff,
        UUID: singleWpc.thisWpc.UUID,
      });
      if (res.status) {
        setTimeManagement({ ...timeManagementRef.current, staff: "" });
        call_chooseWpcStaff();
      } else {
        message.error(res.msg);
        // console.log("call_downStaff", res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getAllStaff = async () => {
    try {
      const res = await getAllStaffs();
      if (res.status) {
        const options = res.data.map((x) => {
          return <Option key={x.cardID}>{`${x.cardID} ${x.peopleNM}`}</Option>;
        });
        setTimeManagement({ ...timeManagementRef.current, staffData: options });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //前拆
  const call_prefix = async () => {
    try {
      if (prefixModal.UUID === "" || prefixModal.d_planQty <= 0) {
        message.error("拆單失敗，請輸入正確拆單數量");
        return;
      }
      const res = await prefix({ ...singleWpc.thisWpc, ...prefixModal });
      if (res.status) {
        message.success(res.msg);
        setPrefixModal({
          isOpen: false,
          d_planQty: 0,
          UUID: "",
          woN: "",
        });
        call_getAllWpc();
      } else {
        message.success(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //後拆
  const call_suffix = async () => {
    try {
      if (
        singleWpcRef.current.thisWpc.UUID === "" ||
        suffixModalRef.current.inputC <= 0
      ) {
        message.error("拆單失敗，請輸入正確拆單數量");
        return;
      }
      const res = await suffix({
        ...singleWpcRef.current.thisWpc,
        ...suffixModalRef.current,
      });
      if (res.status) {
        message.success(res.msg);
        setSuffixModal({
          isOpen: false,
          d_planQty: 0,
          UUID: "",
          woN: "",
        });
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_downLine = async () => {
    try {
      const res = await downLine(singleWpcRef.current.thisWpc);
      if (res.status) {
        call_getAllWpc();
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //開始生產
  const call_open = async () => {
    try {
      if (vendorFormData.oemitem === "") {
        message.error("請選擇加工項目");
        return;
      }
      if (vendorFormData.suppID === "") {
        message.error("請選擇廠家");
        return;
      }
      const res = await open({
        ...singleWpcRef.current.thisWpc,
        ...openWork,
        ...vendorFormData,
      });
      if (res.status) {
        message.success(res.msg);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //報停工
  const call_pause = async () => {
    try {
      const res = await pause({
        ...singleWpcRef.current.thisWpc,
        ...stopWorkData,
      });
      if (res.status) {
        message.success(res.msg);
        setStopWorkData({ ...stopWorkData, isOpen: false });
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //報復工
  const call_resume = async () => {
    try {
      // console.log("singleWpcRef.current", singleWpcRef.current);
      const res = await resume(singleWpcRef.current.thisWpc);
      if (res.status) {
        message.success(res.msg);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_wpc = async () => {
    try {
      const res = await wpc(wpcLoginRef.current.thisData);
      if (res.status) {
        setWpcLogin({ ...wpcLogin, isOpen: false });
        call_getAllWpc();
        message.success(res.msg);
        setIsApsOperateModal(false);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };
  //報多單用
  const call_multiPrepare = async (data) => {
    const res = await prepare(data);
    if (res.status) {
      message.success(res.msg);
      setMultipleWon({ ...multipleWonRef.current, isOpen: false });
      multipleWonForm.resetFields();
      call_getAllWpc();
    } else {
      message.error(res.msg);
    }
  };

  const prepareConfirm = () => {
    Modal.confirm({
      title: <MyH1>確認報開工？</MyH1>,
      content: (
        <Space direction="vertical">
          <MySubtitleH3>請先確認相關生產程序已就緒完成</MySubtitleH3>
          <MySubtitleH3>料號：{singleWpc.thisWpc.PN}</MySubtitleH3>
          <MySubtitleH3>品名：{singleWpc.thisWpc.pdtNM}</MySubtitleH3>
          <MySubtitleH3>計畫產量：{singleWpc.thisWpc.planQty}</MySubtitleH3>
        </Space>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "900px",
      onOk() {
        call_prepare();
      },
    });
  };

  const call_prepare = async () => {
    try {
      //這邊不需要報多單 故不需要array
      const res = await prepare(singleWpcRef.current.thisWpc);
      if (res.status) {
        message.success(res.msg);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };
  //報完工
  const call_close = async () => {
    try {
      const res = await close(singleWpcRef.current.thisWpc);
      if (res.status) {
        message.success(res.msg);
        setOpenWorkSocket(false);
        setSingleWpc({ ...singleWpc, thisWpc: {} });
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getPauseList = async () => {
    try {
      const res = await getPauseList();
      if (res.status) {
        const pauseOptions = res.data.map((x) => {
          return (
            <Option key={x.itemID} value={x.itemID}>
              {x.itemNM}
            </Option>
          );
        });
        setStopWorkData({ ...stopWorkData, repoOptions: pauseOptions });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getDirList = async () => {
    try {
      const res = await getDirList();
      if (res.status) {
        const dirOptions = res.data.map((x) => {
          return (
            <Option key={x.itemID} value={x.itemID}>
              {x.dri}
            </Option>
          );
        });
        setAbnormalRepo({
          ...abnormalRepoRef.current,
          unitOptions: dirOptions,
          unitData: res.data,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getAbnList = async () => {
    try {
      const res = await getAbnList();
      if (res.status) {
        const abnOptions = res.data.map((x) => {
          return (
            <Option key={x.itemID} value={x.itemID}>
              {x.itemNM}
            </Option>
          );
        });
        setAbnormalRepo({
          ...abnormalRepoRef.current,
          repoOptions: abnOptions,
          repoData: res.data,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_report = async (obj) => {
    try {
      const res = await report(obj);
      if (res.status) {
        message.success(res.msg);
        setAbnormalRepo({
          isOpen: false,
          unitOptions: [], //單位options
          repoOptions: [], //項目options
          rptUnit: "", //提報部門
          rptItem: "", //提報項目
          AbnDri: "", //責任單位
          rptMsg: "",
          unitData: [],
          repoData: [],
        });
        abnForm.resetFields();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log();
    }
  };

  const call_resolve = async (obj) => {
    try {
      const res = await resolve(obj);
      if (res.status) {
        message.success(res.msg);
        reAbnForm.resetFields();
        setAbnormalRemove({
          isOpen: false,
          data: [],
          abnOptions: [],
          repoItem: "", //提報項目
          abnDri: "", //責任單位
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getAllAbn = async () => {
    try {
      const res = await getAllAbn({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        const allAbnOptions = res.data.map((x) => {
          return (
            <Option key={x.abnSN} value={x.abnSN}>
              {x.abnSN}
            </Option>
          );
        });
        setAbnormalRemove({
          ...abnormalRemoveRef.current,
          abnOptions: allAbnOptions,
          data: res.data,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_sip = async () => {
    try {
      const res = await sip();
      if (res.status) {
        setSipPdfInfo({
          ...sipPdfInfoRef.current,
          pdfUrl: res.data.pdfUrl,
          msg: "",
        });
      } else {
        setSipPdfInfo({ ...sipPdfInfoRef.current, pdfUrl: "", msg: res.msg });
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_sop = async () => {
    try {
      const res = await sop(singleWpcRef.current.thisWpc);
      if (res.status) {
        setSopPdfInfo({
          ...sopPdfInfoRef.current,
          pdfUrl: res.data.pdfUrl,
          msg: "",
        });
      } else {
        setSopPdfInfo({ ...sopPdfInfoRef.current, pdfUrl: "", msg: res.msg });
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //多單
  const call_getMulti = async () => {
    try {
      const res = await getMulti(singleWpcRef.current.thisWpc);
      if (res.status) {
        let result = [];
        for (const x of res.data) {
          if (x.lineMix === 1) {
            result.unshift({ ...x, key: x.lineMix });
          } else if (x.lineMix > 1) {
            result.push({ ...x, key: x.lineMix });
          }
        }
        setMultipleWon({ ...multipleWon, multiInfo: result });
      } else {
        setMultipleWon({ ...multipleWon, multiInfo: [] });
      }
    } catch (e) {
      setMultipleWon({ ...multipleWon, multiInfo: [] });
      console.log(e);
    }
  };

  const searchWpc = async (value) => {
    let newPageInfo = allWpcRef.current.pageInfo;
    newPageInfo.queryString = value;
    setAllWpc({ ...allWpcRef.current, pageInfo: newPageInfo });
    call_getAllWpc();
  };

  const serchLoginWpc = async (value) => {
    const newData = {
      queryString: value,
    };
    setWpcLogin({ ...wpcLogin, queryString: newData });
    call_getWpc();
  };

  const searchAPS = async (value) => {
    try {
      const res = await getAps({ queryString: value });
      if (res.status) {
        setIsAPSWorkOrderModal({
          ...isAPSWorkOrderModalRef.current,
          data: res.data.aps,
        });
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openAbnormalRemoveModal = () => {
    setAbnormalRemove({ ...abnormalRemoveRef.current, isOpen: true });
    call_getAllAbn();
  };

  const openWorkButton = () => {
    Modal.confirm({
      title: <MyH1>確定開始生產該筆工單？</MyH1>,
      content: (
        <MySubtitleH3>請先確認相關生產程序已就緒完成，在開始生產</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "800px",
      onOk() {
        call_open();
      },
    });
  };

  const offLineButton = () => {
    Modal.confirm({
      title: <MyH1>是否對該筆工單進行下線？</MyH1>,
      content: (
        <MySubtitleH3>請先確認生產作業程序無誤，再進行下線</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_downLine();
      },
    });
  };

  const stopWorkButton = () => {
    Modal.confirm({
      title: <MyH1>是否對該筆工單進行停工？</MyH1>,
      content: (
        <MySubtitleH3>請先確認表單內容填寫無誤，在進行停工</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_pause();
      },
    });
  };

  const startWorkButton = () => {
    Modal.confirm({
      title: <MyH1>是否對該筆工單進行復工？</MyH1>,
      content: (
        <MySubtitleH3>請先確認表單內容填寫無誤，在進行復工</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_resume();
      },
    });
  };

  const closeWorkOrderButton = () => {
    Modal.confirm({
      title: <MyH1>是否對該筆工單進行結單？</MyH1>,
      content: (
        <MySubtitleH3>請先確認表單內容填寫無誤，在進行結單</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_close();
      },
    });
  };

  const apsLoginCheck = () => {
    Modal.confirm({
      title: <MyH1>確定登錄該筆工單嗎？</MyH1>,
      content: (
        <MySubtitleH3>工單：{isAPSWorkOrderModal.thisData.woN}</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_apsWoN();
      },
    });
  };

  const wpcLoginCheck = () => {
    Modal.confirm({
      title: <MyH1>確定登錄該筆工單嗎？</MyH1>,
      content: <MySubtitleH3>工單：{wpcLogin.thisData.woN}</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_wpc();
      },
    });
  };

  //前拆單
  const prefixLoginCheck = () => {
    Modal.confirm({
      title: <MyH1>確認對該筆工單進行前拆作業？</MyH1>,
      content: (
        <MySubtitleH3>請先確認輸入的數值無誤，再進行前拆單</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_prefix();
      },
    });
  };

  //後拆單
  const suffixLoginCheck = () => {
    Modal.confirm({
      title: <MyH1>確認對該筆工單進行後拆作業？</MyH1>,
      content: (
        <MySubtitleH3>請先確認輸入的數值無誤，再進行後拆單</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      onOk() {
        call_suffix();
      },
    });
  };

  const multipleWonSubmit = async () => {
    Modal.confirm({
      title: <MyH1>確定進行報多單嗎？</MyH1>,
      content: <MySubtitleH3>請確認選擇的是正確的副單</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      async onOk() {
        await multipleWonForm
          .validateFields()
          .then((val) => {
            let formObj = {
              main: singleWpcRef.current.thisWpc.UUID,
              multi: [val.firstVice],
            };
            if (val.vice !== undefined) {
              val.vice.forEach((x) => {
                formObj.multi.push(x.vice);
              });
            }
            //Array.from(new Set(result.multi)) 用來篩選不重複的工單
            if (
              formObj.multi.length !== Array.from(new Set(formObj.multi)).length
            ) {
              message.error("請勿選擇重複的副單");
              return false;
            } else {
              const resultArray = [];
              resultArray.push(singleWpcRef.current.thisWpc);
              formObj.multi.forEach((x) => {
                multipleWon.data.forEach((i) => {
                  if (x === i.UUID) {
                    resultArray.push(i);
                  }
                });
              });
              call_multiPrepare(resultArray);
            }
          })
          .catch(() => {
            //關閉3級Modal
            return false;
          });
      },
    });
  };

  const awoLoginCheck = async () => {
    let getAWOForm = await form.validateFields();
    getAWOForm.issueDay = moment(getAWOForm.issueDay).format("yyyy-MM-DD");
    getAWOForm.planQty = getAWOForm.planQty - 0;
    const getIndex = isArtificialWorkOrderModal.getPNs
      .map((x) => {
        return x.PN;
      })
      .indexOf(getAWOForm.PN);
    getAWOForm.planHead =
      isArtificialWorkOrderModal.getPNs[getIndex].planHead - 0;
    call_manualWoN(getAWOForm);
  };

  const openAbnormalRepoModal = () => {
    call_getAbnList();
    call_getDirList();

    setAbnormalRepo({ ...abnormalRepo, isOpen: true });
  };

  const abnSubmit = async () => {
    Modal.confirm({
      title: <MyH1>是否進行異常提報？</MyH1>,
      content: <MySubtitleH3>請先確認表單內容無誤，再進行提報</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      async onOk() {
        await abnForm
          .validateFields()
          .then((val) => {
            const rptTypeIndex = abnormalRepo.repoData
              .map((x) => {
                return x.itemID;
              })
              .indexOf(val.rptItem);
            const params = {
              ...singleWpc.thisWpc,
              ...val,
              itemType: abnormalRepo.repoData[rptTypeIndex].itemType,
            };
            call_report(params);
          })
          .catch((err) => {
            return false;
          });
      },
    });
  };

  const reabnSubmit = async () => {
    Modal.confirm({
      title: <MyH1>是否進行異常解除</MyH1>,
      content: (
        <MySubtitleH3>請先確認表單內容無誤，再進行異常解除</MySubtitleH3>
      ),
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: "600px",
      async onOk() {
        await reAbnForm
          .validateFields()
          .then((val) => {
            const data = {
              msg: val.rptMsg,
              abnSN: val.rptUnit,
              UUID: singleWpcRef.current.thisWpc.UUID,
            };
            call_resolve(data);
          })
          .catch((err) => {
            return false;
          });
      },
    });
  };

  //form 日期綁定 不能選擇今天之前
  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  //流程控制
  const process = (lifeF) => {
    // 當table 變動時 滾回最上方
    switch (lifeF) {
      case "1":
        setProcessManagementButton({ ...processManagementButton, state: 1 });
        break;
      case "B":
        setProcessManagementButton({ ...processManagementButton, state: 2 });
        break;
      case "R":
        setProcessManagementButton({ ...processManagementButton, state: 2 });
        break;
      case "2":
        setProcessManagementButton({ ...processManagementButton, state: 3 });
        break;
      case "4":
        setProcessManagementButton({ ...processManagementButton, state: 3 });
        break;
      case "3":
        setProcessManagementButton({ ...processManagementButton, state: 4 });
        break;
      case "5":
        setProcessManagementButton({ ...processManagementButton, state: 5 });
        break;
      case "7":
        setProcessManagementButton({ ...processManagementButton, state: 0 });
        break;
      default:
        setProcessManagementButton({ ...processManagementButton, state: 0 });
    }
  };

  const abnormalRemoveWonChange = (e) => {
    const abnDataIndex = abnormalRemove.data
      .map((x) => {
        return x.abnSN;
      })
      .indexOf(e);
    setAbnormalRemove({
      ...abnormalRemoveRef.current,
      repoItem: abnormalRemove.data[abnDataIndex].rptItemNM,
      abnDri: abnormalRemove.data[abnDataIndex].abnDriNM,
    });
  };

  //當人員上下崗被改變更新 在崗人員
  useEffect(() => {
    call_chooseWpcStaff();
  }, [
    timeManagementRef.current.isOffWork,
    timeManagementRef.current.isStaffModal,
  ]);

  //開始的基本資料
  useEffect(() => {
    call_getAllWpc();
    call_getAllStaff();
    changeStaffSocketSocketParams({
      url: url,
      apiName: "staffSocket",
      params: `"wsID=${cookies.get("wsID")}`,
    });
    setStaffSocket(true);
    call_getPauseList();

    //all wpc queryString
    changeScanSocketParams({
      url: url,
      apiName: "scan/socket",
    });
    setIsScanSocket(true);

    const timer = setInterval(() => {
      setTime(time());
    }, 1000);
    //畫面Rendering 完之後塞到dom
    return () => {
      clearInterval(timer);
    };
    //只執行一次
  }, []);

  useEffect(() => {
    if (scanSocket.status) {
      let newPageInfo = allWpcRef.current.pageInfo;
      newPageInfo.queryString = scanSocket.data;
      setAllWpc({ ...allWpcRef.current, pageInfo: newPageInfo });
      call_getAllWpc();
    }
  }, [scanSocket]);

  //被變更thisWpc
  useEffect(() => {
    if (
      allWpc.thisWpc !== undefined &&
      allWpc.thisWpc !== null &&
      Object.getOwnPropertyNames(allWpc.thisWpc).length !== 0
    ) {
      setSingleWpc({
        ...singleWpcRef.current,
        thisWpc: allWpcRef.current.thisWpc,
      });
      changeOpenWorkSocketParams({
        url: url,
        apiName: "openSocket",
        params: `UUID=${singleWpcRef.current.thisWpc.UUID}`,
      });

      call_chooseWpcStaff();
      call_chooseWpcMold();
      call_getMulti();
      setPrefixModal({
        ...prefixModal,
        UUID: singleWpcRef.current.thisWpc.UUID,
        woN: singleWpcRef.current.thisWpc.woN,
      });
      if (
        singleWpcRef.current.thisWpc.lifeF !== "B" ||
        singleWpcRef.current.thisWpc.lifeF !== "R"
      ) {
        call_chooseWpcMoldInfo();
      }
    } else {
      setSingleWpc({ ...singleWpc, thisWpc: {} });
      setProcessManagementButton({ ...processManagementButton, state: 0 });
      setPrefixModal({ ...prefixModal, UUID: "", woN: "" });
    }
  }, [allWpc.thisWpc]);

  useEffect(() => {
    //lifeF = 4 or 2 open Websocket
    process(singleWpc.thisWpc.lifeF);
    setLgint(singleWpc.thisWpc.situF);
    if (
      openWorkSocket.status === false &&
      singleWpcRef.current.thisWpc.lifeF === "2"
    ) {
      // console.log("當前途單  002-0005 @@@@@@@@@@/n",allWpcRef.current.thisWpc.lifeF);
      setOpenWorkSocket(true);
    } else if (
      openWorkSocket.status === false &&
      singleWpcRef.current.thisWpc.lifeF === "4"
    ) {
      setOpenWorkSocket(true);
    } else if (
      openWorkSocket.status === true &&
      singleWpcRef.current.thisWpc.lifeF !== "2" &&
      singleWpcRef.current.thisWpc.lifeF !== "4"
    ) {
      //關閉 webSocket 連線 get 工單最新資料
      setOpenWorkSocket(false);
      // console.log("當前途單  002--------------1 /n");
    }
  }, [singleWpc.thisWpc]);

  //websocket 人員連線
  useEffect(() => {
    if (
      staffSocket !== undefined &&
      staffSocket !== null &&
      Object.getOwnPropertyNames(staffSocket).length !== 0
    ) {
      if (staffSocket.data.cardID === "noData") {
      } else if (staffSocket.data.ok) {
        message.success(
          `${staffSocket.data.cardID} ${staffSocket.data.description}`
        );
        call_getAllStaff();
        call_chooseWpcStaff();
      } else {
        if (staffSocket.data.cardID !== undefined) {
          message.error(
            `${staffSocket.data.cardID} ${staffSocket.data.description}`
          );
        }
      }
    }
  }, [staffSocket]);

  //open socket data處理
  useEffect(() => {
    if (openWorkSocket.status) {
      // console.log("@@@@@@@openWorkSocket", openWorkSocket);
      const wlID = cookies.get("wlID");
      const newData = {
        ...openWorkSocket.data,
        key: openWorkSocket.data.UUID,
        wlID: wlID,
        whcDay: Array.isArray(openWorkSocket.data.whcDay)
          ? `
            ${openWorkSocket.data.whcDay[0]}天 
            ${openWorkSocket.data.whcDay[1]}時 
            ${openWorkSocket.data.whcDay[2]}分 
            `
          : "0天0時0分",
        mhcDay: Array.isArray(openWorkSocket.data.mhcDay)
          ? `
          ${openWorkSocket.data.mhcDay[0]}天 
          ${openWorkSocket.data.mhcDay[1]}時 
          ${openWorkSocket.data.mhcDay[2]}分 
          `
          : "0天0時0分",
      };
      // /!!!!!!!!!!!!!!!!/
      setSingleWpc({ ...singleWpcRef.current, thisWpc: newData });
      let mold = {
        ...openWorkRef.current,
        cumPdtC: openWorkSocket.data.cumPdtC,
        nextMmrFreq: openWorkSocket.data.nextMmrFreq,
        pdtC: openWorkSocket.data.pdtC,
        moldCavity: openWorkSocket.data.moldCavity,
      };
      setMoldManagement({ ...moldManagement, thisMold: mold });
    }
  }, [openWorkSocket]);

  //pdf 處理
  useEffect(() => {
    if (!pdfRef.current) {
      return;
    }
    //此為橫式pdf
    if (pdfRef.current.clientHeight < 600) {
      setSopPdfInfo({ ...sopPdfInfoRef.current, width: 1200 });
      //此為直式pdf
    } else if (pdfRef.current.clientHeight > 600) {
      setSopPdfInfo({ ...sopPdfInfoRef.current, width: 500 });
    }
  }, [pdfRef]);

  //流程管理
  useEffect(() => {
    //判斷table 是否需要返回頂點
    let parentDom = document.getElementById("table");

    const tableScrollTop = () => {
      let childDom =
        parentDom && parentDom.getElementsByClassName("ant-table-body");
      childDom[0].scrollTop = 0;
    };
    switch (processManagementButton.state) {
      //預設模式
      case 0:
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: false,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: true,
            moldManagement: true,
            timeManagement: true,
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: true, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
      case 1: //點了途單索引 lifeF為1
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: false,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: false, //事前準備
            prefixWork: false,
            openWork: true,
            moldManagement: true,
            timeManagement: true,
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: true, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
      case 2: //lifeF = B or R
        //是否需要模具或工時管理
        tableScrollTop();
        const mold = singleWpc.thisWpc.set2mold === "Y" ? false : true;
        const time = singleWpc.thisWpc.set3whm === "Y" ? false : true;
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: true,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: false, //條件式 需要判斷有無購買模具管理 工時一定要有
            moldManagement: mold,
            timeManagement: time,
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: true, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
      case 3: //lifeF = 2 & 4 go & 再復工
        tableScrollTop();

        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: true,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: true, //條件式 需要判斷有無購買模具管理 工時一定要有
            moldManagement: true,
            timeManagement: false,
            closeWorkOrder: false, //報完工
            suffixWork: false, //後拆單
            offLine: false, //報下線
            workStop: false, //報停工
            resumeWork: true, //報復工
            abcReport: false, //異常提報
            abcDismiss: false, //異常解除
          },
        });
        break;
      case 4: //lifeF = 3
        tableScrollTop();
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: true,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: true, //條件式 需要判斷有無購買模具管理 工時一定要有
            moldManagement: true,
            timeManagement: true, //lifeF = br246
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: false, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
      case 5: //lifeF = 5 //報下線
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: false,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: true, //條件式 需要判斷有無購買模具管理 工時一定要有
            moldManagement: true,
            timeManagement: true, //lifeF = br246
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: true, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
      case 6: //lifeF = 6
        setProcessManagementButton({
          ...processManagementButton,
          button: {
            wpcTable: false,
            productionManagement: false, //生管派工
            wpcLogin: false,
            readyStart: true, //事前準備
            prefixWork: true,
            openWork: true, //條件式 需要判斷有無購買模具管理 工時一定要有
            moldManagement: true,
            timeManagement: true, //lifeF = br246
            closeWorkOrder: true, //報完工
            suffixWork: true, //後拆單
            offLine: true, //報下線
            workStop: true, //報停工
            resumeWork: true, //報復工
            abcReport: true, //異常提報
            abcDismiss: true, //異常解除
          },
        });
        break;
    }
  }, [processManagementButton.state]);

  function time() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDay();
    const hour = today.getHours();
    const min = today.getMinutes();
    const sec = today.getSeconds();
    // 轉成字串，如果低於10，前面加上'0'
    const monthString = month < 10 ? "0" + month : "" + month;
    const dayString = day < 10 ? "0" + day : "" + day;
    const hourString = hour < 10 ? "0" + hour : "" + hour;
    const minString = min < 10 ? "0" + min : "" + min;
    const secString = sec < 10 ? "0" + sec : "" + sec;
    return `${year}-${monthString}-${dayString} ${hourString}:${minString}:${secString}`;
  }

  const produceWorkTabComponent = {
    textAlign: "center",
    // padding: "5px 15px",
    height: "210px",
  };

  const moldTabComponent = {
    textAlign: "left",
    paddingBottom: "14px",
    height: "270px",
  };

  const bigButtonStyle = {
    width: "100%",
    height: "110px",
    maxWidth: "180px",
    textAlign: "center",
  };

  const firstPageButtonStyle = {
    height: "50px",
    width: "100%",
    minWidth: "140px",
  };

  const sopButtonStyle = {
    color: "#FF3300",
    border: "1px solid #FF3300",
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
    height: "40px",
    width: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "23px",
  };

  const sipButtonStyle = {
    color: "#0066FF",
    border: "1px solid #0066FF",
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
    height: "40px",
    width: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "23px",
  };

  const outButtonStyle = {
    color: "#00CC00",
    border: "1px solid #00CC00",
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
    height: "40px",
    width: "130px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "23px",
  };

  return (
    <>
      <MyPageName>
        <Row justify="center" align="top">
          <Col span={2}>
            <MyPageTitle>委外報工</MyPageTitle>
          </Col>
          <Col span={22} style={{ textAlign: "right" }}>
            <Space align="center" size={20}>
              <SituFlight light={light} />
              <div
                style={sopButtonStyle}
                onClick={() => {
                  setSopPdfInfo({ ...sopPdfInfoRef.current, isOpen: true });
                  call_sop();
                }}
              >
                <Tooltip placement="bottom" title="查看SOP">
                  SOP瀏覽
                  {/* <MyIcon.PDF /> */}
                </Tooltip>
              </div>
              {/* SIP 尚未做 */}
              <div
                style={sipButtonStyle}
                onClick={() => {
                  setSipPdfInfo({ ...sipPdfInfoRef.current, isOpen: true });
                  call_sip();
                }}
              >
                <Tooltip placement="bottom" title="SIP預覽">
                  SIP預覽
                  {/* <MyIcon.Print /> */}
                </Tooltip>
              </div>
              <div
                style={outButtonStyle}
                onClick={() => {
                  if (singleWpc.thisWpc.lifeF === "2" || singleWpc.thisWpc.lifeF === "4"){
                    setIsPrinterModal(true)
                  }else{
                    message.error("當前不可列印委外工單")
                  }
                }}
              >
                <Tooltip placement="bottom" title="委外加工單">
                  委外加工單
                  {/* <MyIcon.Print /> */}
                </Tooltip>
              </div>

              <WsWlSelect />
            </Space>
          </Col>
        </Row>
      </MyPageName>
      <Row justify="space-between" align="bottom">
        <Col span={24}>
          <Row justify="space-between" align="bottom" gutter={[0, 12]}>
            <Col span={24}>
              <MyComponentTwo>
                <Row align="middle" gutter={[12, 0]}>
                  <Col span={12}>
                    <MyComponent
                      className="card-container"
                      style={{ boxShadow: "none" }}
                    >
                      <Tabs size="large" style={produceWorkTabComponent}>
                        <TabPane tab="報開工" key="1">
                          <Row justify="space-around" align="middle">
                            <Col span={6}>
                              <Space
                                direction="vertical"
                                style={{ width: "80%" }}
                              >
                                <MyButton.MyButton1
                                  style={firstPageButtonStyle}
                                  onClick={() =>
                                    setIsProductionManagementModal(true)
                                  }
                                  disabled={
                                    processManagementButton.button
                                      .productionManagement
                                  }
                                >
                                  生&nbsp;管&nbsp;派&nbsp;工
                                </MyButton.MyButton1>
                                <MyButton.MyButton2
                                  style={firstPageButtonStyle}
                                  disabled={
                                    processManagementButton.button.wpcLogin
                                  }
                                  onClick={() => {
                                    setIsApsOperateModal(true);
                                  }}
                                >
                                  途&nbsp;單&nbsp;登&nbsp;錄
                                </MyButton.MyButton2>
                              </Space>
                            </Col>
                            <Col span={3}>
                              <SocialMedia.ArrowIcon
                                style={{ fontSize: "46px", color: "#9AC9FF" }}
                              />
                            </Col>
                            <Col span={6}>
                              <Space
                                direction="vertical"
                                style={{ width: "80%" }}
                              >
                                <MyButton.MyButton1
                                  style={firstPageButtonStyle}
                                  disabled={
                                    processManagementButton.button.readyStart
                                  }
                                  onClick={prepareConfirm}
                                >
                                  報&nbsp;開&nbsp;工
                                </MyButton.MyButton1>
                                <MyButton.MyButton3
                                  style={firstPageButtonStyle}
                                  onClick={() => {
                                    setPrefixModal({
                                      ...prefixModal,
                                      isOpen: true,
                                    });
                                  }}
                                  disabled={
                                    processManagementButton.button.prefixWork
                                  }
                                >
                                  前&nbsp;拆&nbsp;單
                                </MyButton.MyButton3>
                              </Space>
                            </Col>
                            <Col span={3}>
                              <SocialMedia.ArrowIcon
                                style={{ fontSize: "46px", color: "#9AC9FF" }}
                              />
                            </Col>
                            <Col span={6}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.openWork
                                }
                                onClick={openWorkButton}
                              >
                                委&nbsp;外&nbsp;加&nbsp;工
                              </MyButton.MyButton1>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="報完工" key="2">
                          <Row
                            justify="space-around"
                            align="middle"
                            style={{ textAlign: "center" }}
                            gutter={[24, 0]}
                          >
                            <Col span={8}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.closeWorkOrder
                                }
                                onClick={closeWorkOrderButton}
                              >
                                報&nbsp;完&nbsp;工
                              </MyButton.MyButton1>
                            </Col>
                            <Col span={8}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.suffixWork
                                }
                                onClick={() => {
                                  setSuffixModal({
                                    ...suffixModal,
                                    isOpen: true,
                                  });
                                }}
                              >
                                後&nbsp;拆&nbsp;單
                              </MyButton.MyButton1>
                            </Col>
                            <Col span={8}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.offLine
                                }
                                onClick={offLineButton}
                              >
                                報&nbsp;下&nbsp;線
                              </MyButton.MyButton1>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tab="換廠商" key="3">
                          <Row
                            justify="space-around"
                            align="middle"
                            gutter={[24, 0]}
                          >
                            <Col span={12} style={{ textAlign: "center" }}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.workStop
                                }
                                onClick={() =>
                                  setStopWorkData({
                                    ...stopWorkData,
                                    isOpen: true,
                                  })
                                }
                              >
                                委&nbsp;外&nbsp;回&nbsp;廠
                              </MyButton.MyButton1>
                            </Col>
                            <Col span={12} style={{ textAlign: "center" }}>
                              <MyButton.MyButton1
                                style={bigButtonStyle}
                                disabled={
                                  processManagementButton.button.resumeWork
                                }
                                onClick={startWorkButton}
                              >
                                再&nbsp;發&nbsp;委&nbsp;外
                              </MyButton.MyButton1>
                            </Col>
                          </Row>
                        </TabPane>
                      </Tabs>
                    </MyComponent>
                  </Col>
                  <Col span={12}>
                    <WorkOrderInformation data={singleWpc.thisWpc} />
                  </Col>
                </Row>
              </MyComponentTwo>
            </Col>
            <Col span={24}>
              <Row align="bottom" gutter={[12, 0]}>
                <Col span={12}>
                  <MyComponent>
                    <Tabs size="large" style={moldTabComponent}>
                      <TabPane tab="委外管理" key="5">
                        <MyDisabled
                          disabled={
                            processManagementButton.button.moldManagement
                          }
                        >
                          <OutTab
                            singleWpc={singleWpc.thisWpc}
                            setVendorForm={setVendorFormData}
                          />
                        </MyDisabled>
                      </TabPane>
                    </Tabs>
                  </MyComponent>
                </Col>
                <Col span={12}>
                  <Productivity
                    data={singleWpc.thisWpc}
                    multiInfo={multipleWon.multiInfo}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <MyDisabled disabled={false}>
            <Row align="bottom">
              <Col span={12}>
                <MyMainColorH3>途單索引</MyMainColorH3>
              </Col>
              <Col span={12} style={{ textAlign: "right", margin: "6px 0" }}>
                <Search
                  placeholder="請輸入完整的工單或料號"
                  onSearch={searchWpc}
                  value={allWpc.pageInfo.queryString}
                  onChange={(e) => {
                    let newPageInfo = allWpcRef.current.pageInfo;
                    newPageInfo.queryString = e.target.value;
                    setAllWpc({ ...allWpcRef.current, pageInfo: newPageInfo });
                  }}
                  enterButton
                  size="large"
                  style={{ width: "380px" }}
                />
              </Col>
              <Col span={24}>
                <CustomTable
                  id="table"
                  bordered={true}
                  columns={wpcTableCol}
                  scroll={{ x: "max-content", y: 150 }}
                  dataSource={allWpc.data}
                  loading={allWpc.loading}
                  // defaultSelectedRowKeys={allWpc.data[0]?allWpc.data[0].UUID:''}
                  rowClassName={(record) => {
                    return record.UUID === allWpc.thisWpc.UUID
                      ? "clickRowStyl"
                      : "";
                  }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        if (record.UUID === allWpc.thisWpc.UUID) {
                          //點擊詳請不算
                          if (singleWpcRef.current.isOpen) {
                            setAllWpc({ ...allWpc, thisWpc: record });
                            setOpenWork({
                              ...openWorkRef.current,
                              UUID: record.UUID,
                              moldID: "",
                            });
                          } else {
                            setAllWpc({ ...allWpc, thisWpc: {} });
                            setOpenWork({
                              ...openWorkRef.current,
                              moldID: "",
                              moldType: "1",
                              UUID: "",
                            });
                            setProcessManagementButton({
                              ...processManagementButton,
                              state: 0,
                            });
                          }
                        } else {
                          setAllWpc({ ...allWpc, thisWpc: record });
                          setOpenWork({
                            ...openWorkRef.current,
                            UUID: record.UUID,
                            moldID: "",
                          });
                        }
                      },
                    };
                  }}
                  pagination={{
                    defaultCurrent: 1,
                    total: allWpc.pageInfo.totalItems
                      ? allWpc.pageInfo.totalItems
                      : 1,
                    current: allWpc.pageInfo.page ? allWpc.pageInfo.page : 1,
                    pageSize: allWpc.pageInfo.pageSize
                      ? allWpc.pageInfo.pageSize
                      : 10,
                    onChange: (current) => {
                      let pageData = allWpc.pageInfo;
                      pageData.page = current;
                      setAllWpc({ ...allWpc, pageInfo: pageData });
                      call_getAllWpc();
                    },
                    showTotal: (total, range) => (
                      <MySubtitleH4>
                        共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
                      </MySubtitleH4>
                    ),
                  }}
                />
              </Col>
            </Row>
          </MyDisabled>
        </Col>
      </Row>

      {/* //生管派工 */}
      <Modal
        title={<MyThemeH1>生管派工</MyThemeH1>}
        visible={isProductionManagementModal}
        onCancel={() => setIsProductionManagementModal(false)}
        cancelText="取消"
        footer={[
          <Button
            key="ModalCencel"
            onClick={() => setIsProductionManagementModal(false)}
          >
            取消
          </Button>,
        ]}
        width={"600px"}
        centered
        forceRender
      >
        <Row justify="space-between" align="center" gutter={[24, 0]}>
          <Col span={8} style={{ textAlign: "center" }}>
            <MyButton.MyButton1 style={bigButtonStyle} onClick={apsButton}>
              APS工單
            </MyButton.MyButton1>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <MyButton.MyButton2 style={bigButtonStyle} onClick={awoButton}>
              人工工單
            </MyButton.MyButton2>
          </Col>
          <Col span={8} style={{ textAlign: "center" }}>
            <MyButton.MyButton3 style={bigButtonStyle} disabled>
              條碼工單
            </MyButton.MyButton3>
          </Col>
        </Row>
      </Modal>

      {/* //途單登錄 */}
      <Modal
        title={<MyThemeH1>途單登錄</MyThemeH1>}
        visible={isApsOperateModal}
        onCancel={() => setIsApsOperateModal(false)}
        cancelText="取消"
        footer={[
          <Button key="ModalCencel" onClick={() => setIsApsOperateModal(false)}>
            取消
          </Button>,
        ]}
        width={"600px"}
        centered
        forceRender
      >
        <Row justify="center" align="middle" gutter={[24, 0]}>
          <Col span={12} style={{ textAlign: "center" }}>
            <MyButton.MyButton1 style={bigButtonStyle} onClick={call_getWpc}>
              途單登錄
            </MyButton.MyButton1>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <MyButton.MyButton2
              style={bigButtonStyle}
              onClick={call_getAllRtnWpc}
            >
              途單撤登
            </MyButton.MyButton2>
          </Col>
        </Row>
      </Modal>

      {/* //APS工單 */}
      <Modal
        title={<MyThemeH1>APS工單</MyThemeH1>}
        visible={isAPSWorkOrderModal.isOpen}
        onCancel={() =>
          setIsAPSWorkOrderModal({
            ...isAPSWorkOrderModalRef.current,
            isOpen: false,
          })
        }
        width={"1400px"}
        cancelText="取消"
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() =>
              setIsAPSWorkOrderModal({
                ...isAPSWorkOrderModalRef.current,
                isOpen: false,
              })
            }
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            disabled={isAPSWorkOrderModal.woN === "" ? true : false}
            onClick={apsLoginCheck}
          >
            確認
          </Button>,
        ]}
        centered
        forceRender
      >
        <Row justify="space-between" align="top" gutter={[24, 24]}>
          <Col span={24}>
            <Space>
              <Select
                defaultValue={apsWhichQuery}
                size="large"
                onChange={(e) => {
                  setApsWhichQuery(e);
                }}
              >
                <Option value="wl">本線工單</Option>
                <Option value="all">全線工單</Option>
              </Select>
              <Search
                placeholder="請輸入完整的工單或料號"
                onSearch={call_getAps}
                enterButton
                value={apsQueryString}
                onChange={(e) => {
                  setApsQueryString(e.target.value);
                }}
                style={{ width: "350px" }}
                size="large"
              />
            </Space>
          </Col>
          <Col span={24}>
            <Table
              bordered={true}
              columns={apsTableCol}
              dataSource={isAPSWorkOrderModal.data.map((x, index) => {
                return { ...x, key: index };
              })}
              rowClassName={(record) => {
                return record.woN === isAPSWorkOrderModal.thisData.woN
                  ? "clickRowStyl"
                  : "";
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (isAPSWorkOrderModal.thisData.woN === record.woN) {
                      setIsAPSWorkOrderModal({
                        ...isAPSWorkOrderModalRef.current,
                        thisData: {},
                      });
                    } else {
                      setIsAPSWorkOrderModal({
                        ...isAPSWorkOrderModalRef.current,
                        thisData: record,
                      });
                    }
                  },
                };
              }}
              pagination={{
                defaultCurrent: 1,
                total: isAPSWorkOrderModal.pageInfo.totalItems
                  ? isAPSWorkOrderModal.pageInfo.totalItems
                  : 1,
                current: isAPSWorkOrderModal.pageInfo.currentPage
                  ? isAPSWorkOrderModal.pageInfo.currentPage
                  : 1,
                pageSize: isAPSWorkOrderModal.pageInfo.pageSize
                  ? isAPSWorkOrderModal.pageInfo.pageSize
                  : 10,
                onChange: (current) => {
                  let pageData = isAPSWorkOrderModal.pageInfo;
                  pageData.page = current;
                  setIsAPSWorkOrderModal({
                    ...isAPSWorkOrderModal,
                    pageInfo: pageData,
                  });
                  call_getAps();
                },
                showTotal: (total, range) => (
                  <MySubtitleH4>
                    共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
                  </MySubtitleH4>
                ),
              }}
            />
          </Col>
        </Row>
      </Modal>

      {/* //途單撤登 */}
      <Modal
        title={<MyThemeH1>途單撤登</MyThemeH1>}
        visible={returnWpc.isOpen}
        onCancel={() => setReturnWpc({ ...returnWpc, isOpen: false })}
        width={"1200px"}
        cancelText="取消"
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => setReturnWpc({ ...returnWpc, isOpen: false })}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={returnWpcButton}
          >
            確認
          </Button>,
        ]}
        centered
        forceRender
      >
        <Row justify="space-between" align="top" gutter={[24, 24]}>
          <Col span={12}></Col>
          <Col span={6}>
            <Search
              placeholder="請輸入完整的工單或料號"
              onSearch={searchAPS}
              enterButton
            />
          </Col>
          <Col span={24}>
            <Table
              bordered={true}
              columns={wpcReturnTableCol}
              dataSource={returnWpc.data.map((x, index) => {
                return { ...x, key: index };
              })}
              rowClassName={(record) => {
                return record.woN === returnWpc.thisData.woN
                  ? "clickRowStyl"
                  : "";
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (returnWpc.thisData.woN === record.woN) {
                      setReturnWpc({
                        ...returnWpc,
                        thisData: {},
                      });
                    } else {
                      setReturnWpc({
                        ...returnWpc,
                        thisData: record,
                      });
                    }
                  },
                };
              }}
            />
          </Col>
        </Row>
      </Modal>

      {/* //人工工單 */}
      <Modal
        title={<MyThemeH1>人工工單</MyThemeH1>}
        visible={isArtificialWorkOrderModal.isOpen}
        onCancel={() =>
          setIsArtificialWorkOrderModal({
            ...isArtificialWorkOrderModal,
            isOpen: false,
          })
        }
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setIsArtificialWorkOrderModal({
                ...isArtificialWorkOrderModal,
                isOpen: false,
              });
              form.resetFields();
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={awoLoginCheck}
            loading={isArtificialWorkOrderModal.isLoading}
          >
            確認
          </Button>,
        ]}
        width={"800px"}
        centered
        forceRender
      >
        <Form
          form={form}
          name="artificialWorkOrderForm"
          layout="horizontal"
          size="large"
        >
          <Row justify="center" align="middle" gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                label={
                  <MySubtitleH3>
                    &nbsp;&nbsp;工線&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </MySubtitleH3>
                }
              >
                <Input placeholder={cookies.get("wlID")} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <MySubtitleH3>
                    工單號&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </MySubtitleH3>
                }
                name="woN"
                rules={[{ required: true, message: "請輸入工單號" }]}
              >
                <Input placeholder="請輸入工單號" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <MySubtitleH3>
                    單別&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </MySubtitleH3>
                }
              >
                <Input placeholder="正常工單" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<MySubtitleH3>計畫產量&nbsp;</MySubtitleH3>}
                name="planQty"
                rules={[
                  {
                    required: true,
                    message: "請輸入計畫產量",
                    validator: (_, value) => {
                      return new Promise(async (resolve, reject) => {
                        if (!value) {
                          await reject("請輸入計畫產量");
                        } else if (value < 1) {
                          await reject("計畫產量必須大於0");
                        } else if (value - 0 === NaN) {
                          await reject("請輸入正確的數字");
                        } else {
                          await resolve();
                        }
                      });
                    },
                  },
                ]}
              >
                <Input placeholder="請輸入計畫產量" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <MySubtitleH3>
                    途程號&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </MySubtitleH3>
                }
              >
                <Input placeholder="1" disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={
                  <MySubtitleH3>
                    料號&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </MySubtitleH3>
                }
                name="PN"
                rules={[{ required: true, message: "請選擇料號" }]}
              >
                <Select
                  showSearch
                  placeholder="請選擇料號"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(e) => {
                    const PN = e;
                    let pdtNM = "";
                    let planHead = 0;
                    for (const x of isArtificialWorkOrderModal.getPNs) {
                      if (x.PN === PN) {
                        pdtNM = x.pdtNM;
                        planHead = x.planHead;
                      }
                    }
                    form.setFieldsValue({ pdtNM: pdtNM });
                  }}
                >
                  {isArtificialWorkOrderModal.getPNs
                    ? isArtificialWorkOrderModal.getPNs.map((i) => {
                        return (
                          <Option key={i.PN} value={i.PN}>
                            {i.PN}
                          </Option>
                        );
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.PN !== currentValues.PN
                }
              >
                {({}) => {
                  return (
                    <Form.Item
                      label={
                        <MySubtitleH3>
                          &nbsp;&nbsp;品名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </MySubtitleH3>
                      }
                      name="pdtNM"
                    >
                      <Input placeholder="請先選擇料號" disabled />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<MySubtitleH3>&nbsp;&nbsp;發佈日期&nbsp;</MySubtitleH3>}
                name="dueDay"
              >
                <Input placeholder="請輸入發佈日期" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<MySubtitleH3>預計完成日期</MySubtitleH3>}
                name="issueDay"
                rules={[{ required: true, message: "請選擇完成日期" }]}
              >
                <DatePicker
                  placeholder="請選擇完成日期"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* //人員上崗 */}
      <Modal
        title={<MyThemeH1>人員上崗</MyThemeH1>}
        visible={timeManagementRef.current.isStaffModal}
        onCancel={() =>
          setTimeManagement({
            ...timeManagementRef.current,
            isStaffModal: false,
          })
        }
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setTimeManagement({
                ...timeManagementRef.current,
                isStaffModal: false,
              });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              call_upStaff();
            }}
          >
            確認
          </Button>,
        ]}
        width={"500px"}
        centered
        forceRender
      >
        <div>
          <MySubtitleH4 style={{ display: "block", margin: "10px 15px" }}>
            人員輸入
          </MySubtitleH4>
          <Select
            size="large"
            showSearch
            style={{ width: 430, display: "block", margin: "10px" }}
            placeholder="請輸入人員ID"
            optionFilterProp="children"
            value={timeManagementRef.current.staff}
            onChange={(e) => {
              setTimeManagement({ ...timeManagementRef.current, staff: e });
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {timeManagementRef.current.staffData}
          </Select>
        </div>
      </Modal>

      {/* //人員下崗 */}
      <Modal
        title={<MyThemeH1>人員下崗</MyThemeH1>}
        visible={timeManagementRef.current.isOffWork}
        onCancel={() =>
          setTimeManagement({ ...timeManagementRef.current, isOffWork: false })
        }
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setTimeManagement({
                ...timeManagementRef.current,
                isOffWork: false,
              });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              call_downStaff();
            }}
          >
            確認
          </Button>,
        ]}
        width={"500px"}
        centered
        forceRender
      >
        <div>
          <MySubtitleH4 style={{ display: "block", margin: "10px 15px" }}>
            人員輸入
          </MySubtitleH4>
          <Select
            size="large"
            showSearch
            style={{ width: 430, display: "block", margin: "10px" }}
            placeholder="請輸入人員ID"
            optionFilterProp="children"
            value={timeManagementRef.current.staff}
            onChange={(e) => {
              setTimeManagement({ ...timeManagementRef.current, staff: e });
            }}
          >
            {timeManagementRef.current.onWorkPeopleOptions}
          </Select>
        </div>
      </Modal>

      {/* //前拆作業 */}
      <Modal
        title={<MyThemeH1>前拆作業</MyThemeH1>}
        visible={prefixModal.isOpen}
        onCancel={() => setPrefixModal({ ...prefixModal, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setPrefixModal({ ...prefixModal, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={prefixLoginCheck}
          >
            確認
          </Button>,
        ]}
        width={"1200px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={prefixTableCol}
          dataSource={[{ ...singleWpc.thisWpc, key: "prefix123" }]}
          pagination={false}
        />
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>原計畫產量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(singleWpc.thisWpc.planQty)}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>已拆數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(singleWpc.thisWpc.d_planQty)}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>可拆數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(
                  singleWpc.thisWpc.planQty - allWpc.thisWpc.d_planQty
                )}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>拆單數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                <InputNumber
                  onPressEnter={prefixLoginCheck}
                  style={{ width: "100%" }}
                  min={1}
                  max={singleWpc.thisWpc.planQty - singleWpc.thisWpc.d_planQty}
                  defaultValue={0}
                  bordered={false}
                  size="large"
                  onChange={(e) => {
                    setPrefixModal({
                      ...prefixModal,
                      d_planQty: e - 0,
                      woN: singleWpc.thisWpc.woN,
                      UUID: singleWpc.thisWpc.UUID,
                    });
                  }}
                />
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
        </Row>
      </Modal>

      {/* //後拆作業 */}
      <Modal
        title={<MyThemeH1>後拆作業</MyThemeH1>}
        visible={suffixModal.isOpen}
        onCancel={() => setSuffixModal({ ...suffixModal, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setSuffixModal({ ...suffixModal, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={suffixLoginCheck}
          >
            確認
          </Button>,
        ]}
        width={"1200px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={prefixTableCol}
          dataSource={[{ ...singleWpc.thisWpc, key: "prefix123" }]}
          pagination={false}
        />
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>原計畫產量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(singleWpc.thisWpc.planQty)}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>已拆數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(singleWpc.thisWpc.d_inC)}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>可拆數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                {checkUndefined(
                  singleWpc.thisWpc.inC - singleWpc.thisWpc.d_inC
                )}
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
          <Col span={6}>
            <WOI.WOIMainBoxItem style={{ width: "100%" }}>
              <WOI.WOIMainBoxItemTitle>拆單數量</WOI.WOIMainBoxItemTitle>
              <WOI.WOIMainBoxItemContent>
                <InputNumber
                  onPressEnter={suffixLoginCheck}
                  style={{ width: "100%" }}
                  min={1}
                  max={singleWpc.thisWpc.inC - singleWpc.thisWpc.d_inC}
                  defaultValue={0}
                  bordered={false}
                  size="large"
                  onChange={(e) => {
                    setSuffixModal({
                      ...suffixModal,
                      inputC: e - 0,
                      woN: singleWpc.thisWpc.woN,
                      UUID: singleWpc.thisWpc.UUID,
                    });
                  }}
                />
              </WOI.WOIMainBoxItemContent>
            </WOI.WOIMainBoxItem>
          </Col>
        </Row>
      </Modal>

      {/* //途單索引詳情 */}
      <Modal
        title={<MyThemeH1>途單索引詳情</MyThemeH1>}
        visible={singleWpc.isOpen}
        onCancel={() => setSingleWpc({ ...singleWpc, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              setSingleWpc({ ...singleWpc, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1600px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={wpcDetailsTableCol}
          dataSource={[{ ...singleWpc.thisWpc, key: "prefix456" }]}
          pagination={false}
        />
      </Modal>

      {/* //途單登錄 */}
      <Modal
        title={<MyThemeH1>途單登錄</MyThemeH1>}
        visible={wpcLogin.isOpen}
        onCancel={() => setWpcLogin({ ...wpcLogin, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setWpcLogin({ ...wpcLogin, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              wpcLoginCheck();
            }}
          >
            確認
          </Button>,
        ]}
        width={"1200px"}
        centered
        forceRender
      >
        <Row>
          <Col span={12}></Col>
          <Col span={12} style={{ textAlign: "right", margin: "5px 0px" }}>
            <Search
              placeholder="請輸入完整的工單或料號"
              onSearch={serchLoginWpc}
              enterButton
              style={{ width: "230px" }}
            />
          </Col>
        </Row>
        <Table
          bordered={true}
          columns={wpcLoginTableCol}
          scroll={{ x: "max-content" }}
          dataSource={wpcLogin.data}
          rowClassName={(record) => {
            return record.UUID === wpcLogin.thisData.UUID ? "clickRowStyl" : "";
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.UUID === wpcLogin.thisData.UUID) {
                  //點擊詳請不算
                  if (wpcLoginRef.thisData) {
                    setWpcLogin({ ...wpcLogin, thisData: record });
                  } else {
                    setWpcLogin({ ...wpcLogin, thisData: {} });
                  }
                } else {
                  setWpcLogin({ ...wpcLogin, thisData: record });
                }
              },
            };
          }}
          pagination={{
            defaultCurrent: 1,
            total: wpcLogin.pageInfo.totalItems
              ? wpcLogin.pageInfo.totalItems
              : 1,
            current: wpcLogin.pageInfo.page ? wpcLogin.pageInfo.page : 1,
            pageSize: wpcLogin.pageInfo.pageSize
              ? wpcLogin.pageInfo.pageSize
              : 10,
            onChange: (current) => {
              let pageData = wpcLogin.pageInfo;
              pageData.page = current;
              setWpcLogin({ ...wpcLogin, pageInfo: pageData });
              call_getWpc();
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      </Modal>

      {/* //異常提報 */}
      <Modal
        title={<MyThemeH1>異常提報</MyThemeH1>}
        visible={abnormalRepo.isOpen}
        onCancel={() => {
          setAbnormalRepo({ ...abnormalRepo, isOpen: false });
          abnForm.resetFields();
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setAbnormalRepo({ ...abnormalRepo, isOpen: false });
              abnForm.resetFields();
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={abnSubmit}
          >
            確認
          </Button>,
        ]}
        width={"1000px"}
        centered
        forceRender
      >
        <Form form={abnForm} name="abnForm" layout="vertical" size="large">
          <Row justify="center" align="middle" gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>提報單位</MySubtitleH3>}
                name="rptUnit"
                hasFeedback
                rules={[{ required: true, message: "請選擇提報單位" }]}
              >
                <Select>{abnormalRepo.unitOptions}</Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>提報人員</MySubtitleH3>}>
                <Input placeholder={cookies.get("userName")} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>登錄時間</MySubtitleH3>}>
                <Input placeholder={s_time} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>生產工單</MySubtitleH3>}>
                <Input
                  placeholder={singleWpcRef.current.thisWpc.woN}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>ERP料號</MySubtitleH3>}>
                <Input placeholder={singleWpcRef.current.thisWpc.PN} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>成品品名</MySubtitleH3>}>
                <Input
                  placeholder={singleWpcRef.current.thisWpc.pdtNM}
                  disabled
                />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>提報項目</MySubtitleH3>}
                name="rptItem"
                hasFeedback
                rules={[{ required: true, message: "請選擇提報項目" }]}
              >
                <Select>{abnormalRepo.repoOptions}</Select>
              </Form.Item>
              <Form.Item
                label={<MySubtitleH3>責任單位</MySubtitleH3>}
                name="abnDri"
                hasFeedback
                rules={[{ required: true, message: "請選擇責任單位" }]}
              >
                <Select>{abnormalRepo.unitOptions}</Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={<MySubtitleH3>提報訊息</MySubtitleH3>}
                name="rptMsg"
              >
                <TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* //異常解除 */}
      <Modal
        title={<MyThemeH1>異常解除</MyThemeH1>}
        visible={abnormalRemove.isOpen}
        onCancel={() => {
          setAbnormalRemove({
            data: [],
            abnOptions: [],
            repoItem: "", //提報項目
            abnDri: "", //責任單位,
            isOpen: false,
          });
          reAbnForm.resetFields();
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setAbnormalRemove({
                data: [],
                abnOptions: [],
                repoItem: "", //提報項目
                abnDri: "", //責任單位,
                isOpen: false,
              });
              reAbnForm.resetFields();
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={reabnSubmit}
          >
            確認
          </Button>,
        ]}
        width={"1000px"}
        centered
        forceRender
      >
        <Form form={reAbnForm} name="reAbnForm" layout="vertical" size="large">
          <Row justify="center" align="middle" gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>異常編號</MySubtitleH3>}
                name="rptUnit"
                hasFeedback
                rules={[{ required: true, message: "請選擇異常編號" }]}
              >
                <Select onChange={abnormalRemoveWonChange}>
                  {abnormalRemove.abnOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>提報人員</MySubtitleH3>}>
                <Input placeholder={cookies.get("userName")} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>登錄時間</MySubtitleH3>}>
                <Input placeholder={s_time} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>生產工單</MySubtitleH3>}>
                <Input
                  placeholder={singleWpcRef.current.thisWpc.woN}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>ERP料號</MySubtitleH3>}>
                <Input placeholder={singleWpcRef.current.thisWpc.PN} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<MySubtitleH3>成品品名</MySubtitleH3>}>
                <Input
                  placeholder={singleWpcRef.current.thisWpc.pdtNM}
                  disabled
                />
              </Form.Item>
            </Col>
            <Divider />
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>提報項目</MySubtitleH3>}
                hasFeedback
                rules={[{ required: true, message: "請選擇提報項目" }]}
              >
                <Input placeholder={abnormalRemove.repoItem} disabled />
              </Form.Item>
              <Form.Item
                label={<MySubtitleH3>責任單位</MySubtitleH3>}
                hasFeedback
                rules={[{ required: true, message: "請選擇責任單位" }]}
              >
                <Input placeholder={abnormalRemove.abnDri} disabled />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label={<MySubtitleH3>提報訊息</MySubtitleH3>}
                name="rptMsg"
              >
                <TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* //報停工 */}
      <Modal
        title={<MyThemeH1>報停工</MyThemeH1>}
        visible={stopWorkData.isOpen}
        onCancel={() => setStopWorkData({ ...stopWorkData, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setStopWorkData({ ...stopWorkData, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={stopWorkButton}
          >
            確認
          </Button>,
        ]}
        width={"500px"}
        centered
        forceRender
      >
        <div>
          <MySubtitleH4 style={{ display: "block", margin: "10px 15px" }}>
            停工原因
          </MySubtitleH4>
          <Select
            size="large"
            showSearch
            style={{ width: 430, display: "block", margin: "10px" }}
            placeholder="請選擇停工原因"
            value={stopWorkData.cause}
            onChange={(e) => {
              setStopWorkData({ ...stopWorkData, cause: e });
            }}
          >
            {stopWorkData.repoOptions}
          </Select>
        </div>
      </Modal>

      {/* pdf sop */}
      <Modal
        title={<MyThemeH1>SOP瀏覽</MyThemeH1>}
        visible={sopPdfInfo.isOpen}
        onCancel={() => {
          setSopPdfInfo({ ...sopPdfInfoRef.current, isOpen: false });
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="ttst"
            type="primary"
            onClick={() => {
              setSopPdfInfo({ ...sopPdfInfoRef.current, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1400px"}
        centered
        forceRender
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Space size={48}>
            <Button size="large" type="primary" onClick={upSize}>
              +
            </Button>
            <Button size="large" type="primary" onClick={downSize}>
              -
            </Button>
          </Space>
        </div>
        <div
          style={{
            width: "100%",
            height: "840px",
            overflow: "scroll",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document
            file={sopPdfInfo.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf_center"
            onLoadProgress={({ loaded, total }) =>
              console.log("Loading a document: " + (loaded / total) * 100 + "%")
            }
            error={<Alert type="error" message="暫無PDF文件" />}
          >
            {sopPdfInfo.pdfData &&
              new Array(sopPdfInfo.pdfData.numPages)
                .fill("")
                .map((item, index) => {
                  return (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      scale={sopPdfInfo.scale}
                      height={800}
                      noData="暫無PDF文件"
                      error={<Alert type="error" message="暫無PDF文件" />}
                    />
                  );
                })}
            {/* <Page pageNumber={1} height={800} scale={pdfInfo.scale} /> */}
          </Document>
        </div>
      </Modal>

      {/* pdf sip */}
      <Modal
        title={<MyThemeH1>SIP瀏覽</MyThemeH1>}
        visible={sipPdfInfo.isOpen}
        onCancel={() => {
          setSipPdfInfo({ ...sipPdfInfoRef.current, isOpen: false });
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="ttst"
            type="primary"
            onClick={() => {
              setSipPdfInfo({ ...sipPdfInfoRef.current, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1400px"}
        centered
        forceRender
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Space size={48}>
            <Button size="large" type="primary" onClick={upSize}>
              +
            </Button>
            <Button size="large" type="primary" onClick={downSize}>
              -
            </Button>
          </Space>
        </div>
        <div
          style={{
            width: "100%",
            height: "840px",
            overflow: "scroll",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Document
            file={sipPdfInfo.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf_center"
            onLoadProgress={({ loaded, total }) =>
              console.log("Loading a document: " + (loaded / total) * 100 + "%")
            }
            error={<Alert type="error" message="暫無PDF文件" />}
          >
            {sipPdfInfo.pdfData &&
              new Array(sipPdfInfo.pdfData.numPages)
                .fill("")
                .map((item, index) => {
                  return (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      scale={sipPdfInfo.scale}
                      height={800}
                      noData="暫無PDF文件"
                      error={<Alert type="error" message="暫無PDF文件" />}
                    />
                  );
                })}
            {/* <Page pageNumber={1} height={800} scale={pdfInfo.scale} /> */}
          </Document>
        </div>
      </Modal>

      {/* 報多單 */}
      <Modal
        title={<MyThemeH1>報多單</MyThemeH1>}
        visible={multipleWon.isOpen}
        onCancel={() => {
          setMultipleWon({ ...multipleWonRef.current, isOpen: false });
          multipleWonForm.resetFields();
        }}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setMultipleWon({ ...multipleWonRef.current, isOpen: false });
              multipleWonForm.resetFields();
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={multipleWonSubmit}
          >
            確認
          </Button>,
        ]}
        width={"700px"}
        centered
        forceRender
      >
        <Form
          form={multipleWonForm}
          name="multipleWonForm"
          layout="vertical"
          size="large"
        >
          <Form.Item label={<MySubtitleH3>主單</MySubtitleH3>}>
            <Input
              placeholder={singleWpc.thisWpc.woN}
              style={{ width: "300px" }}
              disabled
            />
          </Form.Item>
          <Form.Item
            name="firstVice"
            label={<MySubtitleH3>副單</MySubtitleH3>}
            rules={[{ required: true, message: "請選擇副單" }]}
          >
            <Select placeholder="請選擇副單" style={{ width: "300px" }}>
              {multipleWon.options}
            </Select>
          </Form.Item>

          <Form.List name="vice">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "vice"]}
                      fieldKey={[fieldKey, "vice"]}
                      label={<MySubtitleH3>副單</MySubtitleH3>}
                      rules={[{ required: true, message: "請選擇副單或取消" }]}
                    >
                      <Select
                        placeholder="請選擇副單"
                        style={{ width: "300px" }}
                      >
                        {multipleWon.options}
                      </Select>
                    </Form.Item>
                    <div className="dynamic-delete-button-box">
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        className="dynamic-delete-button"
                      />
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    新增副單
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      {/* <ShowPdf /> */}

      {/* 預覽列印 */}
      <Modal
        title={<MyThemeH1>委外加工單列印</MyThemeH1>}
        visible={isPrinterModal}
        width="45%"
        onCancel={() => setIsPrinterModal(false)}
        cancelText="取消"
        destroyOnClose={true}
        footer={null}
        centered
        // forceRender
      >
        {isPrinterModal && <PreviewPrint setIsShowModal={setIsPrinterModal} thisData={{...vendorFormData,...singleWpc.thisWpc}} />}
      </Modal>
    </>
  );
};
export default Outsourcing;
