import React from 'react';
import styled from 'styled-components';

import { MySubtitleH4, fontSize } from '../../styled/publicTag';

const BoxContainer = styled.div`
  
  .Header {
    //background-color: ${({theme}) => theme.Color4};
    background-color: ${({color}) => color};
    border: 1px solid #C4C4C4;
    color: #6C6C6C;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: ${fontSize.h2};
    padding: 5px 25px 5px 25px;
  }

  .Value {
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: ${fontSize.h4};
    padding: 5px 25px 5px 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .title {
    font-size: ${fontSize.h4};
  }

  .totalCount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #6C6C6C;
  }

  .totalCount > .title {
    font-weight: 400;
  }

  .totalCount > .count {
    padding-left: 10px;
  }

  .totalOutput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #6C6C6C;
    
  }

  .totalOutput > .title {
    font-weight: 400;
  }

  .totalOutput > .count {
    padding-left: 10px;
  }
`

const Box = ({data}) => {

  return(
    <BoxContainer color={data.color}>
      <div className='Header'>{data.lifeF}</div>
      <div className='Value'>
        <div className='totalCount'>
          <div className='title'>總 工單單數：</div>
          <div className='count'>{data.totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
        <div className='totalOutput'>
          <div className='title'>總 工件數量：</div>
          <div className='count'>{data.totalOutput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
      </div>
    </BoxContainer>
  )
};

export default Box;