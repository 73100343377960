/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Routes from "../../routes/";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

import { Layout, message } from "antd";

import { ThemeProvider } from "styled-components";
import { themeLight, themeDark } from "../../styled/theme";

import MyHeader from "../../components/Header/Header";
import NavBar from "../../components/NavBar/NavBar";
import MyFooter from "../../components/Footer/Footer";

import Cookies from "universal-cookie";

import { initPage } from "../../services/api";

import webOutTime from "../../utility/webOutTime";

function Home() {
  const dispatch = useDispatch();

  const history = useHistory();

  const cookies = new Cookies();

  const r_isNavbar = useSelector((allReducers) => allReducers.navBar);
  const r_isNavbarInline = useSelector(
    (allReducers) => allReducers.navBarInline
  );
  const r_theme = useSelector((allReducers) => allReducers.theme);

  const [systemInfo, setSystemInfo] = useState({
    authority: {},
    user: {},
  });

  //call 所有初始API
  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      history.push("/login");
      message.error("請重新登入");
    }
    call_initPage();
  }, []);

  // 特殊功能 判斷頁面如果出現mc/qc超時 需回到線頭看板
  // useEffect(() => {
  //   let webTimeOut = null;
  //   const auth = cookies.get("auth");
  //   if (wswls) {
  //     webTimeOut = setInterval(() => {
  //       webOutTime("wostk");
  //     }, 30000);
  //   }else{
  //     webTimeOut = setInterval(() => {
  //       webOutTime("woswo");
  //     }, 30000);
  //   }
  //   return () => {
  //     clearInterval(webTimeOut);
  //   };
  // }, []);

  const call_initPage = async () => {
    try {
      const res = await initPage();
      if (res.status) {
        setSystemInfo({ authority: res.data.authority, user: res.data.person.peopleNM });
        cookies.set("userName", res.data.person.peopleNM);
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_initPage", e);
    }
  };

  const nabar_temporary_margin_width = (isNavbar, isNavbarInline) => {
    //打開狀態
    if (!isNavbar) {
      return 200;
    } else {
      return isNavbarInline ? 0 : 80;
    }
  };

  const layoutStyle = {
    marginTop: "64px",
    marginLeft: nabar_temporary_margin_width(r_isNavbar, r_isNavbarInline),
    transition: ".3s",
    minHeight: "calc(100vh - 111px)",
  };

  return (
    <ThemeProvider theme={r_theme ? themeLight : themeDark}>
      <div className="App">
        <Layout>
          <NavBar auth={cookies.get("auth")} />
          <MyHeader />
          <Layout style={layoutStyle}>
            <div style={{ padding: "0px 15px", position: 'relative', height: '100%' }}>
              <Routes />
            </div>
          </Layout>
        </Layout>
        <MyFooter />
      </div>
    </ThemeProvider>
  );
}

export default Home;
