import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Select, InputNumber, Space, Divider } from "antd";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import Cookies from "universal-cookie";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import "./PrintForm.css";
import MyIcon from "../../../Icon/pageIcon";

const FormTemplate = ({ thisData, className }) => {
  const cookies = new Cookies();
  return (
    <div className={`${className} Wraper`} key={Math.random() * 1000}>
      <div className="Header pl-5">
        <div className="Info-text">物&nbsp;料&nbsp;移&nbsp;轉&nbsp;單</div>
        <div className="pr-5">
          {thisData.lifeF === "Q"
            ? "不良處置"
            : thisData.nextSN !== "T"
            ? "過站"
            : thisData.lifeF === "G" && thisData.nextSN === "T"
            ? "入庫"
            : "最終站"}
        </div>
      </div>

      <div className="flex-c">
        <div className="flex-r">
          <div className="title w-17_15 h-50 flex-center border-t border-l  border-b">
            料號
          </div>
          <div className="value w-34_5 h-50 pl-15 flex-left border-t border-r border-b border-l font-17">
            {thisData.PN}
          </div>
          <div className="title w-16 h-50 flex-center border-t border-b border-r">
            移出工站
          </div>
          <div className="value w-35 h-50 flex-center border-t border-r  border-b font-17">
            {cookies.get("wsID") + "_" + cookies.get("wsNM")}
          </div>
        </div>

        <div className="flex-r">
          <div className="title w-17_15 h-50 flex-center border-l border-b">
            品名
          </div>
          <div className="value w-34_5 h-50 pl-15 flex-left border-r border-b  border-l font-13">
            {thisData.pdtNM}
          </div>
          <div className="title w-16 h-50 flex-center border-r border-b flex-center">
            移入工站
          </div>
          <div className="value w-35 h-50 flex-center border-r border-b font-17">
            {thisData.lifeF === "Q"
              ? "不良處置"
              : thisData.nextSN !== "T"
              ? thisData.nextWsID + "-" + thisData.nextWsNM
              : thisData.lifeF === "G" && thisData.nextSN === "T"
              ? "入庫"
              : "最終站"}
          </div>
        </div>
      </div>

      <div className="flex-r">
        <div className="flex-c w-16_75">
          <div className="title flex-center h-40 border-l border-r border-b">
            工單號
          </div>
          <div className="title flex-center h-40 border-l border-r border-b">
            過站時間
          </div>
          <div className="title flex-center h-40 border-l border-r border-b">
            總生產時間
          </div>
        </div>
        <div className="w-33">
          <div className="flex-center pl-15 h-40 font-17 border-r border-b ">
            {thisData.woN}
          </div>
          <div className="flex-center pl-15 h-40 font-17 border-r border-b">
            {moment(thisData.closeT).format("yyyy/MM/DD HH:mm")}
          </div>
          <div className="flex-center pl-15 h-40 font-17 border-r border-b">
            {`${thisData.totalDay[0]}天${thisData.totalDay[1]}時${thisData.totalDay[2]}分${thisData.totalDay[3]}秒`}
          </div>
        </div>

        <div className="w-50 h-120 border-r border-b">
          <div className="h-120 QRCode">
            <div className="flex-c flex-center">
              <div className="title">途程碼</div>
              <div>
                {" "}
                {thisData.woType}-{thisData.wpcSet}-{thisData.wpcSN}-
                {thisData.addSN}&emsp;
                {thisData.prefix}-{thisData.suffix}-{thisData.offfix}-
                {thisData.sqc}
              </div>
            </div>
            <QRCode
              id={thisData.UUID}
              value={`{"UUID":"${thisData.UUID}","woN":"${thisData.woN}"}`} //value引數為生成二維碼的連結 我這裡是由後端返回
              size={80} //二維碼的寬高尺寸
              fgColor="#000000" //二維碼的顏色
            />
          </div>
        </div>
      </div>

      <div className="title pl-5">移轉紀錄</div>
      <div className="flex-r">
        <div className="w-50 flex-r">
          <div className="flex-c w-40">
            <div className="title h-50 flex-center border-b border-r border-l border-t">
              {thisData.lifeF === "Q"
                ? "不良數量"
                : thisData.lifeF === "0" &&
                  thisData.nextSN !== "T" &&
                  thisData.sqc === "G"
                ? thisData.nextWsID + "-" + thisData.nextWsNM
                : thisData.lifeF === "G" && thisData.nextSN === "T"
                ? "入庫數量"
                : "合計數量"}
            </div>
            <div className="h-110 border-b flex-c flex-center felx-wrap font-30 border-r border-l ">
              {thisData.lifeF === "Q" ? thisData.ngC : thisData.okC}
            </div>
          </div>

          <div className="w-60">
            <div className="title h-50 flex-center border-b border-t">
              錄入數量&nbsp; ｜ &nbsp;製程工時
            </div>
            <div className="h-110 border-b flex-c flex-center font-13 felx-wrap">
              {/* 將來在這邊塞入製成工時的循環 */}
              {thisData.lifeF === "Q"
                ? thisData.ngs.map((x) => {
                    return (
                      <div className="ml-5 mt-10 " key={x.item}>
                        {x.itemNM}：{x.ngC}
                      </div>
                    );
                  })
                : thisData.oks.map((x) => {
                    return (
                      <div className="ml-5 mt-10 " key={x.item}>
                        {x.itemNM}：{x.okC}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="flex-c w-50">
          <div className="MaterialText font-17 border-l border-r border-b h-160 w-100 border-t">
            <span className="pl-30 pt-10">
              滿箱數：__________箱__________件/箱{" "}
            </span>
            <span className="pl-30 pt-10">
              零箱數：__________箱____________&nbsp;件{" "}
            </span>
            <span className="pl-30 pt-10">
              備&ensp;&ensp;註：__________________________
            </span>
          </div>
        </div>
      </div>

      <div className="title pl-5">作業紀錄</div>

      <div className="flex-r">
        <div className="flex-c w-20">
          <div className="title h-40 flex-center border-t border-r border-l ">
            ERP入庫單號
            <EditOutlined className="pl-5" />
          </div>
          <div className="flex-col-c h-60 border-t border-l border-r border-b"></div>
        </div>
        <div className="flex-c w-20">
          <div className="title h-40 flex-center border-t border-2-r">
            移轉物流
            <EditOutlined className="pl-5" />
          </div>
          <div className="flex-col-c h-60 border-t border-2-r border-b"></div>
        </div>
        <div className="flex-c w-20">
          <div className="title h-40 flex-center border-2-t border-r">
            移入物料盤點
            <EditOutlined className="pl-5" />
          </div>
          <div className="flex-col-c h-60 border-t border-r border-2-b"></div>
        </div>
        <div className="flex-c w-20">
          <div className="title h-40 flex-center border-2-t border-r pl-5">
            移入執行[登錄]
            <span
              role="img"
              aria-label="edit"
              className="anticon anticon-edit pl-5"
            >
              <MyIcon.IconPress />
            </span>
          </div>
          <div className="flex-col-c h-60 border-t border-r border-2-b"></div>
        </div>
        <div className="flex-c w-20">
          <div className="title h-40 flex-center border-2-t border-2-r pl-5">
            移入執行[報開工]
            <span
              role="img"
              aria-label="edit"
              className="anticon anticon-edit "
            >
              <MyIcon.IconPress />
            </span>
          </div>
          <div className="flex-col-c h-60 border-t border-2-r border-2-b"></div>
        </div>
      </div>
    </div>
  );
};

const PrintFormEle = ({ className, setIsModal, thisData }) => {
  const printRef = useRef("");
  const [isPrint, setIsPrint] = useState(true);
  const [printNum, setPrintNum] = useState([1]);
  const [printNumObj, setPrintNumObj] = useState([]);

  const { Option } = Select;
  const cookies = new Cookies();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    // content: () => CreatePrintNumObj(printNum),
    bodyClass: "print-style",
    pageStyle: `
    body{
      counter-reset: page-number;  
    }
    @page {
      size: A5 landscape;
      margin-bottom:0px;
      margin-top:10px;
    }
    .pointer-box {
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper{
      page-break-inside: avoid;
      position: relative;
    }

    .Wraper:after {
      content: "標示單 － "counter(page-number)"/${
        printNum.length
      }"; /* 取用該名字的計數器 */
      counter-increment: page-number 1; /* 增加計數器數值 */
      position: absolute;
      right: 5;
      bottom: 10;
      font-size: 20px;
      text-align: center;
    }
    .Wraper:before {
      content: "列印時間：${moment(new Date()).format(
        "yyyy-MM-DD HH:mm:ss"
      )} ${cookies.get("userName")}"; /* 取用該名字的計數器 */
      position: absolute;
      left: 5;
      bottom: 15;
      font-size: 13px;
      text-align: center;
    }
    .template{
      page-break-inside: avoid;
      position: relative;
    }
    .template:after{
      content: "作業單"; 
      position: absolute;
      right: 0;
      bottom: 10;
      font-size: 20px;
      text-align:right;
      width:120px;
      height:30px;
      background: white;
      z-index:99;
    }
  `,
  });

  useEffect(() => {
    if (!isPrint) {
      setTimeout(() => {
        setIsPrint(true);
      }, [500]);
    }
  }, [isPrint]);

  useEffect(() => {
    const obj = printNum.map((x) => {
      return <FormTemplate thisData={thisData} key={Math.random() * 1000} />;
    });
    setPrintNumObj(obj);
  }, [printNum]);

  return (
    <div className={className}>
      <div className="pointer-box mc">
        <FormTemplate thisData={thisData} />
      </div>
      <div className="pointer-box mc hidden" ref={printRef}>
        {printNumObj}
        <FormTemplate thisData={thisData} className="template" />
      </div>
      <Divider />
      <div className="w-100 align-r">
        <Space>
          <div className="font-17">列印張數</div>
          <InputNumber
            size="large"
            min={1}
            max={20}
            value={printNum.length}
            onChange={(e) => {
              let obj = [];
              for (let i = 0; i < e; i++) {
                obj.push(i);
              }
              setPrintNum(obj);
            }}
          />
          <Button type="primary" size="large" onClick={handlePrint}>
            列印
          </Button>
        </Space>
      </div>
    </div>
  );
};

const PrintForm = styled(PrintFormEle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .hidden {
    display: none;
  }
  .pointer-box {
    width: 794px;
    /* background-color: #f5e0e0; */
    /* border: 1px solid #000; */
  }
  .ant-table-cell {
    font-size: 12px;
    padding: 4px;
  }
  .ant-table {
    border: none;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #dc10dc00;
  }
  .ant-table-cell {
    background-color: #fafafa;
  }
  .align-r {
    text-align: right;
    margin-right: 15px;
  }
  .w-100 {
    width: 100%;
  }
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export default PrintForm;
