import styled from "styled-components";
import { Modal } from "antd";

const WosTableKanbanErrorModal = styled(Modal)`
  .ant-modal-header {
    background-color: #fa4242;
  }
  .ant-modal-content {
    background-color: #fc4a4a;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 55px;
    color: white;
  }
  .big {
    font-size: 90px;
  }
`;

const ErrorModal = ({ thisData, isErrorModal = false, setIsErrorModal }) => {
  return (
    <WosTableKanbanErrorModal
      visible={isErrorModal}
      onCancel={() => setIsErrorModal(false)}
      destroyOnClose={true}
      centered
      footer={false}
      width={"1200px"}
    >
      <div className="main">
        <div>工單號：{thisData.woN}</div>
        <div className="big">
          {thisData.planQty -
            thisData.inC -
            thisData.d_inC -
            thisData.d_planQty <
          0
            ? "超量生產"
            : "即將完工"}
        </div>
      </div>
    </WosTableKanbanErrorModal>
  );
};

export default ErrorModal;
