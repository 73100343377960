import React, { useState, useEffect } from "react";
import WOI from "../../styled/workOrderInformation";
import MyWpcRecode from "../../styled/wpcRecode";
import WsWlSelect from "../../components/WsWlSelect";
import WorkOrderInformation from "../../components/WosMaterialsControl/WorkOrderInformation";
import Productivity from "../../components/WosMaterialsControl/Productivity";
import { numberWithCommas } from "../../utility/numberWithCommas";
import useStateRef from "../../utility/useStateRef";
import MyButton from "../../styled/button";
import checkUndefined from "../../utility/checkUndefined";
import Cookies from "universal-cookie";
import prefixWorkImg from "../../assets/images/prefixWorkImg.png";
import suffixWorkImg from "../../assets/images/suffixWorkImg.png";
import MyIcon from "../../components/Icon/pageIcon";

import {
  Row,
  Col,
  Table,
  message,
  Button,
  Space,
  Select,
  Modal,
  Input,
  Typography,
  Tooltip,
  Divider,
  Alert,
  Popover,
} from "antd";
import {
  MyPageName,
  MyComponent,
  MyPageTitle,
  MyThemeH1,
  MySubtitleH3,
  MyH1,
  MyMainColorH3,
  MySubtitleH4,
  MyDisabled,
  MyMainColorH1,
  MyComponentTwo,
} from "../../styled/publicTag";

import {
  getAllWpc,
  confirm,
  cancel,
  prefixWorkAPI,
  suffixWorkAPI,
} from "../../services/mcAPI";

import { getWsWl } from "../../services/api";

import AddNg from "../../components/WosMaterialsControl/ModalContainer/AddNg";
import AddOk from "../../components/WosMaterialsControl/ModalContainer/AddOk";

const WosMaterialsControl = () => {
  const { TextArea } = Input;

  const { Search } = Input;

  const { Link } = Typography;

  const cookies = new Cookies();

  const { Option } = Select;

  const wpcDetailsTableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => {
        return str ? str : "暫無數據";
      },
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      render: (str) => {
        return str;
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "下線碼",
      dataIndex: "offfix",
      key: "offfix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "多單碼",
      dataIndex: "woMix",
      key: "woMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "混線碼",
      dataIndex: "lineMix",
      key: "lineMix",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
  ];

  const wpcTableCol = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      width: "90px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      width: "200px",
      className: "row-min-width",
      align: "left",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "途單歷程",
      dataIndex: "mappedLifeF",
      key: "mappedLifeF",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "歷程時間",
      dataIndex: "lifeFTime",
      key: "lifeFTime",
      width: "150px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      width: "130px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(
          str === 0 ? "正常工單" : str === "0" ? "重工工單" : "暫無資料"
        );
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      width: "260px",
      className: "row-min-width col-white-space",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      width: "280px",
      className: "row-min-width",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      width: "130px",
      className: "row-min-width",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      width: "130px",
      render: (num) => {
        return numberWithCommas(num);
      },
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      width: "130px",
      render: (num) => {
        return numberWithCommas(num);
      },
    },

    {
      //planQty - d_planQty - d_inC
      title: "目標產量",
      dataIndex: "qty",
      key: "qty",
      width: "130px",
      className: "row-min-width",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    // {
    //   title: "生產數量",
    //   dataIndex: "okC",
    //   key: "okC",
    //   width: "130px",
    //   className: "row-min-width",
    //   align: "center",
    //   render: (str) => {
    //     return checkUndefined(str);
    //   },
    // },
    {
      title: "動作",
      dataIndex: "action",
      key: "action",
      className: "row-last-col-width",
      align: "center",
      render: (obj, recoded) => {
        return (
          <Tooltip title={`途程碼：${recoded.wpcSN + ""}`}>
            <Link
              onClick={() => {
                setSingleWpc({ ...singleWpc, isOpen: true });
                setAllWpc({ ...allWpc, thisWpc: obj });
              }}
            >
              詳情
            </Link>
          </Tooltip>
        );
      },
    },
  ];

  const [singleWpc, setSingleWpc, singleWpcRef] = useStateRef({
    isOpen: false,
    thisWpc: {},
  });

  const [isNgModal, setIsNgModal] = useState(false);

  const [isOkModal, setIsOkModal] = useState(false);

  const [wsList, setWsList] = useState([]);

  //前插工
  const [prefixWork, setPrefixWork, prefixWorkRef] = useStateRef({
    isOpen: false,
    wsID: "",
    description: "",
  });
  //後插工
  const [suffixWork, setSuffixWork, suffixWorkRef] = useStateRef({
    isOpen: false,
    wsID: "",
    description: "",
  });

  const [allWpc, setAllWpc, allWpcRef] = useStateRef({
    //料表詳情
    pageInfo: {
      page: 1,
      quertString: "",
      totalItems: 0,
      totalItems: 25,
      currentPage: 1,
      pageItemNumber: 10,
      startItemNumber: 1,
      endItemNumber: 10,
    },
    loading: false,
    data: [],
    thisWpc: {},
  });

  const [WpcRecode, setWpcRecode] = useState({
    up: {},
    down: {},
  });

  const [processManagementButton, setProcessManagementButton] = useState({
    okProductsLoginButton: true,
    noProductsLoginButton: true,
    mcComfirmButton: true,
    mcCancelButton: true,
    prefixWorkButton: true,
    suffixWorkButton: true,
  });

  const call_getAllWpc = async () => {
    try {
      const res = await getAllWpc(allWpcRef.current.pageInfo);
      if (res.status) {
        const newAllWpc = res.data.woNs.map((x) => {
          // const wlID = cookies.get("wlID");
          return {
            ...x,
            qty: x.planQty - x.d_planQty - x.d_inC,
            key: x.UUID,
            // wlID: wlID,
          };
        });
        const thisWpcIndex = newAllWpc
          .map((x) => {
            return x.UUID;
          })
          .indexOf(allWpcRef.current.thisWpc.UUID);

        let newPageInfo = {
          ...allWpcRef.current.pageInfo,
          ...res.data.pageInfo,
        };
        setAllWpc({
          ...allWpcRef.current,
          data: newAllWpc,
          thisWpc: thisWpcIndex > -1 ? newAllWpc[thisWpcIndex] : {},
          pageInfo: newPageInfo,
        });
      } else {
        setAllWpc({
          pageInfo: {
            page: 1,
            quertString: "",
            totalItems: 0,
            totalItems: 0,
            currentPage: 1,
            pageItemNumber: 10,
            startItemNumber: 1,
            endItemNumber: 10,
          },
          loading: false,
          data: [],
          thisWpc: {},
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  //物控覆核
  const call_confirm = async () => {
    try {
      const res = await confirm({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        message.success(res.msg);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //取消物控覆核
  const call_cancel = async () => {
    try {
      const res = await cancel({ UUID: singleWpcRef.current.thisWpc.UUID });
      if (res.status) {
        message.success(res.msg);
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_getWsWl = async () => {
    const res = await getWsWl();
    if (res.status) {
      let wsOptionArray = [];
      for (const i of res.data.wsIDs) {
        wsOptionArray.push(
          <Option
            key={i.wsID.wsID}
            value={`${i.wsID.wsID}`}
          >{`${i.wsID.wsID}-${i.wsID.wsNM}`}</Option>
        );
      }
      setWsList(wsOptionArray);
    } else {
      message.error(res.msg);
    }
  };

  const call_prefixWorkAPI = async () => {
    try {
      if (prefixWork.wsID === "") {
        message.error("請選擇工站");
        return;
      }
      if (prefixWork.description === "") {
        message.error("請填寫插工註記");
        return;
      }
      const data = {
        UUID: singleWpcRef.current.thisWpc.UUID,
        newWsID: prefixWork.wsID,
        description: prefixWork.description,
      };
      const res = await prefixWorkAPI({ ...data });
      if (res.status) {
        message.success(res.msg);
        setPrefixWork({
          isOpen: false,
          wsID: "",
          description: "",
        });
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const call_suffixWorkAPI = async () => {
    try {
      if (suffixWork.wsID === "") {
        message.error("請選擇工站");
        return;
      }
      if (suffixWork.description === "") {
        message.error("請填寫插工註記");
        return;
      }

      const data = {
        UUID: singleWpcRef.current.thisWpc.UUID,
        newWsID: suffixWork.wsID,
        description: suffixWork.description,
      };
      const res = await suffixWorkAPI({ ...data });
      if (res.status) {
        message.success(res.msg);
        setSuffixWork({
          isOpen: false,
          wsID: "",
          description: "",
        });
        call_getAllWpc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const prefixWorkCheck = () => {
    Modal.confirm({
      title: <MyH1>確定進行前插工？</MyH1>,
      content: <MySubtitleH3>請先確認輸入的工站無誤，再進行插工</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: 600,
      onOk() {
        call_prefixWorkAPI();
      },
    });
  };

  const searchWPC = async (value) => {
    const newPageInfo = allWpcRef.current.pageInfo;
    newPageInfo.queryString = value;
    setAllWpc({ ...allWpcRef.current, pageInfo: newPageInfo });
    call_getAllWpc();
  };

  const suffixWorkCheck = () => {
    Modal.confirm({
      title: <MyH1>確定進行後插工？</MyH1>,
      content: <MySubtitleH3>請先確認輸入的工站無誤，再進行插工</MySubtitleH3>,
      okText: "確認",
      okType: "primary",
      cancelText: "取消",
      centered: true,
      width: 600,
      onOk() {
        call_suffixWorkAPI();
      },
    });
  };

  const suffixWorkPopoverContent = () => {
    return <img src={suffixWorkImg} />;
  };

  const prefixWorkPopoverContent = () => {
    return <img src={prefixWorkImg} />;
  };

  useEffect(() => {
    call_getAllWpc();
    call_getWsWl();
  }, []);

  useEffect(() => {
    setSingleWpc({ ...singleWpc, thisWpc: allWpcRef.current.thisWpc });
    console.log("當前塗單", singleWpcRef.current.thisWpc);
    let up = {
      wpcSN: allWpcRef.current.thisWpc.preSN,
      wsID: allWpcRef.current.thisWpc.preWsID,
      wsNM: allWpcRef.current.thisWpc.preWsNM,
    };
    let down = {
      wpcSN: allWpcRef.current.thisWpc.nextSN,
      wsID: allWpcRef.current.thisWpc.nextWsID,
      wsNM: allWpcRef.current.thisWpc.nextWsNM,
    };
    setWpcRecode({ up: up, down: down });
    if (Object.getOwnPropertyNames(allWpc.thisWpc).length !== 0) {
      switch (allWpcRef.current.thisWpc.lifeF) {
        case "L":
          setProcessManagementButton({
            okProductsLoginButton: true,
            noProductsLoginButton: true,
            mcComfirmButton: true,
            mcCancelButton: false,
            prefixWorkButton: true,
            suffixWorkButton: true,
          });
          break;
        case "7":
          setProcessManagementButton({
            okProductsLoginButton: false,
            noProductsLoginButton: false,
            mcComfirmButton: false,
            mcCancelButton: true,
            prefixWorkButton: false,
            suffixWorkButton: false,
          });
          break;
        case "2":
          setProcessManagementButton({
            okProductsLoginButton: false,
            noProductsLoginButton: false,
            mcComfirmButton: true,
            mcCancelButton: true,
            prefixWorkButton: true,
            suffixWorkButton: true,
          });
          break;
        case "3":
          setProcessManagementButton({
            okProductsLoginButton: false,
            noProductsLoginButton: false,
            mcComfirmButton: true,
            mcCancelButton: true,
            prefixWorkButton: true,
            suffixWorkButton: true,
          });
          break;
        case "4":
          setProcessManagementButton({
            okProductsLoginButton: false,
            noProductsLoginButton: false,
            mcComfirmButton: true,
            mcCancelButton: true,
            prefixWorkButton: true,
            suffixWorkButton: true,
          });
          break;

        default:
          setProcessManagementButton({
            okProductsLoginButton: true,
            noProductsLoginButton: true,
            mcComfirmButton: true,
            mcCancelButton: true,
            prefixWorkButton: true,
            suffixWorkButton: true,
          });
      }
    } else {
      setProcessManagementButton({
        okProductsLoginButton: true,
        noProductsLoginButton: true,
        mcComfirmButton: true,
        mcCancelButton: true,
        prefixWorkButton: true,
        suffixWorkButton: true,
      });
    }
  }, [allWpc.thisWpc]);

  const buttonStyle = { width: "100%", height: "80px" };

  return (
    <React.Fragment>
      <MyPageName>
        <Row justify="center" align="middle">
          <Col span={4}>
            <MyPageTitle>過站_物控作業</MyPageTitle>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Space align="center" size={20}>
              <WsWlSelect />
            </Space>
          </Col>
        </Row>
      </MyPageName>
      <Row justify="space-between" align="bottom">
        <Col span={24}>
          <MyComponentTwo>
            <Row justify="space-between" align="bottom">
              <Col span={12}>
                <Row justify="space-between" align="bottom">
                  <Col span={24}>
                    <MyComponent style={{ height: "232px" }}>
                      <Row
                        justify="space-between"
                        align="bottom"
                        gutter={[24, 24]}
                      >
                        <Col span={12}>
                          <MyButton.MySuccessIconButton
                            disabled={
                              processManagementButton.okProductsLoginButton
                            }
                            icon={<MyIcon.BoxCheck />}
                            onClick={() => {
                              setIsOkModal(true);
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <MyButton.MyErrorIconButton
                            disabled={
                              processManagementButton.noProductsLoginButton
                            }
                            icon={<MyIcon.BoxFailed />}
                            onClick={() => {
                              setIsNgModal(true);
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#BEBEBE" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              投產數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.inC-singleWpcRef.current.thisWpc.d_inC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#448933" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              良品數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.okC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#C54141" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              不良品數量
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.ngC
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                        <Col span={6}>
                          <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                            <WOI.WOIMainBoxItemHeader
                              style={{ backgroundColor: "#BEBEBE" }}
                            />
                            <WOI.WOIMainBoxItemTitle>
                              平衡帳差
                            </WOI.WOIMainBoxItemTitle>
                            <WOI.WOIMainBoxItemContent>
                              {checkUndefined(
                                singleWpcRef.current.thisWpc
                                  ? singleWpcRef.current.thisWpc.bal
                                  : "暫無數據"
                              )}
                            </WOI.WOIMainBoxItemContent>
                          </WOI.WOIMainBoxItem>
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <MyComponent style={{ margin: "8px" }}>
                      <Row justify="center" align="bottom" gutter={[24, 24]}>
                        <Col span={6}>
                          <MyButton.MyButton1
                            disabled={processManagementButton.mcComfirmButton}
                            style={buttonStyle}
                            onClick={() => {
                              call_confirm();
                            }}
                          >
                            物控覆核
                          </MyButton.MyButton1>
                        </Col>
                        <Col span={6}>
                          <MyButton.MyWhiteButton1
                            style={buttonStyle}
                            onClick={() => {
                              call_cancel();
                            }}
                            disabled={processManagementButton.mcCancelButton}
                          >
                            取消覆核
                          </MyButton.MyWhiteButton1>
                        </Col>
                        <Col span={6}>
                          <MyButton.MyButton2
                            disabled={processManagementButton.prefixWorkButton}
                            style={buttonStyle}
                            onClick={() =>
                              setPrefixWork({ ...prefixWork, isOpen: true })
                            }
                          >
                            前插工
                          </MyButton.MyButton2>
                        </Col>
                        <Col span={6}>
                          <MyButton.MyButton3
                            disabled={processManagementButton.suffixWorkButton}
                            style={buttonStyle}
                            onClick={() =>
                              setSuffixWork({ ...suffixWork, isOpen: true })
                            }
                          >
                            後插工
                          </MyButton.MyButton3>
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <MyComponent>
                      <Row
                        justify="space-between"
                        align="bottom"
                        gutter={[24, 12]}
                      >
                        <Col span={24}>
                          <MyMainColorH1>途單紀錄</MyMainColorH1>
                        </Col>
                        <Col span={12}>
                          <MyWpcRecode title="上途程" content={WpcRecode.up} />
                        </Col>
                        <Col span={12}>
                          <MyWpcRecode
                            title="下途程"
                            content={WpcRecode.down}
                          />
                        </Col>
                      </Row>
                    </MyComponent>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row align="bottom">
                  <Col span={24}>
                    <MyComponent>
                      <WorkOrderInformation data={singleWpc.thisWpc} />
                    </MyComponent>
                  </Col>
                  <Col span={24}>
                    <Productivity data={singleWpc.thisWpc} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </MyComponentTwo>
        </Col>
        <Col span={24}>
          <MyDisabled>
            <Row align="bottom">
              <Col span={12}>
                <MyMainColorH3>途單索引</MyMainColorH3>
              </Col>
              <Col span={12} style={{ textAlign: "right", margin: "6px 0" }}>
                <Search
                  placeholder="請輸入完整的工單或料號"
                  enterButton
                  onSearch={searchWPC}
                  style={{ width: "230px" }}
                />
              </Col>
              <Col span={24}>
                <Table
                  bordered={true}
                  columns={wpcTableCol}
                  scroll={{ x: "max-content", y: 150 }}
                  dataSource={allWpc.data}
                  loading={allWpc.loading}
                  rowClassName={(record) => {
                    return record.UUID === allWpc.thisWpc.UUID
                      ? "clickRowStyl"
                      : "";
                  }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        if (record.UUID === allWpc.thisWpc.UUID) {
                          //點擊詳請不算
                          if (singleWpcRef.current.isOpen) {
                            setAllWpc({ ...allWpc, thisWpc: record });
                          } else {
                            setAllWpc({ ...allWpc, thisWpc: {} });
                          }
                        } else {
                          setAllWpc({ ...allWpc, thisWpc: record });
                        }
                      },
                    };
                  }}
                  // defaultSelectedRowKeys={allWpc.data[0]?allWpc.data[0].UUID:''}
                  pagination={{
                    defaultCurrent: 1,
                    total: allWpc.pageInfo.totalItems
                      ? allWpc.pageInfo.totalItems
                      : 1,
                    current: allWpc.pageInfo.page ? allWpc.pageInfo.page : 1,
                    pageSize: allWpc.pageInfo.pageSize
                      ? allWpc.pageInfo.pageSize
                      : 10,
                    onChange: (current) => {
                      let pageData = allWpc.pageInfo;
                      pageData.page = current;
                      setAllWpc({ ...allWpc, pageInfo: pageData });
                      call_getAllWpc();
                    },
                    showTotal: (total, range) => (
                      <MySubtitleH4>
                        共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
                      </MySubtitleH4>
                    ),
                  }}
                />
              </Col>
            </Row>
          </MyDisabled>
        </Col>
      </Row>

      {/* 良品登錄 */}
      <Modal
        title={<MyThemeH1>良品登錄</MyThemeH1>}
        visible={isOkModal}
        onCancel={() => setIsOkModal(false)}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setIsOkModal(false);
            }}
          >
            關閉
          </Button>,
        ]}
        width={"800px"}
        centered
        forceRender
      >
        {isOkModal ? <AddOk singleWpc={singleWpc.thisWpc} call_getAllWpc={call_getAllWpc}/> : null}

      </Modal>

      {/* 不良品登錄 */}
      <Modal
        title={<MyThemeH1>不良品登錄</MyThemeH1>}
        visible={isNgModal}
        onCancel={() => setIsNgModal(false)}
        footer={[
          <Button
            key="artificialWorkOrderModalok"
            onClick={() => setIsNgModal(false)}
          >
            關閉
          </Button>,
        ]}
        width={"800px"}
        centered
        forceRender
      >
        {isNgModal ? <AddNg singleWpc={singleWpc.thisWpc} call_getAllWpc={call_getAllWpc}/> : null}
      </Modal>
      {/* 前插工 */}
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <MyThemeH1>前插工</MyThemeH1>
            <Popover
              content={prefixWorkPopoverContent}
              trigger="click"
              placement="bottomLeft"
            >
              <Alert
                message="說明"
                type="info"
                showIcon
                style={{ margin: "0px 15px" }}
              />
            </Popover>
          </div>
        }
        visible={prefixWork.isOpen}
        onCancel={() => setPrefixWork({ ...prefixWork, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setPrefixWork({ ...prefixWork, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={prefixWorkCheck}
          >
            確認
          </Button>,
        ]}
        width={"900px"}
        centered
        forceRender
      >
        <div>
          <Row justify="space-between" align="center" gutter={[24, 12]}>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>工單號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.woN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>料號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.PN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>品名</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.pdtNM)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>前途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.preSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>本途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.wpcSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>次途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.nextSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>前工站</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.preWsID)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>本工站</MySubtitleH4>
              <Input
                value={checkUndefined(cookies.get("wsID"))}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>後工站</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.nextWsID)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle" gutter={[12, 0]}>
            <Col span={12}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>前插工</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  <Select
                    style={{ width: "100%" }}
                    bordered={false}
                    value={prefixWork.wsID}
                    onChange={(e) => setPrefixWork({ ...prefixWork, wsID: e })}
                    size="large"
                  >
                    {wsList}
                  </Select>
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
            <Col span={12}>
              <TextArea
                onChange={(e) =>
                  setPrefixWork({ ...prefixWork, description: e.target.value })
                }
                placeholder="插工註記"
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      {/* 後插工 */}
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <MyThemeH1>後插工</MyThemeH1>
            <Popover
              content={suffixWorkPopoverContent}
              trigger="click"
              placement="bottomLeft"
            >
              <Alert
                message="說明"
                type="info"
                showIcon
                style={{ margin: "0px 15px" }}
              />
            </Popover>
          </div>
        }
        visible={suffixWork.isOpen}
        onCancel={() => setSuffixWork({ ...suffixWork, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalCencel"
            onClick={() => {
              setSuffixWork({ ...suffixWork, isOpen: false });
            }}
          >
            取消
          </Button>,
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={suffixWorkCheck}
          >
            確認
          </Button>,
        ]}
        width={"900px"}
        centered
        forceRender
      >
        <div>
          <Row justify="space-between" align="center" gutter={[24, 12]}>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>工單號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.woN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>料號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.PN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>品名</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.pdtNM)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>前途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.preSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>本途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.wpcSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>次途程號</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.nextSN)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>前工站</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.preWsID)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>本工站</MySubtitleH4>
              <Input
                value={checkUndefined(cookies.get("wsID"))}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
            <Col span={8}>
              <MySubtitleH4 style={{ display: "block" }}>後工站</MySubtitleH4>
              <Input
                value={checkUndefined(singleWpc.thisWpc.nextWsID)}
                style={{ width: "200px" }}
                disabled
              />
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle" gutter={[12, 0]}>
            <Col span={12}>
              <WOI.WOIMainBoxItem style={{ width: "100%" }}>
                <WOI.WOIMainBoxItemHeader />
                <WOI.WOIMainBoxItemTitle>後插工</WOI.WOIMainBoxItemTitle>
                <WOI.WOIMainBoxItemContent>
                  <Select
                    style={{ width: "100%" }}
                    bordered={false}
                    value={suffixWork.wsID}
                    onChange={(e) => setSuffixWork({ ...suffixWork, wsID: e })}
                    size="large"
                  >
                    {wsList}
                  </Select>
                </WOI.WOIMainBoxItemContent>
              </WOI.WOIMainBoxItem>
            </Col>
            <Col span={12}>
              <TextArea
                onChange={(e) =>
                  setSuffixWork({ ...suffixWork, description: e.target.value })
                }
                placeholder="插工註記"
                autoSize={{ minRows: 5, maxRows: 5 }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      {/* 途單索引詳情 */}
      <Modal
        title={<MyThemeH1>途單索引詳情</MyThemeH1>}
        visible={singleWpc.isOpen}
        onCancel={() => setSingleWpc({ ...singleWpc, isOpen: false })}
        cancelText="取消"
        destroyOnClose={true}
        footer={[
          <Button
            key="artificialWorkOrderModalok"
            type="primary"
            onClick={() => {
              setSingleWpc({ ...singleWpc, isOpen: false });
            }}
          >
            確認
          </Button>,
        ]}
        width={"1400px"}
        centered
        forceRender
      >
        <Table
          bordered={true}
          columns={wpcDetailsTableCol}
          dataSource={[{ ...singleWpc.thisWpc, key: "prefix456" }]}
          pagination={false}
        />
      </Modal>
    </React.Fragment>
  );
};
export default WosMaterialsControl;
